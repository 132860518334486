// Nested menu implemented by this tutorial
// https://stackoverflow.com/questions/44467377/bootstrap-4-multilevel-dropdown-inside-navigation
import INetteExtension from "../../INetteExtension";
import ClassicExtension from "../../ClassicExtension";

export default class BootstrapNestedMenuExtension extends ClassicExtension {

    public attach($root: JQuery): void {
        $root.find('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
            if (!$(this).next().hasClass('show')) {
                $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
            }
            const $subMenu = $(this).next('.dropdown-menu');
            $subMenu.toggleClass('show');


            $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
                $('.dropdown-submenu .show').removeClass('show');
            });

            return false;
        });
    }

}
