import {Chart, SVGElement} from "highcharts";

function getPositions(n: number, plotWidth: number, paddingLeft = 10): number[] {
    if (n < 2) {
        return [plotWidth/2];
    }
    
    const positions = [];
    const space = (plotWidth) / (n-1);
    for (let i=0; i<n; i++) {
        positions.push(paddingLeft + i*space);
    }
        
    return positions;
}

function align(i, n) {
    if (i < 1) {
        return 'left';
    } else if (i >= n-1) {
        return 'right'
    }
    return 'center';
}


export default function renderColumnLabels(chart: Chart & {columnLabels?: SVGElement[]}, labels: string[]) {

    if (!labels.length) {
        return;
    }

    const positions = getPositions(labels.length, chart.plotWidth, chart.plotLeft);
    if (chart.columnLabels) {
        chart.columnLabels.forEach((customLabel, i) => {
            customLabel.attr({
                x: positions[i],
                y: chart.chartHeight - 20
            }).css({
                width: chart.plotWidth / labels.length,
            });
        });
    } else {
        chart.columnLabels = [];
        labels.forEach((label, i) => {
            const labelEl = chart.renderer.text(label);
            labelEl.attr({
                    x: positions[i],
                    y: chart.chartHeight - 20,
                    align: align(i, labels.length),
                    title: label,
                })
                .css({
                    fontSize: '12px',
                    width: chart.plotWidth / labels.length,
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                })
                .add();

            chart.columnLabels.push(labelEl);
        });
    }

}
