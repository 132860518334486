import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import Table from "../../shared/Table";
import {Column} from "../../shared/Table/js/Table";
import arrayToCsvDownload from "../../../../../js/utility/arrayToCsvDownload";

type TField = {
    value: string,
    attributes?: {
        class?: string[],
        style?: string,
    }
};

type TRow = {
    label: string,
    score: TField,
    scorePrevious: TField,
};

type TRankTableData = {
    rows: TRow[],
    show: {
        rows: {
            first: number|null,
            last: number|null,
        }
    }
};

export default class RankTable extends DashboardComponent
{
    private $table: JQuery;
	private table: Table;
	public _data: TRankTableData;

    init() {
        super.init();
        this.$table = this._$element.find('[data-table]');

        const columns: Column[] = [];
		this.$table.find('thead th').each((i, th) => {
			const $th = $(th);
			columns.push({
				id: $th.data('field') ?? '' + i,
				label: $th.text().trim(),
				formatter: value => String(value ?? '-'),
			});
		});

		this.table = new Table(this.$table, columns);

		this._$element.find('[data-chart-export-csv]').on('click', (e) => {
			e.preventDefault();
			arrayToCsvDownload(
				this.table.arrayExport(),
				e.currentTarget.dataset.chartExportCsv
			);
		});
	}

    onUpdate(data: TRankTableData): void {
        const rows = this.prepareRows(data.rows);
        let finalRows = [];

        if (data.show.rows.first !== null && rows.length > data.show.rows.first) {
            finalRows = finalRows.concat(rows.slice(0, data.show.rows.first));
        }

        if (data.show.rows.last !== null && rows.length > data.show.rows.last && rows.length - finalRows.length > 0) {
            let remaining = rows.length - finalRows.length;
            finalRows = finalRows.concat(rows.slice(-1 * Math.min(data.show.rows.last, remaining)));
        }

        if (finalRows.length === 0) {
            finalRows = rows;
        }

        this.table.load(finalRows);

        this.decorate(data);
    }

	prepareRows(rows: TRow[]): { [columnId: string]: string }[] {
		let prepared = [];
		for (let r in rows) {
			const row = rows[r];
			for (let colId in row) {
				if (row[colId] === undefined || row[colId] === null) {
					continue;
				}

				if (prepared[r] === undefined) {
					prepared[r] = {};
                }

                const field = row[colId];
                if (typeof field === 'object') {
                    if (field.value === undefined || field.value === null) {
                        continue;
                    }
                    prepared[r][colId] = field['value'];
                } else {
                    prepared[r][colId] = field;
                }
            }
        }

        return prepared;
    }

    decorate(data): void {
        for (let r in data.rows) {
            const row = data.rows[r];
            for (let c in row) {
                if (row[c] === undefined || row[c] === null) {
                    continue;
                }

                const data = row[c];
                if (typeof data === 'object') {
                    if (data['value'] === undefined || data['value'] === null) {
                        continue;
                    }

                    const field = this.$table.find(`tr[data-index="${r}"] td[data-field="${c}"]`);
                    if (field.length && data['attributes'] !== undefined) {
                        const parent = field.closest(`tr`);
                        for (let attrName in data['attributes']) {
                            parent.attr(attrName, data['attributes'][attrName].join(' ')); // this could override attributes from iterations before
                            field.attr(attrName, data['attributes'][attrName].join(' '));
                        }

                    }
                }
            }
        }
    }
}

ComponentManager.registerComponentClass('RankTable', RankTable);