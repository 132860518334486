import IOptions from "../../../shared/highcharts/options/IOptions";
import TDataPieChart from "../types/TDataPieChart";
import ChartConstants from "../../../shared/highcharts/ChartConstants";
import {Options} from "highcharts";

export default class PieChartOptions implements IOptions {
    options(data: TDataPieChart): Partial<Options> {

        return {
            "chart": {
                "plotBorderWidth": 0,
                "type": "pie",
                "height": "100%",
                "backgroundColor": ChartConstants.PLOT_BACKGROUND,
                "style": {
                    "fontFamily": ChartConstants.FONT_FAMILY,
                },
            }
        }
    }
}