import FilterItemTiles from "../FilterItemTiles";
import {TFilterValueTileMatrix, TFilterValueTileRow} from "../../../model/filterTypes";
import {Vnode} from "mithril";

export default abstract class SidebarItemTile {
    protected constructor(
        protected readonly filterItem: FilterItemTiles,
        public readonly definition: TFilterValueTileRow|TFilterValueTileMatrix,
    ) { }

    public toggleState(): void {
        this.filterItem.setState(!this.filterItem.getState(this.definition), this.definition);
    }

    public isChecked(): boolean {
        return this.filterItem.getState(this.definition);
    }

    abstract view(): Vnode|null;
}