import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';

/**
 * Static component shows HTML from component config
 * No JS need fo this.
 */
export default class Blank extends DashboardComponent
{ }

ComponentManager.registerComponentClass('Blank', Blank);