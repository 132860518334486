export default class TranslatableElement
{
    constructor($element, name, options) {
        this._$element = $element;
        this._name = name;
        this._options = options;
        this._afterTranslateCallback = null;
        this.init();
    }

    init() {
        const messages = window.messages || {translate: 'Translate'};
        this.$icon = $(`<div class="translation-translate" title="${messages.translate}">${messages.translate}</span>`);
        const innerHtml = this._$element.html().trim();
        if (innerHtml) {
            this.$icon.on('click', () => {
                const $source = $(`<div class="translation-source">${innerHtml}</div>`);
                const $target = $('<div class="translation-target"></div>');
                this._$element.addClass('translating')
                this._$element.empty().append($source).append($target);
                this.translate($target);
            });
            this._$element.append(this.$icon);
        }
    }

    translate($target) {
        $.ajax({
            url: this._$element.data('url'),
            success: (data) => {
                this._$element.removeClass('translating').addClass('translated');
                $target.text(data.translation);
                if (typeof this._afterTranslateCallback === "function") {
                    this._afterTranslateCallback(translation, this);
                }
            },
            error: (xhr) => {
                console.warn(xhr);
                this._$element.removeClass('translating').addClass('error');
                $target.text('Err: ' + ((xhr.responseJSON && xhr.responseJSON.message) || 'For further information check console'));
            }
        })
    }

    /**
     * Set callback after translate
     * @param callback (translation: string, context: TranslatableElement) => void
     */
    setAfterTranslateCallback(callback) {
        this._afterTranslateCallback = callback;
    }
}