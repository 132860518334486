import ClassicExtension from "../../ClassicExtension";
import rangePlugin from "./plugins/rangePlugin";
import flatpickr from "flatpickr";
import getFlatpickrLocale from "./getFlatpickrLocale";


export default class FlatpickrExtension extends ClassicExtension {

    attach($root: JQuery) {
        let flatpickrInputs = document.querySelectorAll('.flatpickr');
        for (let i = 0, size = flatpickrInputs.length; i < size; i++) {
            const input = <HTMLInputElement>flatpickrInputs[i];
            // @ts-ignore
            if (input._flatpickr === undefined) {
                const positionElement = $(input).closest('th').get(0);
                const plugins = [];
                if (input.classList.contains('flatpickr-multiinput-start')) {
                    const sibling = <HTMLInputElement>($(input).parent().find('.flatpickr-multiinput-end').get(0));
                    plugins.push( rangePlugin({input: sibling}) );
                    this.connectRangeInputs(input, sibling);
                }

                flatpickr(input, {
                    locale: getFlatpickrLocale(),
                    plugins: plugins,
                    allowInput: true,
                    positionElement: positionElement || null,
                    disableMobile: true,
                });
            }
        }
    }

    private connectRangeInputs(first: HTMLInputElement, second: HTMLInputElement): void {
        first.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'ArrowDown') {
                const position = Math.min(first.selectionStart, second.value.length);
                second.focus();
                setTimeout(() => second.setSelectionRange(position, position),1);
            }
        });
        second.addEventListener('keydown', (e: KeyboardEvent) => {
            switch (e.key) {
                case 'ArrowUp': {
                    const position = Math.min(second.selectionStart, first.value.length);
                    first.focus();
                    first.setSelectionRange(position, position)
                    break;
                }
                case 'ArrowLeft': {
                    const position = Math.max(second.selectionStart-1, 0);
                    second.setSelectionRange(position, position);
                    break;
                }
                case 'ArrowRight': {
                    const position = Math.min(second.selectionEnd+1, second.value.length);
                    second.setSelectionRange(position, position);
                    break;
                }
            }
            if (e.key === 'ArrowUp') {

            }
        })
    }

}
