// @ts-ignore
import cs from "../../../../app/lang/js.cs.neon";
// @ts-ignore
import en from "../../../../app/lang/js.en.neon";
// @ts-ignore
import sk from "../../../../app/lang/js.sk.neon";
// @ts-ignore
import hu from "../../../../app/lang/js.hu.neon";
import Messages from "./Messages";

export default class Translator {
    private readonly messages: Messages;
    public readonly lang?: "cs"|"en"|"sk"|"hu"|string

    private static instance;

    public static getInstance(lang?: string): Translator {
        if (!this.instance || (lang && this.instance.lang !== lang)) {
            this.instance = new Translator(lang);
        }
        return this.instance;
    }

    private constructor(lang?: string) {
        this.lang = lang ?? $('html').attr('lang');

        switch (this.lang) {
            case "cs": this.messages = new Messages(cs); break;
            case "en": this.messages = new Messages(en); break;
            case "sk": this.messages = new Messages(sk); break;
            case "hu": this.messages = new Messages(hu); break;

            default:
                this.messages = new Messages(en);
                console.warn(`Language "${this.lang}" was not recognised, translator fallback to "en".`);
        }
    }

    public translate(message: string, replace?: {[key: string]: string}): string {
        return this.messages.get(message, replace);
    }
}
