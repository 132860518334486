import translator from "../../../../translator";
import TableExcelFilter from "./TableExcelFilter";

type Value = null | string | number;
type Row = Value[] | { [field: string | number]: Value } & { _attributes?: any };
type ColumnContext = { col: Column, colIndex: number | string, columns: Column[], table: Table };
type RowContext = { row: Row, rowIndex: number, table: Table, $tr?: JQuery<HTMLTableRowElement> };
type CellContext = ColumnContext & RowContext;
export type Column = {
	id: number | string,      													// Cell value is row[id].
	label: string,          													// Label of column in table heading.
	class?: string,         													// Add given class to every cell on column.
	classer?: (value: Value, row: Row, context: CellContext) => string,       	// Add class to cell by callback
	formatter?: (value: Value, row: Row, context: CellContext) => string,     	// Format value of cell
	styler?: (value: Value, row: Row, context: CellContext) => string,        	// Add style to cell
	th?: boolean | ((value: Value, row: Row, context: CellContext) => boolean), 	// Is cell th?
	hider?: (rows: Row[], context: ColumnContext) => boolean,                 	// Hide column?
	filterable?: boolean,   													// Is column filterable
	sortable?: boolean,     													// Is column sortable
	exportLabel?: string,											            // Label of column for export
	exportHider?: boolean | ((rows: Row[], context: ColumnContext) => boolean),    	// Hide column for export?
	exportFormatter?: (value: Value, row: Row, context: CellContext) => string,	// Format value of cell for export
};
type SortOptions = {
    order: 'ASC'|'DESC';
    column: null|string|number;
};

export default class Table {

    public readonly sortOptions: SortOptions = {column: null, order: 'ASC'};

    private rows: Row[] = [];
    private filters: TableExcelFilter[] = [];

	private $search?: JQuery<HTMLInputElement>;
	private rowFormatter?: ($tr: JQuery, row: Row, i: number, rows: Row[]) => void;
	private hasThead = true;

	constructor(
		public readonly $table: JQuery,
		public readonly columns: Column[],
	) {
	}

	/**
	 * Fill table with data
	 */
	public load(rows: Row[]): Table {
		this.rows = rows;
		this.redraw();
		this.filters.forEach(filter => filter?.loadItems());

		return this;
	}

	/**
	 * Force redraw of table element
	 */
	public redraw(): void {
		this.redrawThead();
		this.redrawTbody();
	}

	/**
	 * Set external element as search input for table
	 */
	public setSearch($search: JQuery<HTMLInputElement>): Table {
		this.$search = $search;
		let timeout;
		$search.off('change input').on('change input', () => {
			if (timeout) {
				clearTimeout(timeout);
			}
			timeout = setTimeout(() => this.redrawTbody(), 388);
		});

		return this;
	}

	/**
	 * Enable / disable draw of thead
	 * it comes handy, when you do not want to redraw thead your custom thead
	 */
	public setHasThead(hasThead: boolean): Table {
		this.hasThead = hasThead;

		return this;
	}

	/**
	 * Something like Before row render event
	 */
	public setRowFormatter(formatter: ($tr: JQuery, row: Row, i: number, rows: Row[]) => void) {
		this.rowFormatter = formatter;

		return this;
	}

	/**
	 * Export last loaded data to two-dimensional array
	 * first row is heading, rest is content
	 */
	public arrayExport(): (string | number | boolean | null)[][] {
		const result = [];
		const columns = this.columns.filter((col, i) => {
			if (typeof col.exportHider === 'boolean') {
				return col.exportHider;
			} else if (typeof col.exportHider === 'function') {
				return col.exportHider(this.rows, {col: col, colIndex: i, columns: this.columns, table: this});
			} else {
				return true;
			}
		});
		const rows = this.sortRows(this.rows);

		// heading
		result.push(columns.map(c => c.exportLabel ?? c.label));

		// content
		rows.forEach((row, i) => {
			result.push(this.columns.map((col, j) => {
				if (col.exportFormatter) {
					return col.exportFormatter(row[col.id], row, {
						row: row,
						col: col,
						rowIndex: i,
						colIndex: j,
						columns: this.columns,
						table: this
					});
				}
				return row[col.id] ?? '';
			}))
		});

		return result;
	}


	private updateSortOptions(colId: string | number): void {
        if (this.sortOptions.column === colId) {
            this.sortOptions.order = this.sortOptions.order === 'ASC' ? 'DESC' : 'ASC';
            return;
        }
        this.sortOptions.column = colId;
        this.sortOptions.order = 'ASC';
    }

    private getSortIcon(colId: string|number): string {
        if (this.sortOptions.column === colId) {
            return this.sortOptions.order === 'ASC' ? 'ico-sort-asc-arr' : 'ico-sort-desc-arr';
        }
        return 'ico-sort-default';
    }

    private redrawThead(): void {
        if (!this.hasThead) {
            return;
        }
        let $thead = this.$table.find('thead');
        if ($thead.length === 0) {
            $thead = $(`<thead class="thead-light"></thead>`);
            this.$table.append($thead);
        }
        $thead.html('');

        const $tr = $('<tr></tr>')
        this.columns.forEach((col, i) => {
            if (col.hider?.(this.rows, {col: col, colIndex: i, columns: this.columns, table: this})) {
                return;
            }
            const $th = $(`<th data-field="${col.id}" class="${col.class || ''}"></th>`);
            const $content = $(`<div><span>${col.label}</span></div>`);


            if (col.sortable) {
                const $sortControl = $(`<a href="#"><i class="icon ${this.getSortIcon(col.id)}" title="${translator.translate('sort_default')}"></i></a>`)
                $sortControl.on('click', (e) => {
                    e.preventDefault();
                    this.updateSortOptions(col.id);
                    this.redraw();
                });
                $content.append($sortControl);
            }

            $th.append($content);

            $tr.append($th);

            if (col.filterable) {
                this.filters[i] ??= new TableExcelFilter(this.$table, $content, () => this.redraw());
                this.filters[i]?.attach($content);
            }
        });

        $thead.append($tr);
    }

    private sortRows(rows: Row[]): Row[] {
        if (!this.sortOptions.column) {
            return rows;
        }
        const sorted = rows.sort((a,b) => {
            const va = a[this.sortOptions.column];
            const vb = b[this.sortOptions.column];
            if (typeof va === 'number' && typeof vb === 'number') {
                return va - vb;
            } else if (typeof va === 'string' || typeof vb === 'string') {
                return String(va).localeCompare(String(vb));
            }
            return 0;
        });

        return this.sortOptions.order === 'ASC' ? sorted : sorted.reverse();
    }

    private filterRows(rows: Row[]): Row[] {

        rows = rows.filter(row => {
            return this.columns.every((col, i) => {
                if (!this.filters[i] || !this.filters[i].getSelected().length) {
                    return true;
                }
                const needle = row[col.id];
                if (!needle) {
                    return false;
                }
                return this.filters[i].getSelected().indexOf(String(needle)) !== -1;
            });
        })

        const searchValue = this.$search ? String(this.$search.val()).toLocaleLowerCase() : '';
        if (searchValue) {
            rows = rows.filter(row => {
                const values = (Array.isArray(row) ? row : Object.values(row)).filter(v => typeof v === 'string').map(v => v.toLocaleLowerCase());
                return values.some(v => v.indexOf(searchValue) !== -1);
            });
        }

        return rows;
    }

    private redrawTbody(): void {
        let $tbody = this.$table.find('tbody');
        if ($tbody.length === 0) {
            $tbody = $(`<tbody></tbody>`);
            this.$table.append($tbody);
        }
        $tbody.html('');

        if (!this.rows.length) {
            $tbody.append($(`<tr><td style="text-align: center" colspan="${this.columns.length}">${translator.translate('noData')}</td></tr>`));
            return;
        }

        const rows = this.filterRows(this.sortRows(this.rows));

        rows.forEach( (row, i) => {
            const $tr = <JQuery<HTMLTableRowElement>>($(`<tr data-index="${i}"></tr>`));
            this.columns.forEach((col,j) => {
                const context: CellContext = {
                    row: row,
                    col: col,
                    rowIndex: i,
                    colIndex: j,
                    columns: this.columns,
                    table: this,
                    $tr: $tr,
                };
                if (col.hider?.(this.rows, context)) {
                    return;
                }
                const value = row[col.id];
                const formattedValue = col.formatter?.(value, row, context) ?? value ?? '';
                const style = col.styler?.(value, row, context) ?? '';
                const tag = (col.th === true || (typeof col.th === 'function' && col.th(value, row, context))) ? 'th' : 'td';
                $tr.append($(`<${tag} style="${style}" class="${this.getHtmlClass(col, value, row, context)}" data-field="${col.id}">${formattedValue}</td>`))
            });
            this.rowFormatter?.($tr, row, i, rows);
            $tbody.append($tr);
        })
    };

    private getHtmlClass(col: Column, value: Value, row: Row, context: CellContext): string {
        const classList = [];
        if (col.class) {
            classList.push(col.class);
        }
        const generatedClass = col.classer?.(value, row, context);
        if (generatedClass) {
            classList.push(generatedClass);
        }
        return classList.join(' ');
    }
}




