import IPrintChange from "./IPrintChange";
import Highcharts from "highcharts";
import ChartConstants from "../../../../dashboardsComponents/shared/highcharts/ChartConstants";

export default class SetupHighcharts implements IPrintChange {

    constructor(
        private readonly printPlotBackground?: string
    ) { }

    prepare(): void {
        Highcharts.charts.filter(ch => ch).forEach((chart: any) => {
            if (this.printPlotBackground) {
                chart.chartBackground.css({
                    color: this.printPlotBackground ?? ChartConstants.PLOT_BACKGROUND,
                });
            }
            chart.reflow();
        });
    }

    revert(): void {
        Highcharts.charts.filter(ch => ch).forEach((chart: any) => {
            if (this.printPlotBackground) {
                chart.chartBackground.css({
                    color: ChartConstants.PLOT_BACKGROUND,
                });
            }
            chart.reflow();
        });
    }

}
