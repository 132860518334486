import IOptions from "../../../shared/highcharts/options/IOptions";
import TDataRangeChart, {TDatasetRange} from "../types/TDataRangeChart";
import {createValueFormatterFromDataset} from "../../../shared/highcharts/formatters";
import ChartColors from "../../../../../../js/utility/ChartColors";
import translator from "../../../../translator";
import {transformRangeDataBoxplotPoint, transformRangeDataScatterPoint} from "../transforms/transformRangeDataPoint";

export default class SeriesOptions implements IOptions {

    private colors(dataset: TDatasetRange) {
        return {
            boxplot: dataset.color ?? ChartColors.COLORS[0],
            min: dataset.colorMin ?? dataset.color ?? ChartColors.COLORS[0],
            total: dataset.colorTotal ?? ChartColors.COLOR_TOTAL,
            max: dataset.colorMax ?? dataset.color ?? ChartColors.COLORS[0],
        }
    }

    options(data: TDataRangeChart): any {
        const series = [];

         data.datasets.forEach((dataset, datasetIndex) => {
            const formatter = createValueFormatterFromDataset(dataset);
            const colors = this.colors(dataset);
            const trinities = dataset.dataTotal.map((p,i) => ({
                min: dataset?.dataMin?.[i] ?? null,
                total: dataset?.dataTotal?.[i] ?? null,
                max: dataset?.dataMax?.[i] ?? null
            }));

            series.push({
                type: "boxplot",
                name: dataset.label,
                color: colors.boxplot,
                medianColor: colors.total,
                custom: {
                    index: datasetIndex,
                },
                data: trinities.map( (trinity,i) => transformRangeDataBoxplotPoint(trinity, i, formatter)),
            });

            const scatterData = trinities.map((trinity,i) => transformRangeDataScatterPoint(trinity,i,formatter));
            ['min', 'total', 'max'].forEach((type,i) => {
                series.push({
                    name: dataset.label+' - '+translator.translate(type),
                    type: 'scatter',
                    data: scatterData.map(sd => sd[i]),
                    color: colors[type],
                    marker: {
                        lineColor: colors[type],
                    }
                })

            });
        });

        return {
            series: series,
        }

    }

}
