import ClassicExtension from "../../ClassicExtension";
import translator from "../../../translator";
import "bootstrap-select";

$.fn.selectpicker.Constructor.DEFAULTS.dropupAuto = false; // dropdown forced to drop down
$.fn.selectpicker.Constructor.DEFAULTS.noneSelectedText = translator.translate('none_selected_text');
$.fn.selectpicker.Constructor.DEFAULTS.countSelectedText = translator.translate('count_selected_text');
$.fn.selectpicker.Constructor.DEFAULTS.selectAllText = translator.translate('select_all_text');
$.fn.selectpicker.Constructor.DEFAULTS.deselectAllText = translator.translate('reset');
$.fn.selectpicker.Constructor.DEFAULTS.iconBase = 'icon';
$.fn.selectpicker.Constructor.DEFAULTS.tickIcon = 'ico-check';
$.fn.selectpicker.Constructor.DEFAULTS.actionsBox = false;
$.fn.selectpicker.Constructor.DEFAULTS.liveSearch = false;
$.fn.selectpicker.Constructor.DEFAULTS.virtualScroll = false;

export default class SelectpickerExtension extends ClassicExtension {

    init() {
        if (typeof $.fn.selectpicker !== 'function') {
            console.error('$.fn.selectpicker not found.');
            return;
        }

        super.init();
    }

    attach($root: JQuery) {
        $root.find('.selectpicker').each((i, select) => {
            const $select = $(select);

            const optionsCount = $select.find('option').length;
            const $container = this.resolveParentContainer($select);

            $select.selectpicker('destroy');
            if ($container || optionsCount > 7) {
                $select.selectpicker({
                    liveSearch: optionsCount > 7,
                    container: <string><unknown>$container, // override ts, container can be JQuery
                });
            } else {
                $select.selectpicker()
            }

            $select.on('show.bs.select', () => $('body').addClass('stop-scrolling'));
            $select.on('hide.bs.select', () => $('body').removeClass('stop-scrolling'));
        });
    }

    /**
     * Set parent container to prevent scroll inside responsive tables
     */
    private resolveParentContainer($select: JQuery): JQuery|undefined {
        const parentContainerSelector = $select.data('parent-container');
        if (!parentContainerSelector) {
            return undefined;
        }

        let $container;
        try {
            $container = $select.closest(parentContainerSelector);
        } catch (e) {
            // probably wrong selector
            console.warn('Data attribute parent-container of select is wrong.', $select, e);
        }

        return $container?.length ? $container : undefined;
    }

}
