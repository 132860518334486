import normalizeString from "./normalizeString";

export default class StringMatcher {
    public readonly descriptors;


    constructor(
        descriptor: string
    ) {
        this.descriptors = normalizeString(descriptor).split(' ');
    }

    /**
     * Expecting normalized query, ex.: "brno stred"
     * @param query
     */
    public match(query: string): boolean {
        // every query part match...
        return query.split(' ').every(q => {
            // ...at least one part of descriptor (descriptorPart.startsWith)
            return this.descriptors.some(d => d.indexOf(q) === 0);
        });
    }

}