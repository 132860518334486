export default class UserScoCell
{
    constructor($element, name, options) {
        this._$element = $element;
        this._name = name;
        this._options = options;
        this._$componentUserSco = $('[data-component-user-sco]');
        this._$userScoUserIdInput = this._$componentUserSco.find('input[name="user_id"]');
        this._$userScoModalHeader = this._$componentUserSco.closest('.modal').find('[data-modal-header]');
        this._$userScoScInput = this._$componentUserSco.find('*[name="service_classification"]');
        this._$userScoScoInput = this._$componentUserSco.find('*[name="service_classification_option"]');
        this._userId = this._$element.data('user-id');
        this._modalHeader = this._$element.data('modal-header');
        this._scoId = this._$element.data('sco-id');
        this._scId = this._$element.data('sc-id');
        this._setDefaultsUrl = this._$componentUserSco.data('set-defaults-url');
        this.init();
    }

    init() {
        const self = this;
        this._$element.on('click', () => {

            self._$userScoUserIdInput.val(self._userId);
            self._$userScoScInput.val(self._scId);
            self._$userScoModalHeader.text(self._modalHeader);

            this._$componentUserSco.css('opacity', 0.1);

            $.nette.ajax({
                type: 'GET',
                url: self._setDefaultsUrl,
                data: {
                    'scoId': self._scoId,
                },
                success: () => {
                    this._$componentUserSco.css('opacity', 1);
                },
            });
        });
    }
}