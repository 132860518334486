import ValueFormatter from "../../../model/ValueFormatter";
import ExtendedPointOptionsObject from "./ExtendedPointOptionsObject";
import TDataset from "./TDataset";
import {TDatasetRange} from "../../RangeChart/js/types/TDataRangeChart";
import TRepresentation from "./TRepresentation";

export function createValueFormatterFromDataset(dataset?: TDataset|TDatasetRange): ValueFormatter {
    return new ValueFormatter(dataset?.representation?.precision, dataset?.representation?.unitMark, dataset?.representation?.emptyValue, dataset?.representation?.decimalSeparator)
}

export function formatPointValue(point: ExtendedPointOptionsObject): string {
	const formatter = point.custom?.formatter;
	return formatter ? formatter.format(point.y) : String(point.y);
}

export function formatPointCount(point: ExtendedPointOptionsObject): string {
	return 'n=' + (point.custom?.count ?? 0);
}


export function formatTooltip(
	params: Partial<{
		label: string,
		color: string,
		value: number,
		formattedValue: string,
		count: number,
		isLowBase: boolean,
		formatter: ValueFormatter,
		representation: TRepresentation,
		previous: Partial<{
			value: number,
			count: number,
			isLowBase: boolean,
			formatter: ValueFormatter,
			representation: TRepresentation,
		}>,
	}>
): string {

	let res = '';

	// Current point
	const dot = params.color ? `<span style="color:${params.color}">●</span> ` : '';
	const formatter = params.formatter ?? new ValueFormatter(params.representation?.precision, params.representation?.unitMark, params.representation?.emptyValue, params.representation?.decimalSeparator);
	const value = params.formattedValue ?? formatter.format(params.value);
	const count = 'count' in params ? 'n=' + (params.count ?? 0) : '';
	const separator = (value && count) ? ', ' : '';
	const countColor = params.isLowBase ? 'color:red' : '';

	res += params.label ? `${dot}${params.label}<br>` : '';
	res += `<span>${value}</span>${separator}<span style="${countColor}">${count}</span>`;

	if (params.previous && params.previous.value !== null) {
		const previousFormatter = params.previous.formatter ?? (params.previous.representation ? new ValueFormatter(params.previous.representation?.precision, params.previous.representation?.unitMark, params.previous.representation?.emptyValue, params.previous.representation?.decimalSeparator) : formatter);
		const previousValue = previousFormatter.format(params.previous.value);
		const previousCount = 'count' in params.previous ? 'n=' + (params.previous.count ?? 0) : '';
		const previousSeparator = (previousValue && previousCount) ? ', ' : '';
		const previousCountColor = params.previous.isLowBase ? 'color:red' : '';
		const diff = formatter.getRoundedValue(value) - formatter.getRoundedValue(previousValue);
		const diffFormatted = (diff > 0 ? '+' : '') + formatter.format(diff);
		const diffColor = diff > 0 ? 'color:green' : (diff < 0 ? 'color:red' : '');

		res += `<br><span style="color:#909090;font-size:10px">${previousValue} (<span style="${diffColor}">${diffFormatted}</span>)${previousSeparator}<span style="color:${previousCountColor}">${previousCount}</span></span>`;
	}

	return res;
}