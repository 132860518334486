import {Chart} from "highcharts";

export default function actionToggleDataLabels(chart: Chart, $input: JQuery<HTMLInputElement>): void {
    const checked = $input.is(':checked');
    chart?.update({
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: checked,
                }
            }
        },
    });
    if (checked) {
        $input.closest('label.icon').removeClass('ico-tag').addClass('ico-tag-outline');
    } else {
        $input.closest('label.icon').removeClass('ico-tag-outline').addClass('ico-tag');
    }
}
