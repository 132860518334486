import IOptions from "./IOptions";
import {Options} from "highcharts";

export default class CustomOptions implements IOptions {

    options(data: any): Partial<Options> {
        return typeof data.chart === "object" ? data.chart : {};
    }

}
