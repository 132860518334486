import TDataBarChart from "./types/TDataBarChart";
import {Options} from "highcharts";
import OptionsComposer from "../../shared/highcharts/options/OptionsComposer";
import BarChartOptions from "./options/BarChartOptions";
import PlotOptionsBarOptions from "./options/PlotOptionsBarOptions";
import ResponsiveBarOptions from "./options/ResponsiveBarOptions";
import SeriesBarOptions from "./options/SeriesBarOptions";
import TooltipBarOptions from "./options/TooltipBarOptions";
import XAxisBarOptions from "./options/XAxisBarOptions";
import YAxisBarOptions from "./options/YAxisBarOptions";
import CreditsOptions from "../../shared/highcharts/options/CreditsOptions";
import ExportingOptions from "../../shared/highcharts/options/ExportingOptions";
import PlotOptionsDataLabelsOptions from "../../shared/highcharts/options/PlotOptionsDataLabelsOptions";
import PlotOptionsSeriesExtendLegendControlOptions
    from "../../shared/highcharts/options/PlotOptionsSeriesExtendLegendControlOptions";
import PlotOptionsSeriesLegendHighlightingOptions
    from "../../shared/highcharts/options/PlotOptionsSeriesLegendHighlightingOptions";
import PlotOptionsSeriesPointClickOpenLinkOptions
    from "../../shared/highcharts/options/PlotOptionsSeriesPointClickOpenLinkOptions";
import TitleOptions from "../../shared/highcharts/options/TitleOptions";
import CustomOptions from "../../shared/highcharts/options/CustomOptions";
import AddTargetsModifier from "../../shared/highcharts/modifiers/AddTargetsModifier";
import LowBaseModifier from "../../shared/highcharts/modifiers/LowBaseModifier";
import LegendOptions from "../../shared/highcharts/options/LegendOptions";
import ChartConstants from "../../shared/highcharts/ChartConstants";
import PlotOptionsSeriesVisibilityOfAssociatedPlotLines
	from "../../shared/highcharts/options/PlotOptionsSeriesVisibilityOfAssociatedPlotLines";
import LegendHoverHighlightsTotalLine from "./modifiers/LegendHoverHighlightsTotalLine";

export default class BarOptionsTransformer {

    private readonly composer: OptionsComposer;

    constructor() {
        this.composer = new OptionsComposer([
            new BarChartOptions(),

            new CreditsOptions(),
            new ExportingOptions(),
            new ResponsiveBarOptions(),
            new TitleOptions(),

			new XAxisBarOptions(),
			new YAxisBarOptions(),
			new LegendOptions(),
			new TooltipBarOptions(),
			new SeriesBarOptions(),

			new PlotOptionsBarOptions(),
			new PlotOptionsDataLabelsOptions(),
			new PlotOptionsSeriesExtendLegendControlOptions(),
			new PlotOptionsSeriesVisibilityOfAssociatedPlotLines(),
			new PlotOptionsSeriesLegendHighlightingOptions(),
			new PlotOptionsSeriesPointClickOpenLinkOptions(),

			new CustomOptions(),
		]);
    }

    transform(data: TDataBarChart): Options {
        const options = this.composer.options(data);

        (new AddTargetsModifier(data)).modify(options);

		const lowBase = LowBaseModifier.loadLowBase(data, {base: "category"});

		const lowBasePointCallback = ['filter', 'hide'].includes(lowBase.mode) ? LowBaseModifier.hideCallback : p => p.opacity = ChartConstants.LOW_BASE_OPACITY_BAR;
		const lowBaseOptions = {
			pointCallback: lowBasePointCallback,
			removeCategories: true,
			removeDatasets: true,
		};
		(new LowBaseModifier(lowBase, lowBaseOptions)).modify(options);

		(new LegendHoverHighlightsTotalLine()).modify(options);

		return options;
	}

}
