import m, {Vnode, VnodeDOM} from "mithril";

export default class FilterItemLabel {

    public readonly self: FilterItemLabel;
    private $el: JQuery;

    constructor(
        public readonly label: string,
        public readonly style?: string,
    ) {
        this.self = this;
    }

    public view(): Vnode {
        return m('label', {
            style: this.style || '',
            onmouseenter: () => {
                if (this.updateTooltip()) {
                    this.$el.tooltip("show");
                }
            },
            oncreate: (vnode: VnodeDOM) => {
                const el = <HTMLElement>vnode.dom;
                this.self.$el = $(el);
                this.self.$el.tooltip({
                    html: true,
                    trigger: "hover",
                    title: this.label,
                });
                this.updateTooltip();

                $(window).on('resize', () => this.updateTooltip());
            }
        }, m.trust(this.label));
    }

    private updateTooltip(): boolean {
        const enable = this.$el && this.$el.get(0).scrollWidth > this.$el.get(0).offsetWidth;
        this.$el?.tooltip(enable ? "enable" : "disable");
        return enable;
    }
}
