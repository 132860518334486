import {Chart} from "highcharts";
import BarChartConstants from "./BarChartConstants";

/**
 * Counts categories in chart
 *
 * Count is max from count of categories for each series
 * Count of categories in series is count of data points in dataset + count of datapoint in linked series
 *
 * @param chart {Chart}
 */
function getCategoryCount(chart): number {

    return Math.max(
        ...chart.series.map(s => s.data.length + s.linkedSeries.reduce((sum, ls) => sum + ls.data.length, 0))
    );
}

export default function barChartAutoHeight(chart: Chart) {
    if (chart.options?.chart?.type === 'bar') {
        // @ts-ignore
        const categoryHeight = chart.options?.custom?.categoryHeight || BarChartConstants.DEFAULT_CATEGORY_HEIGHT;
        const chartOptionsHeight = (typeof chart.options?.chart?.height === "number") ? chart.options.chart.height : BarChartConstants.DEFAULT_BARCHART_HEIGHT;

        const categoryCount = getCategoryCount(chart);

        const chartComputedHeight = categoryHeight * categoryCount + (chart.chartHeight - chart.plotHeight);
        if (chartComputedHeight > chartOptionsHeight) {
            chart.update({
                chart: {
                    height: chartComputedHeight,
                }
            }, undefined, undefined, false);
        }
    }
}