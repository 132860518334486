import TDataPoint from "../../../shared/highcharts/TDataPoint";
import {Chart, YAxisPlotLinesOptions} from "highcharts";
import createTooltip from "../../../shared/highcharts/tooltip/createTooltip";
import TDataBarChart, {TDatasetBar} from "../types/TDataBarChart";
import CustomTooltipPositioner from "../../../shared/highcharts/CustomTooltipPositioner";
import {createValueFormatterFromDataset, formatTooltip} from "../../../shared/highcharts/formatters";
import LowBaseModifier from "../../../shared/highcharts/modifiers/LowBaseModifier";
import LegendHoverHighlightsTotalLine from "../modifiers/LegendHoverHighlightsTotalLine";

/**
 * Create total line (in form of plot line) instead of bar/column with tooltip and hover effects
 * @see PlotOptionsSeriesVisibilityOfAssociatedPlotLines
 */
export default function transformBarChartTotalPointToPlotLine(params: {
	name: string,
	color: string,
	point: TDataPoint,
	previousPoint?: TDataPoint,
	dataset: TDatasetBar,
	backendDataIndex: number,
	data: TDataBarChart,
}): YAxisPlotLinesOptions {
	let tooltip;
	let tooltipDestroyTimeout;
	let positioner = new CustomTooltipPositioner(2, 0, "left");
	const formatter = createValueFormatterFromDataset(params.dataset);

	return {
		id: "__total_" + params.backendDataIndex,
		width: 2,
		value: params.point.value,
		color: params.color,
		events: {
			mousemove: function (e: any) {
				clearTimeout(tooltipDestroyTimeout);
				tooltip?.destroy();
				tooltip = undefined;

				const lowBaseThreshold = LowBaseModifier.loadLowBase(params.data).threshold;

				const html = formatTooltip({
					label: `${params.dataset.label} - ${params.name}`,
					color: params.color,
					value: params.point.value,
					count: params.point.count,
					formatter: formatter,
					isLowBase: params.point.count < lowBaseThreshold,
					previous: params.previousPoint ? {
						value: params.previousPoint.value,
						count: params.previousPoint.count,
						isLowBase: params.previousPoint.count < lowBaseThreshold,
						formatter: formatter,
					} : undefined,
				});

				/* @ts-ignore */
				const chart: Chart = this.axis.chart;
				const [x, y] = CustomTooltipPositioner.layerXY(e);

				tooltip = createTooltip(chart, html, x, y);
				positioner.place(chart, tooltip, x, y);

				chart.series.every(serie => {
					/* @ts-ignore */
					if (serie.options.custom.index === params.backendDataIndex) {
						LegendHoverHighlightsTotalLine.highlight(serie);
						chart.options.tooltip.enabled = false;
						serie.onMouseOver();
						return false;
					}
					return true;
				});
			},

			mouseout: function (e) {
				tooltipDestroyTimeout = setTimeout(() => {
					tooltip?.destroy();
					tooltip = undefined;
				}, 800);

				/* @ts-ignore */
				const chart: Chart = this.axis.chart;
				chart.options.tooltip.enabled = true;
				chart.series.every(serie => {
					/* @ts-ignore */
					if (serie.options.custom.index === params.backendDataIndex) {
						LegendHoverHighlightsTotalLine.dismissHighlight(chart);
						chart.options.tooltip.enabled = true;
						serie.onMouseOut();
						return false;
					}
					return true;
				});
			},
		}
	};

}
