import IOptions from "./IOptions";
import {Options} from "highcharts";
import TDataBarChart from "../../../BarChart/js/types/TDataBarChart";

export default class TitleOptions implements IOptions {
    options(data: any): Partial<Options> {

        return {
            title: null,
            subtitle: null
        }

    }

}
