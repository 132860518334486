import IModifier from "./IModifier";
import {Options} from "highcharts";

export default class UseRegressionModifier implements IModifier {

    modify(chart: Options): void {
        chart.series.forEach((serie: any) => {
            serie.regression = serie.regressionSettings?.type && serie.data.filter(p => p?.y).length > 2;
        });
    }
}