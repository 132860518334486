import {PointOptionsObject} from "highcharts";
import TDataPoint from "../../../shared/highcharts/TDataPoint";
import ValueFormatter from "../../../../model/ValueFormatter";

export default function transformBarChartPoint(
    point: TDataPoint,
    backendDataIndex: number,
    name: string,
    color: string,
    formatter: ValueFormatter
): PointOptionsObject {

    return {
        name: name,
        y: point.value,
        custom: {
            index: backendDataIndex,
            count: point.count,
            formatter: formatter,
            link: point.link,
            value: point.value,
            point: point,
        },
        color: color,
    };

}