import ClassicExtension from "../../ClassicExtension";

export default class ClickTargetExtension extends ClassicExtension {

    attach($root: JQuery) {
        const clickables = $root.find('[data-click-target]');

        clickables.each((index, clickable) => {
            const $self = $(clickable);
            const targetSelector = $self.data('click-target');

            if (!targetSelector || $(targetSelector).length === 0) {
                $self.remove();
            }
        });

        clickables.on('click', (e) => {
            e.preventDefault();

            const $clickedElement = $(e.currentTarget);
            const targetSelector = $clickedElement.data('click-target');
            if (!targetSelector) {
                console.warn('Missing target selector.', e.currentTarget);
                return;
            }

            const $target = <JQuery<HTMLInputElement>>targetSelector;
            if (!$target[0]) {
                console.warn('Target not found. Selector: '+targetSelector, );
                return;
            }

            const confirmMessage = $clickedElement.data('confirm');
            if (confirmMessage && !confirm(confirmMessage)) {
                return;
            }

            $(targetSelector)[0].click();
        });
    }

}
