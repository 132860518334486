import {TFilterValueCheckbox} from "../filterTypes";
import FilterItemCheckbox from "../../components/filterItems/FilterItemCheckbox";

export default class CheckboxSelector {

    private lastInteractedCheckbox: TFilterValueCheckbox|null = null;
    private readonly checkboxes: TFilterValueCheckbox[];

    constructor(
        private readonly filterItem: FilterItemCheckbox,
    ) {
        this.checkboxes = this.getFlattenValueCheckboxes();
    }

    public setLastInteractedCheckbox(checkbox: TFilterValueCheckbox): void {
        this.lastInteractedCheckbox = checkbox;
    }

    /**
     * Extend selection to clicked value, follow gmail mail selection UI
     */
    public extendSelection(actualCheckbox: TFilterValueCheckbox): void {

        const value = this.filterItem.getCheckboxCheckedState(actualCheckbox) !== "checked";
        const lastInteractedCheckbox = this.lastInteractedCheckbox || actualCheckbox;

        // set leaves between "last interacted checkbox" and "actual selected checkbox" to value
        this.setLeavesInSelection(actualCheckbox, lastInteractedCheckbox, value);

        // set checked interacted checkbox
        this.filterItem.setState(value, actualCheckbox);

        // set parents by children
        this.setParents();

    }



    // Get all checkboxes flatten, order same as side menu on web
    private getFlattenValueCheckboxes(): TFilterValueCheckbox[] {
        const res = [];
        const traverse = (definition: TFilterValueCheckbox) => {
            res.push(definition);
            definition.values?.forEach(v => traverse(v));
        };
        this.filterItem.definition.values?.forEach(v => traverse(v));
        return res;
    }

    /**
     * Get indexes of checkboxes where begins and ends user actual select
     */
    private getSelectBorders(actual: TFilterValueCheckbox, last:TFilterValueCheckbox): [begin: number, end: number] {
        let begin = -1;
        let end = -1;
        this.getFlattenValueCheckboxes().forEach((checkbox, i) => {
            if (actual === checkbox || last === checkbox) {
                if (begin === -1) {
                    begin = i;
                }
                end = i;
            }
        });
        return [begin, end];
    }

    /**
     * Get indexes of checkboxes where begins and ends checked checkboxes (leaves)
     */
    private getActiveSelectionBorders(leaves: boolean): [begin: number, end: number] {
        let begin = -1;
        let end = -1;
        this.getFlattenValueCheckboxes().forEach((checkbox, i) => {
            if (leaves && checkbox.values?.length) {
                return;
            }
            if (this.filterItem.getState(checkbox) ) {
                if (begin === -1) {
                    begin = i;
                }
                end = i;
            }
        });
        return [begin, end];
    }

    /**
     * check this state:
     * |   ☑️️ ✅ ✅ ☑️ before
     * |   L        A   L = last click, A actual click
     * V   ☑️️ ✅ ✅ ✅️ after  ️
     * @param actual
     * @param last
     * @private
     */
    private isSelectOverExistingSelection(actual: TFilterValueCheckbox, last: TFilterValueCheckbox): boolean {
        const [selectBegin, selectEnd] = this.getSelectBorders(actual, last);
        const [selectionBegin, selectionEnd] = this.getActiveSelectionBorders(true);
        return selectBegin<selectionBegin && selectEnd>selectionEnd;
    }

    private setLeavesInSelection(
        actual: TFilterValueCheckbox,
        last: TFilterValueCheckbox,
        value: boolean
    ): void {
        let selection = false;
        const selectOverExistingSelection = value && this.isSelectOverExistingSelection(actual, last);
        for (const checkbox of this.checkboxes) {
            if (selection || checkbox === actual || checkbox === last) {
                if (checkbox === actual || checkbox === last) {
                    selection = !selection;
                }
                if ((!checkbox.values?.length) // set only leaves
                    && !(selectOverExistingSelection && (checkbox === actual || checkbox === last)) // not set edge checkbox if setting over
                ) {
                    this.filterItem.filter.list().setOptionState(checkbox.id, value);
                }
                if (!selection || actual === last) { // don't run out of selection
                    break;
                }
            }
        }
    }

    /**
     * Set parents value by children
     * @private
     */
    private setParents(): void {
        for (let i = this.checkboxes.length-1; i>=0; i--) {
            const checkbox = this.checkboxes[i];
            if (checkbox.values?.length && checkbox.values?.every( p => this.filterItem.getState(p) === true)) {
                this.filterItem.filter.list().setOptionState(checkbox.id, true);
            }
            if (checkbox.values?.length && checkbox.values?.every( p => this.filterItem.getState(p) === false)) {
                this.filterItem.filter.list().setOptionState(checkbox.id, false);
            }
        }
    }
}