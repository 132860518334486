export default class UploadedFileSetting
{
    constructor(
        private readonly $element: JQuery,
        private readonly name: string,
        private readonly options: any
    ) {
        this.init();
    }

    init() {
        if (this.$element.is('form')) {
            // @ts-ignore
            window.Nette?.initForm(this.$element.get(0));
        }

        const $allowedUsers = this.$element.find('select[name="allowedUsers[]"]');
        $allowedUsers.select2({
            placeholder: $allowedUsers.data('placeholder'),
            width: '100%',
        });
        const $deniedUsers = this.$element.find('select[name="deniedUsers[]"]');
        $deniedUsers.select2({
            placeholder: $deniedUsers.data('placeholder'),
            width: '100%',
        });
    }
}
