import BodyPrintSetup from "./changes/BodyPrintSetup";
import TableAllRowsVisible from "./changes/TableAllRowsVisible";
import ToggleDataLabels from "./changes/ToggleDataLabels";
import SetupHighcharts from "./changes/SetupHighcharts";
import IPrintChange from "./changes/IPrintChange";

type TPrintOptions = {
    $printParent: JQuery|null,
    pageSize: string,
    printiloUrl: string,
    printiloProxyCreate: string,
    printiloProxyDownload: string,
    settingTableVisibleAllRows: boolean,
    settingChartBackgroundColor: string|null,
    settingChartVisibleDatalabels: boolean,
};

export default class PrintAction {

    private readonly options: TPrintOptions;
    private readonly printChanges: IPrintChange[] = [];

    constructor(
        options: Partial<TPrintOptions>,
    ) {
        this.options = {
            $printParent: null,
            pageSize: '1100*1556',
            printiloUrl: '',
            printiloProxyCreate: '',
            printiloProxyDownload: '',
            settingTableVisibleAllRows: false,
            settingChartBackgroundColor: null,
            settingChartVisibleDatalabels: false,

            ...options,
        }

        this.setupPrintChanges();
    }

    private setupPrintChanges(): void {
        this.printChanges.push(new BodyPrintSetup());
        if (this.options.settingTableVisibleAllRows) {
            this.printChanges.push(new TableAllRowsVisible());
        }
        if (this.options.settingChartVisibleDatalabels) {
            this.printChanges.push(new ToggleDataLabels());
        }
        this.printChanges.push(new SetupHighcharts(this.options.settingChartBackgroundColor));
    }

    /**
     * Prepare page before printing
     */
    public prepareForPrinting() {
        this.printChanges.forEach(change => change.prepare());
    }

    /**
     * Revert page after printing
     */
    public revertPrinting(): void {
        this.printChanges.forEach(change => change.revert());
    }

    private printWithPrintilo(printiloOptions: any = {}) {

        const printilo = window['Printilo'];
        if (!printilo) {
            alert('Print error (printilo library is missing). Try to use Chrome or Edge browser.');
            return;
        }

        this.prepareForPrinting();

        printilo.print({
            //htmlId: "phantomjs",
            //printer: "puppeteer",
            pageSize: this.options.pageSize,
            deviceScaleFactor: 2,
            printiloUrl: this.options.printiloUrl,
            createProxyUrl: this.options.printiloProxyCreate,
            downloadProxyUrl: this.options.printiloProxyDownload,
            htmlConvert: (html) => {
                html.find("#tracy-debug").remove();
                html.find(".tracy-debug").remove();
                html.find("#loader").remove();
                html.find("[data-component-board]").removeAttr('data-options');
                html.find("[data-use-stored-data]").attr('data-use-stored-data', 1); // modify dom data attribute
                if (this.options.$printParent) {
                    if (this.options.$printParent) {
                        const $header = html.find('.site-header-phantomjs').clone();
                        const $footer = html.find('.site-footer-phantomjs').clone();
                        const $body = html.children('body');
                        for (const child of $body.children()) {
                            child.remove();
                        }

                        const $page = $('<div class="page"></div>');
                        $page.append(this.options.$printParent.clone());
                        $page.append($footer);
                        $body.append($header);
                        $body.append($page);
                    }
                }
                return html;
            },
            textConvert: (text) => {
                return text
                    .replace('snippet-body', '')
                    .replace(/<!-- Google Tag Manager -->(.|\n)*<!-- End Google Tag Manager -->/g, '')
                    ;
            },
            onStart: () => $('#loader').show(),
            onAfterSend: () => this.revertPrinting(),
            onSuccess: () => $('#loader').hide(),
            onError: (err) => {
                $('#loader').hide();
                alert('Print error. Try to use Chrome or Edge browser.');
                console.log("Printilo ERR: " + err);
            },
            //footer: '',
            ...printiloOptions
        });
    }


    public print() {
        return window['chrome'] ? window.print() : this.printWithPrintilo();
    }

    public async getPdfUrl(): Promise<string> {
        return new Promise( (resolve, reject) => {
            this.printWithPrintilo({
                onSuccess: (response: any) => {
                    $('#loader').hide();
                    this.revertPrinting();
                    resolve(response.location);
                },
                onError: (err: string) => reject(err),
                downloadPdf: false,
            });
        });
    }
}
