import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import Highcharts from 'highcharts';
import 'highcharts/modules/exporting';
import BaseHighchartsComponent from "../../BaseHighchartsComponent";
import TDataPieChart from "./types/TDataPieChart";
import PieChartTransformer from "./PieChartTransformer";

class PieChart extends BaseHighchartsComponent {

    private readonly optionsTransformer: PieChartTransformer;

    constructor(
        componentManager: ComponentManager,
        $element: JQuery<HTMLElement>,
        name: string,
        options: {[name: string]: any}
    ) {
        super(componentManager, $element, name, options);
        this.optionsTransformer = new PieChartTransformer();
    }

    protected addResizer($chart: JQuery) {
        // do not add resizer
    }

    public onUpdate(data: TDataPieChart): void {
        this.chart?.destroy();

        const chartOptions = this.optionsTransformer.transform(data);

        this.chart = Highcharts.chart(this._$element.find('[data-chart]')[0], chartOptions);
    }
}

ComponentManager.registerComponentClass('PieChart', PieChart);
