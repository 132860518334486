import FilterItem from "./FilterItem";
import m, {Vnode} from "mithril";
import {TFilterItemPeriod, TFilterValuePeriod} from "../../model/filterTypes";
import Filter from "../Filter";
import IFilterComponent from "../IFilterComponent";
import SidebarItemPeriod from "./period/SidebarItemPeriod";
import CalendarModal from "./period/CalendarModal";
import ISearchable from "./ISearcheable";
import PeriodSelector from "../../model/Selector/PeriodSelector";
import translator from "../../../../translator";
import PeriodPreSelector from "../../model/Selector/PeriodPreSelector";

export default class FilterItemPeriod extends FilterItem implements IFilterComponent, ISearchable {
    private readonly subtreeItems: SidebarItemPeriod[] = [];
    private isOpen = false;
    private isSearching = false;
    private selector: PeriodSelector;

    private preSelector: PeriodPreSelector;
    private preSelectedExtend: boolean = false;
    private lastClickedPeriod?: TFilterValuePeriod;
    private preSelectedPeriod?: TFilterValuePeriod;

    constructor(
        public readonly definition: TFilterItemPeriod,
        public readonly filter: Filter,
        public parent?: IFilterComponent
    ) {
        super();
        this.filter.search?.register(this);

        if (definition.values) {
            this.subtreeItems = definition.values.map(v => new SidebarItemPeriod(this, v));
        }

        this.selector = new PeriodSelector(this);
        this.preSelector = new PeriodPreSelector(this.selector);
    }

    public isActive(): boolean {
        return this.subtreeItems.some(i => this.getPeriodCheckedState(i.definition) !== "unchecked");
    }

    public reset(): void {
        // chill and do nothing
    }

    /**
     * Select period
     */
    public setPeriod(definition: TFilterValuePeriod): void {
        const list = this.filter.list();
        const selection =  this.selector.select(definition);
        list.clearPeriods();
        list.setShortenedPeriodState(this.selector.getShortenedPeriod(definition));
        selection.forEach(s => list.setPeriodState(s.id,true));
        this.lastClickedPeriod = definition;
    }

    /**
     * Extend selection to clicked value, follow gmail mail selection UI
     */
    public extendSelection(definition: TFilterValuePeriod): void {
        const list = this.filter.list();
        const selection =  this.selector.selectExtend(definition, this.lastClickedPeriod);
        list.clearPeriods();
        list.clearShortenedPeriodState();
        selection.forEach(s => list.setPeriodState(s.id,true));
        this.lastClickedPeriod = definition;
    }

    public onSearch(query: string) {
        this.isSearching = Boolean(query);
    }

    public getState(valueDefinition: TFilterValuePeriod): boolean|null {
        const list = this.filter.list()
        if (!list) {
            return null;
        }
        return list.getPeriodState(valueDefinition.id);
    }

    public onFilterLoadState(): void {
        const list = this.filter.list();
        const periods = list.getState().periods;
        if (periods.length === 1) {
            const periodDefinition = list.periodsById[periods[0]];
            this.setPeriod(periodDefinition);
        }
        this.subtreeItems.forEach(i => i.onFilterLoadState());
        this.isOpen = true;
    }

    /**
     * Get checkbox value of given period
     * returned state can be:
     *   checked     - this period and all children periods are checked
     *   unchecked   - this period and all children periods are unchecked
     *   semichecked - this period has checked and either unchecked children
     *   unknown     - information about period are not available (not loaded yet)
     * @param valueDefinition
     */
    public getPeriodCheckedState(valueDefinition?: TFilterValuePeriod): "checked"|"unchecked"|"semichecked"|"unknown" {
        let hasTrue = false;
        let hasFalse = false;

        const result = () => {
            if (hasFalse && hasTrue) {
                return "semichecked";
            }
            if (hasTrue) {
                return "checked";
            }
            if (hasFalse) {
                return "unchecked";
            }
            return "unknown";
        }

        const traverseFunction = (vd: TFilterValuePeriod) => {
            const state = this.getState(vd);
            hasTrue = hasTrue || state === true;
            hasFalse = hasFalse || state === false;
            if (hasTrue && hasFalse) {
                return;
            }
            if (vd.values) {
                for (const v of vd.values) {
                    traverseFunction(v);
                }
            }
        };
        if (valueDefinition) {
            traverseFunction(valueDefinition);
            return result();
        }

        if (this.definition.values) {
            for (const vd of this.definition.values) {
                traverseFunction(vd);
                if (hasTrue && hasFalse) {
                    return "semichecked";
                }
            }
            return "unknown";
        }
    }

    public isPreselected(period: TFilterValuePeriod): false|'semi-checked'|'checked' {
        return this.preSelector.isPreselected(period, this.preSelectedExtend, this.preSelectedPeriod, this.lastClickedPeriod);
    }

    public setPreselectPeriod(period?: TFilterValuePeriod): void {
        this.preSelectedPeriod = period;
    }

    private keyDown(e: KeyboardEvent) {
        this.preSelectedExtend = e.key === 'Shift';
        m.redraw();
    }
    private keyUp(e: KeyboardEvent) {
        this.preSelectedExtend = false;
        m.redraw();
    }

    // view methods
    viewModalPanel(): Vnode | null {
        return null;
    }

    viewModalSidebar(): Vnode | null {
        return null;
    }

    viewSidebar(): Vnode | null {
        if (this.isSearching) {
            return null;
        }

        const cssClass = '.FilterItem.FilterItem-Group' + (this.isOpen ? '.opened' : '.closed');
        return m(cssClass, {
            oninit: () => {
                window.addEventListener('keydown', this.keyDown.bind(this));
                window.addEventListener('keyup', this.keyUp.bind(this));
            },
            onremove: () => {
                window.removeEventListener('keydown', this.keyDown.bind(this));
                window.removeEventListener('keyup', this.keyUp.bind(this));
            }
        }, [
            m('.item', {
                onclick: (e: MouseEvent) => {
                    e.preventDefault();
                    this.isOpen = !this.isOpen;
                },
            }, [
                m('div.item-label', {}, [
                    m('label', {}, this.definition.label),
                ]),
                m('.control', {}, [
                    m('i.icon.ico-inform-outline', {
                        onclick: e => e.stopPropagation(),
                        oncreate: node => $(node.dom).tooltip({trigger: "hover", title: translator.translate('multi_period_select_hint')}),
                    }),
                    this.definition.filterRender.main?.enableDateRangeInput ? m('i.icon.ico-calendar', {
                        title: translator.translate('calendar'),
                        'data-ga': 'filter-periodCalendar',
                        onclick: (e: MouseEvent) => {
                            e.stopPropagation()
                            this.openModal();
                        }
                    }) : null,
                    this.subtreeItems.length ? m('i.icon.ico-dropdown') : null,
                ]),
            ]),
            m('.subtree', {}, this.isOpen ? this.subtreeItems.map(si => m(si)) : null),
        ]);
    }

    private openModal(): void {
        this.filter.modal = new CalendarModal(this.filter);
    }

}
