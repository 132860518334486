import DashboardComponent from "nms-dashboard-js/src/DashboardComponent";
import ComponentManager from "nms-dashboard-js/src/ComponentManager";

export default abstract class BaseDashboardComponent extends DashboardComponent
{

    constructor(
        componentManager: ComponentManager,
        $element: JQuery<HTMLElement>,
        name: string,
        options: {[name: string]: any}
    ) {
        super(componentManager, $element, name, options);

        $element.find('.nav-tabs input[type="radio"]').each((i, input) => {
            const $input = $(input);

            const activeTabChange = () => {
                const $label = $input.closest('label');
                if ($input.is(':checked')) {
                    $input.closest('.nav-tabs')
                        .find('label.active')
                        .removeClass('active');
                    $label.addClass('active');

                    const $cardTitle = $element.find('.card-title').first();
                    let $onlyPrintSubtitle = $cardTitle.find('span.d-none.d-print-inline');
                    if (!$onlyPrintSubtitle.length) {
                        $onlyPrintSubtitle = $(`<span class="d-none d-print-inline"></span>`);
                        $cardTitle.append($onlyPrintSubtitle);
                    }
                    $onlyPrintSubtitle.text(' - '+$label.text());
                }
            }
            activeTabChange();

            $input.on('change', activeTabChange);
        });
    }

    public init() {
        super.init();
    }

    public update(data)
    {
        if (data.errors) {
            for (let key in data.errors) {
                this.throwError(data.errors[key], data);
            }
        }

        super.update(data);
    }

    protected throwError(errorTitle: string, description: string, ...details: any[]): void {
        let $errorContainer = this._$element.find('.dashboard-component-errors');
        if (!$errorContainer.length) {
            $errorContainer = $('<div class="dashboard-component-errors"></div>');
            this._$element.append($errorContainer);
        }

        $errorContainer.append(`<div class="error" title="${(new Date()).toLocaleString()}">${errorTitle}: ${description}</div>`);
        console.warn(errorTitle, description, details);
    }
}
