import FilterItem from "./FilterItem";
import IFilterComponent from "../IFilterComponent";
import ISearchable from "./ISearcheable";
import StringMatcher from "../../utilities/StringMatcher";
import {
    TFilterItemTilesMatrix,
    TFilterItemTilesRow,
    TFilterValueTileMatrix,
    TFilterValueTileRow
} from "../../model/filterTypes";
import Filter from "../Filter";
import {Vnode} from "mithril";
import SidebarItemTile from "./tile/SidebarItemTile";

export default abstract class FilterItemTiles extends FilterItem implements IFilterComponent, ISearchable {
    public self;

    private isSearching = false;
    private searchMatch = false;
    private readonly searchMatcher: StringMatcher;

    protected subTiles: SidebarItemTile[] = [];

    protected constructor(
        protected readonly definition: TFilterItemTilesRow|TFilterItemTilesMatrix,
        public readonly filter: Filter,
        public parent?: IFilterComponent
    ) {
        super();
        this.self = this;

        this.filter.search?.register(this);
        this.searchMatcher = new StringMatcher(this.definition.label);
    }

    isActive(): boolean {
        return this.definition?.values.some(v => !this.filter.list().getOptionState(v.id));
    }

    public reset(): void {
        this.definition.values.forEach(vd => this.setState(true, vd));
    }

    onSearch(query: string): void {
        this.isSearching = Boolean(query);
        this.searchMatch = this.searchMatcher.match(query);
    }

    public setState(state: boolean, valueDefinition: TFilterValueTileRow|TFilterValueTileMatrix) {
        const list = this.filter.list();
        list.clearFavouriteState();
        list.setOptionState(valueDefinition.id, state);

        this.filter.triggerChange();
    }

    public getState(valueDefinition: TFilterValueTileRow|TFilterValueTileMatrix): boolean {
        return this.filter.list().getOptionState(valueDefinition.id);
    }

    abstract viewModalPanel(): Vnode | null;

    abstract viewModalSidebar(): Vnode | null;

    abstract viewSidebar(): Vnode | null;
}