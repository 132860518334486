import INetteExtension from "../../INetteExtension";

export default class AntispamExtension implements INetteExtension {

    init() {
        setTimeout(() => {
                $('input[name="antispam"]').val('verified');
            }, 666
        );
    }

}
