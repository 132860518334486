export default class Modal {

    private onCancel?: (e)=>any;
    private onOk?: (e)=>any;
    private onAfterRender?: ($modal: JQuery<HTMLElement>)=>any;
    private onBeforeRender?: ($modal: JQuery<HTMLElement>)=>void;

    private icon?: string;

    constructor(
        public readonly header: string,
        public readonly $content: JQuery<HTMLElement>,
        public readonly cancel: string|boolean = 'cancel',
        public readonly ok: string|boolean = 'ok',
    ) { }

    public setOnCancel(onCancel: (e:MouseEvent)=>any) {
        this.onCancel = onCancel;
    }

    public setOnOk(onOk: (e:MouseEvent)=>any) {
        this.onOk = onOk;
    }
    public setOnBeforeRender(onBeforeRender: ($modal: JQuery<HTMLElement>)=>void) {
        this.onBeforeRender = onBeforeRender;
    }
    public setOnAfterRender(onAfterRender: ($modal: JQuery<HTMLElement>)=>void) {
        this.onAfterRender = onAfterRender;
    }

    public setIcon(icon: string): void {
        this.icon = icon;
    }

    public render(): void {
        const $body = $('body');
        const icon = this.icon ? `<i class="icon ico-${this.icon}"></i>` : '';
        const $modal = $(`<div tabindex="-1" role="dialog" id="filterModal" class="modal fade show modal-with-background modal-favourite-add" style="display:block">
                <div role="document" class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            ${icon}
                            <h4>${this.header}</h4>
                            <button class="close"><span>×</span></button>
                        </div>
                        <div class="modal-body">
            
                        </div>
                        <div class="modal-footer">`
                            + (this.cancel !== false ? `<button type="button" class="btn btn-flat-secondary">${this.cancel}</button>` : '')
                            + (this.ok !== false ? `<button type="button" class="btn btn-primary">${this.ok}</button>` : '')
                        +`</div>
                    </div>
                </div>
            </div>
            `);

        const close = () => {
            $modal.remove();
            $body.removeClass('modal-is-open');
        }

        // modal backdrop click
        $modal.on('click', e => {
            if (e.currentTarget === e.target) {
                this.onCancel?.(e);
                close();
            }
        });

        // close with keyboard
        $modal.on('keyup', e => {
            if (e.key === "Escape") {
                this.onCancel?.(e);
                close();
            }
            if ($(e.currentTarget).is('textarea')) {
                return;
            }
            if (e.key === "Enter") {
                this.onOk?.(e);
                close();
            }
        });

        // cancel button
        $modal.find('button.close, .modal-footer .btn-secondary').on('click', (e) => {
            this.onCancel?.(e);
            close();
        });

        // ok button
        $modal.find('.modal-footer .btn-primary').on('click', (e) => {
            this.onOk?.(e);
            close();
        });

        $modal.find('.modal-body').append(this.$content);

        this.onBeforeRender?.($modal);

        $body.addClass('modal-is-open');

        $('.modals-container')
            .html('')
            .append($modal);

        this.onAfterRender?.($modal);
    }

}