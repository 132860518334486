import PrintAction from "./PrintAction";

export default class PrintButton {
    private static printEventInitialized = false;
    private static currentInstanceOfButton: PrintButton;

    protected readonly printAction: PrintAction;

    constructor(
        protected readonly $element: JQuery
    ) {
        const printParent = $element.data("print-parent");
        const $printParent = this.$element.closest(printParent);

        this.printAction = new PrintAction({
            $printParent: $printParent.length ? $printParent : null,
            pageSize: $element.data("page-size"),
            printiloUrl: $element.data("printilo-url"),
            printiloProxyCreate: $element.data("printilo-url-proxy-create"),
            printiloProxyDownload: $element.data("printilo-url-proxy-download"),
            settingTableVisibleAllRows: $element.data('print-setting-table-visible-all-rows'),
            settingChartBackgroundColor: $element.data('print-setting-chart-background-color'),
            settingChartVisibleDatalabels: $element.data('print-setting-chart-visible-all-datalabels'),
        });
        this.init();
    }


    init() {
        this.$element.on('click', e => {
            e.preventDefault();
            PrintButton.currentInstanceOfButton = this;
            this.printAction.print();
        });

        if (!PrintButton.printEventInitialized) {
            PrintButton.printEventInitialized = true;
            if (window.matchMedia) {
                const mediaQueryList = window.matchMedia('print');
                mediaQueryList.addEventListener('change', e => {
                    PrintButton.currentInstanceOfButton ??= this;
                    if (e.matches) {
                        PrintButton.currentInstanceOfButton.printAction.prepareForPrinting();
                    } else {
                        PrintButton.currentInstanceOfButton.printAction.revertPrinting();
                    }
                });
            } else {
                window.onbeforeprint = () => this.printAction.prepareForPrinting();
                window.onafterprint = () => this.printAction.revertPrinting();
            }
        }

    }

}
