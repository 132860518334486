import IOptions from "./IOptions";
import {Options} from "highcharts";

export default class PlotOptionsSeriesExtendLegendControlOptions implements IOptions {

    options(data: any): Partial<Options> {
        return {
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: function (e) {
                            const clickEvent: PointerEvent = e.browserEvent;
                            if (clickEvent.metaKey || clickEvent.ctrlKey) {
                                this.chart.series.forEach(s => s.hide());
                            } else if (clickEvent.altKey) {
                                this.chart.series.forEach(s => s.show());
                            } else if (clickEvent.shiftKey) {
                                this.chart.series.forEach(s => s.visible ? s.hide() : s.show());
                            }
                        }
                    }
                }
            }
        }
    }

}