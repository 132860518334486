export default class SwitchProject {
    constructor(
        private readonly $element: JQuery,
        private readonly name: string,
        private readonly options: any
    ) {
        this.init();
    }

    private init() {
        const $select = this.$element.find('select');
        $select.select2({
            placeholder: $select.data('msg-switch-project'),
            allowClear: true,
            dropdownCssClass: "SwitchProject-dropdown",
        });
        $select.on('change', () => {
            if ($select.val()) {
                window.location.href = '' + $select.val();
            }
        }).on('select2:open', () => {
            console.log('open');
            setTimeout(() => $('.SwitchProject-dropdown input.select2-search__field')[0]?.focus(), 16);
        });
    }
}
