import SidebarItemTile from "./SidebarItemTile";
import {TFilterValueTileRow} from "../../../model/filterTypes";
import FilterItemTilesRow from "../FilterItemTilesRow";
import m, {Vnode, VnodeDOM} from "mithril";

export default class SidebarItemTileRow extends SidebarItemTile {

    constructor(
        filterItem: FilterItemTilesRow,
        definition: TFilterValueTileRow,
    ) {
        super(filterItem, definition)
    }

    view(): Vnode|null {
        const checkedClass = this.isChecked() ? '.checked' : '';
        const hasTooltipClass = this.definition.title ? '.has-tooltip' : '';

        return m(`.option${checkedClass}${hasTooltipClass}[data-id="${this.definition.id}"]`, {
            tabindex: "1",
            onclick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.toggleState();
            },
            oncreate: (vnode: VnodeDOM) => {
                if (this.definition.title) {
                    const $el = $(<HTMLElement>vnode.dom);
                    $el.tooltip({
                        html: true,
                        trigger: "hover",
                        placement: "bottom",
                        title: this.definition.title,
                    });
                }
            }
        }, [
            m('label', {
                style: this.definition.style || '',
            }, m.trust(this.definition.label))
        ])
    }
}