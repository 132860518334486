import m from "mithril";
import {TFilterState, TFilterStructure, TOptionData} from "./filterTypes";
import Board from "../Board";

export type TEndpoints = {
    filterStructure: string,
    filterState: string,
    filterData: string,
    favouriteFilter: string,
    useFavouriteFilter: string,
};

type TFilterStructureResponse = {
    filterStructure: TFilterStructure,
};

type TFilterStateResponse = {
    filterState: TFilterState,
};

type TFilterDataResponse = {
    filterData: TOptionData[],
};

type TFavouriteFilterResponse = {
    favouriteFilters: {uuid: string, name: string}[],
};

export default class Api {

    private requestFilterData: XMLHttpRequest|null = null;
    private requestFilterState: XMLHttpRequest|null = null;

    constructor(
        public readonly board: Board,
        private readonly endpoints: TEndpoints
    ) { }

    /**
     * @see App\Modules\ClientModule\Components\GlobalFilter\GlobalFilter::handleFilterStructure()
     */
    public loadFilterStructure(): Promise<TFilterStructureResponse> {
        return m.request({
            method: 'GET',
            url: this.endpoints.filterStructure,
        });
    }

    /**
     * @see App\Modules\ClientModule\Components\GlobalFilter\GlobalFilter::handleFilterState()
     */
    public loadFilterState(): Promise<TFilterStateResponse> {
        if (this.requestFilterState) {
            this.requestFilterState.abort();
            this.requestFilterState = null;
        }
        return m.request({
            method: 'GET',
            url: this.endpoints.filterState,
            config: xhr => this.requestFilterState = xhr,
        });
    }

    /**
     * @see App\Modules\ClientModule\Components\GlobalFilter\GlobalFilter::handleFilterData()
     */
    public loadFilterData(): Promise<TFilterDataResponse> {
        if (this.requestFilterData) {
            this.requestFilterData.abort();
            this.requestFilterData = null;
        }
        const filterData = this.board.filter.getData();
        return m.request({
            method: 'POST',
            url: this.endpoints.filterData,
            body: {
                globalFilters: {
                    options: filterData.options,
                    period: filterData.periods,
                }
            },
            config: xhr => this.requestFilterData = xhr,
        });
    }

    /**
     * @see App\Modules\ClientModule\Components\GlobalFilter\GlobalFilter::handleFavouriteFilter()
     */
    public loadFavouriteFilters(): Promise<TFavouriteFilterResponse> {
        return m.request({
            method: 'GET',
            url: this.endpoints.favouriteFilter,
        });
    }

    /**
     * @see App\Modules\ClientModule\Components\GlobalFilter\GlobalFilter::handleFilterStructure()
     */
    public saveFavouriteFilter(name: string): Promise<{uuid: string, name: string}> {
        const body = new FormData();
        body.append('name', name);

        return m.request({
            method: 'POST',
            url: this.endpoints.favouriteFilter,
            body: body,
        });
    }

    /**
     * @see App\Modules\ClientModule\Components\GlobalFilter\GlobalFilter::handleFilterStructure()
     */
    public deleteFavouriteFilter(id: string): Promise<{status: "success"}> {
        return m.request({
            method: 'DELETE',
            url: this.endpoints.favouriteFilter,
            params: {id: id},
        });
    }

    /**
     * @see App\Modules\ClientModule\Components\GlobalFilter\GlobalFilter::handleUseFavouriteFilter()
     */
    public useFavouriteFilter(id: string): Promise<{status: "success"}> {
        const body = new FormData();
        body.append('id', id);

        return m.request({
            method: 'POST',
            url: this.endpoints.useFavouriteFilter,
            body: body,
        });
    }


}