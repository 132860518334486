import IModifier from "../../../shared/highcharts/modifiers/IModifier";
import TDataPieChart from "../types/TDataPieChart";
import {Options, SeriesPieOptions} from "highcharts";
import {createValueFormatterFromDataset} from "../../../shared/highcharts/formatters";

export default class TargetsPieModifier implements IModifier {

    constructor(
        private readonly data: TDataPieChart,
    ) { }


    modify(chartOptions: Options) {

        this.data.targets?.forEach((target, i) => {
            const sum = this.data.datasets.map(d => d.data[0].value).filter(v => v!==null).reduce((a,b)=>a+b, 0);
            const valueFormatter = createValueFormatterFromDataset(this.data.datasets[0]);

            const targetValue = target.max ?? target.min ?? target.to ?? target.from ?? 0;
            chartOptions.series.push({
                id: '__target_'+i,
                type: "pie",
                name: target.label,
                colors: [target.color, 'transparent'],
                data: [
                    {
                        y: targetValue,
                        name: target.label,
                        custom: {
                            count: 0,
                            formatter: valueFormatter,
                            link: null,
                            value: targetValue,
                        }
                    },
                    {
                        y: sum - targetValue,
                        name: target.label,
                        custom: {
                            count: 0,
                            formatter: valueFormatter,
                            link: null,
                            value: targetValue,
                        }
                    }
                ],
                showInLegend: false,
                innerSize: '93%',
                size: (68-5*i)+'%',
                borderWidth: 1,
                borderColor: "white",
                dataLabels: {
                    enabled: false,
                },

                // @ts-ignore
                formatters: valueFormatter,
            })
        });
    }

}