import m, {Vnode, VnodeDOM} from "mithril";
import Filter from "../Filter";


export default abstract class FilterModal {

    protected parts = {
       header: true,
       body: true,
       footer: true,
    };

    constructor(
        public readonly filter: Filter,
    ) { }

    public view(): Vnode|null {

        return m(`.modal.fade.show.modal-with-background${this.extendModalClass()}#filterModal`, {
            tabindex: -1,
            role: "dialog",
            style: "display:block",
            oncreate: (vnode: VnodeDOM) => $('body > .modals-container').append($(vnode.dom)),
            onremove: (vnode: VnodeDOM) => $('#filterModal').remove(),
            onclick: (e: MouseEvent) => this.onBackgroundClick(e),
        }, [
            m(".modal-dialog", {
                role: "document",
                onclick: (e: MouseEvent) => e.stopPropagation(),
            }, [
                m(".modal-content", {}, [
                     this.parts.header ? m(".modal-header", {}, this.viewHeader()) : null,
                     this.parts.body ? m(".modal-body", {}, this.viewBody()) : null,
                     this.parts.footer ? m(".modal-footer", {}, this.viewFooter()) : null,
                ]),
            ]),
        ]);
    }

    protected onBackgroundClick(e: MouseEvent):void {
        this.filter.modal = null;
    }

    protected abstract viewHeader(): Vnode[]|Vnode|null;
    protected abstract viewBody(): Vnode[]|Vnode|null;
    protected abstract viewFooter(): Vnode[]|Vnode|null;
    protected abstract extendModalClass(): string;
}