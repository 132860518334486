import IOptions from "../../../shared/highcharts/options/IOptions";
import TDataPieChart from "../types/TDataPieChart";
import {Options} from "highcharts";
import ChartColors from "../../../../../../js/utility/ChartColors";
import ValueFormatter from "../../../../model/ValueFormatter";
import LowBaseModifier from "../../../shared/highcharts/modifiers/LowBaseModifier";
import {createValueFormatterFromDataset} from "../../../shared/highcharts/formatters";


/**
 * Title and subtitle shows total dataset
 */
export default class TitlePieOptions implements IOptions {
    options(data: TDataPieChart): Partial<Options> {

        const lowBase = LowBaseModifier.loadLowBase(data);
        const totalDataset = data.totalDatasets?.[0];

        const title = totalDataset ? createValueFormatterFromDataset(totalDataset).format(totalDataset.data[0]?.value) : '';
        const subtitle = totalDataset?.data?.length ? 'n='+(totalDataset.data[0]?.count ?? 0) : '';
        const subtitleColor = lowBase.threshold > totalDataset?.data?.[0]?.count ? 'red' : "#AAAAAA";

        return {
            "title": {
                "align": "center",
                "verticalAlign": "middle",
                "y": 30,
                "style": {
                    "color": totalDataset?.colorTotal ?? <string|undefined>totalDataset?.color ?? ChartColors.COLOR_TOTAL,
                    "fontSize": "47px"
                },
                text: title,
            },
            "subtitle": {
                "align": "center",
                "verticalAlign": "middle",
                "y": 45,
                "style": {
                    "color": subtitleColor,
                    "fontSize": "16px",
                },
                text: subtitle,
            },
        }
    }
}