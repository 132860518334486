import scrollToHash from "./scrollToHash";

/**
 * Save & load position of scroll in $element relative to visible checkpoint
 * avoid of loyout shift
 */
export default class ScrollKeeper {

    private static LOCAL_STORAGE_NAMESPACE = "scroll_keeper"

    /**
     * Index of first checkpoint element visible in scrollElement
     * @private
     */
    private checkpointIndex?: number;

    /**
     * Relative position of checkpoint on this.checkpointIndex to this.$scrollEl
     * @private
     */
    private position?: number;

    private readonly $window: JQuery<Window>;
    private readonly $relativeEl: JQuery<HTMLElement>;
    private readonly duid: string;

    constructor(
        private readonly checkpointSelector: string,
        private readonly relativeElementSelector: string = 'body',
        private ignoreFirstSave = true,
    ) {
        this.$window = $(window);
        this.$relativeEl = $(relativeElementSelector);
        this.duid = this.getDuid();
        this.loadFromLocalstorage();
    }

    private loadFromLocalstorage(): void {
        const key = this.getLocalStorageKey();
        let item = null;

        try {
            item = localStorage.getItem(key) !== null ? JSON.parse(localStorage.getItem(key)) : null;
        } catch (error) {
            // no problem, continue
        }

        localStorage.removeItem(key);
        if (!item || item.duid !== this.duid) {
            return;
        }

        this.checkpointIndex = item.i ?? 0;
        this.position = item.p ?? 0;
    }

    private saveToLocalStorage(): void {
        const item = {
            i: this.checkpointIndex,
            p: this.position,
            duid: this.duid,
        }

        localStorage.setItem(this.getLocalStorageKey(), JSON.stringify(item));
    }

    private getTop($el: JQuery): number {
        return $el.offset().top - this.$relativeEl.offset().top;
    }

    private getDuid(): string {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const duid = urlParams.get('duid');
        return duid || queryString;
    }

    private getLocalStorageKey(): string {
        return ScrollKeeper.LOCAL_STORAGE_NAMESPACE+'_'+this.relativeElementSelector;
    }

    private scrollToSavedPosition(): boolean {
        if (typeof this.checkpointIndex !== "number" || typeof this.position !== "number") {
            return;
        }
        const checkpoint = $(this.checkpointSelector)[this.checkpointIndex];
        if (!checkpoint) {
            return;
        }
        const checkpointTop = this.getTop($(checkpoint));
        this.$window.scrollTop(checkpointTop - this.position);
    }

    save(): void {
        if (this.ignoreFirstSave) {
            this.ignoreFirstSave = false;
            return;
        }

        const scrollTop = this.$window.scrollTop();
        const $checkpoints = $(this.checkpointSelector);
        this.position = 0;
        this.checkpointIndex = 0;
        $checkpoints.each((i, checkpoint) => {
            const $checkpoint = $(checkpoint);
            const checkpointTop = this.getTop($checkpoint);
            if (checkpointTop > scrollTop) {
                this.checkpointIndex = i;
                this.position = checkpointTop - scrollTop;
                return false;
            }
        });

        this.saveToLocalStorage();
    }

    load(): void {
        scrollToHash() || this.scrollToSavedPosition();
    }

}
