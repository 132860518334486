import Highcharts, {SVGElement} from "highcharts";

/**
 * Place complete tooltip inside chart
 * to show it near the cursor and prevent hide part of it outside of chart
 */
export default class CustomTooltipPositioner {

	constructor(
		private readonly padding: number = 2,
		private readonly offsetY: number = -20,
		private readonly alignX: 'left' | 'center' | 'right' = "center",
	) { }

	public place(chart: Highcharts.Chart, tooltip: SVGElement, cursorX: number, cursorY: number): void {
		const box = tooltip.getBBox();
		const
			plotW = chart.plotWidth,
			plotH = chart.plotHeight,
			plotX = chart.plotLeft,
			w = box.width,
			h = box.height;

		// Align tooltip to cursor horizontally and keep tooltip in chart
		let x;
		switch (this.alignX) {
			case "left":
				x = cursorX;
				break
			case "right":
				x = cursorX - w;
				break;
			default:
				x = cursorX - w / 2;
				break;
		}
		x = Math.min(Math.max(x, this.padding + plotX), plotW - this.padding - w + plotX);


		// Align tooltip above cursor vertically, if not fit, put tooltip under cursor
		let y = cursorY + this.offsetY + (this.offsetY < 0 ? -h : 0);
		if (cursorY < this.padding + h) {
			y = cursorY + Math.abs(this.offsetY);
		}
		y = Math.min(Math.max(y, this.padding), plotH - this.padding - h);

		// Set tooltip position
		tooltip.translate(x, y);
	}

	/**
	 * Normalize non-standard properties layerX and layerY of MouseEvent
	 * @param e
	 */
	public static layerXY(e: MouseEvent): [number, number] {
		const el = e.currentTarget;
		const referenceElement = <HTMLElement>$(el).closest('.highcharts-root')[0];

		let bbox_rect = referenceElement.getBoundingClientRect()
		let layerX = e.clientX - bbox_rect.left
		let layerY = e.clientY - bbox_rect.top

		return [layerX, layerY];
	}
}