import m, {Vnode} from "mithril";
import FilterItemCheckbox from "../FilterItemCheckbox";
import {TFilterValueCheckbox} from "../../../model/filterTypes";
import FilterItemLabel from "../FilterItemLabel";
import StringMatcher from "../../../utilities/StringMatcher";
import OptionCount from "./OptionCount";

export default class SidebarItemCheckbox {

    private readonly subtreeItems: SidebarItemCheckbox[] = [];
    private readonly label: FilterItemLabel;
    private readonly optionCount: OptionCount;

    private isOpen: boolean = false;

    private isSearching = false;
    private searchMatch = false; // match self or subtree match
    private searchMatchSelf = false; // match self or subtree match
    private readonly searchMatcher: StringMatcher;

    private static readonly ICONS = {
        checked: "checked-filled",
        unchecked: "checked-empty",
        semichecked: "checked-semi",
        unknown: "check-all",
    }

    constructor(
        private readonly filterItem: FilterItemCheckbox,
        public readonly parent: SidebarItemCheckbox|null,
        public readonly definition: TFilterValueCheckbox,
    ) {
        if (definition.values) {
            this.subtreeItems = definition.values.map(v => new SidebarItemCheckbox(filterItem, this, v));
        }
        this.label = new FilterItemLabel(this.definition.label, this.definition.style);
        this.optionCount = new OptionCount(this.filterItem, this.definition);
        this.searchMatcher = new StringMatcher(this.definition.label);
    }

    onFilterLoadState(): boolean {
        const subOpen = this.subtreeItems.map(i => i.onFilterLoadState()).filter(v => v).length > 0 ;
        this.isOpen = subOpen || (
            this.filterItem.getState(this.definition) &&
            ( this.definition.values?.some(v => !this.filterItem.getState(v)) && this.definition.values?.some(v => this.filterItem.getState(v)) )
        );
        return this.isOpen;
    }

    onSearch(query: string): boolean {
        this.isSearching = Boolean(query);
        this.searchMatchSelf = this.searchMatcher.match(query);
        if (this.searchMatchSelf) {
            this.subtreeItems.forEach(si => si.onSearch(''));
            this.searchMatch = true;
        } else {
            this.searchMatch = this.subtreeItems.filter(si => si.onSearch(query)).length > 0;
        }

        this.isOpen = this.isSearching && this.searchMatch;

        return this.searchMatch;
    }

    open(): void {
        this.isOpen = true;
        if (this.parent) {
            this.parent.open()
        } else {
            this.filterItem.open();
        }
    }

    view(): Vnode|null {
        if (this.isSearching && !this.searchMatch) {
            return null;
        }
        if (this.definition.hidden) {
            return null;
        }
        const cssClass = '.FilterItem.FilterItem-Checkbox' + (this.showSubTree() ? '.opened' : '.closed');
        const checkboxState = this.filterItem.getCheckboxCheckedState(this.definition);
        return m(cssClass, {}, [
            m(`.item[data-id="${this.definition.id}"]`, {
                tabindex: "1", // make it focusable
                onclick: (e: MouseEvent) => {
                    e.stopPropagation();
                    this.filterItem.selector.setLastInteractedCheckbox(this.definition);
                    this.isOpen = !this.isOpen;
                },
            }, [
                m('i.icon.ico-'+SidebarItemCheckbox.ICONS[checkboxState], {
                    onclick: (e: MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();

                        this.toggleState(e.shiftKey);
                    },
                }),
                m('div.item-label', {}, [
                    m(this.label),
                    m(this.optionCount),
                ]),
                m('.control', {}, [
                    this.definition.title ? m('i.icon.ico-inform-outline', {
                        title: this.definition.title || '',
                        oncreate: vnode => $(vnode.dom).tooltip({
                            html: true,
                            trigger:"hover",
                            template: '<div class="tooltip tooltip-sidebar" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
                        }),
                    }) : null,
                    this.subtreeVisibleItemsLength() ? m('i.icon.ico-dropdown',{
                        onclick: (e) => {
                            e.stopPropagation();
                            this.isOpen = !this.isOpen
                        },
                    }) : null,
                ]),
            ]),
            m('.subtree', {}, this.showSubTree() ? this.subtreeItems.map(si => m(si)) : null),
        ]);
    }

    private showSubTree(): boolean {
        return this.isOpen && this.subtreeVisibleItemsLength() > 0;
    }

    private subtreeVisibleItemsLength(): number {
        return this.subtreeItems.filter(si => !si.definition.hidden).length;
    }

    private toggleState(extend: boolean): void {
        if (extend) {
            if (this.filterItem.isAllChecked()) { // check first checkbox
                this.filterItem.setState(false, this.definition);
            } else {
                this.filterItem.extendSelection(this.definition);
            }
        } else {
            if (this.filterItem.isAllChecked()) { // check first checkbox
                this.filterItem.getFlattenValues().forEach(v => this.filterItem.filter.list().setOptionState(v.id, false));
                this.filterItem.setState(true, this.definition);
            } else {
                this.filterItem.setState(this.filterItem.getCheckboxCheckedState(this.definition) !== "checked", this.definition);
            }
        }
        this.filterItem.filter.triggerChange();
    }
}
