import FilterItemPeriod from "../../components/filterItems/FilterItemPeriod";
import {TFilterValuePeriod} from "../filterTypes";
import FilterList from "../FilterList";

/**
 * What periods would be selected on interact with given period (and on given circumstances)
 * Period selector does not change filter state
 */
export default class PeriodSelector {

    constructor(
        private readonly filterItem: FilterItemPeriod,
    ) { }

    public select(chosenPeriod: TFilterValuePeriod, includeSemiChecks = false): TFilterValuePeriod[] {
        const selected = [];
        this.getPrecedentSiblings(chosenPeriod, this.getMultiPeriodRange(chosenPeriod)).forEach(period => {
            selected.push(...this.getFlattenValuePeriods(period));
        });

        return includeSemiChecks ? this.addParentsWithSomeLeafsChecked(selected) : this.addParentsWithAllLeafsChecked(selected);
    }

    /**
     * Get shortened period for reply to backend with just one period instead of list of periods
     * Solves edge case, when there is one child under parent (like this example) and backend can not recognise what should be previous period.
     *    2022
     *    \- Q1
     *    \- Q2     <-- this parent
     *       \- P4  <-- this child
     */
    public getShortenedPeriod(chosenPeriod?: TFilterValuePeriod): string|null {
        return chosenPeriod && this.getMultiPeriodRange(chosenPeriod) === 1 ? chosenPeriod.id : null;
    }

    /**
     * Extend selection to clicked value, follow gmail mail selection UI
     */
    public selectExtend(chosenPeriod: TFilterValuePeriod, lastInteractedPeriod?: TFilterValuePeriod, includeSemiChecks= false): TFilterValuePeriod[] {

        lastInteractedPeriod ??= chosenPeriod;

        const value = !this.filterItem.getState(chosenPeriod);

        // work with leafs
        let leafs = this.getCheckedLeafs();
        if (value) {
            leafs = this.union(leafs, this.getLeafs(chosenPeriod));
            leafs = this.getLeafsInterval(leafs[0], leafs[leafs.length-1]);
        } else {
            if (this.filterList.periods.indexOf(chosenPeriod) > this.filterList.periods.indexOf(lastInteractedPeriod)) { // from top to down
                leafs = this.subtract(leafs, this.getLeafsInterval(leafs[0], chosenPeriod));
            } else { // from bottom to up
                leafs = this.subtract(leafs, this.getLeafsInterval(chosenPeriod, leafs[leafs.length-1]));
            }
        }

        // set parents by children
        const periods = includeSemiChecks ? this.addParentsWithSomeLeafsChecked(leafs) : this.addParentsWithAllLeafsChecked(leafs);

        // set at least one period checked
        if (!periods.length) {
            return this.getFlattenValuePeriods(chosenPeriod);
        }

        return periods;
    }

    private get filterList(): FilterList {
        return this.filterItem.filter.list();
    }

    private getPeriodLevel(period: TFilterValuePeriod): number {
        let level = 0;
        let parent = period;
        while(parent = this.filterList.periodParents.get(parent)) {
            level++
        }
        return level;
    }

    private getMultiPeriodRange(period: TFilterValuePeriod): number {
        const multiPeriodAutoSelect = this.filterItem.definition.filterRender?.main?.multiPeriodAutoSelect;
        if (!multiPeriodAutoSelect?.enabled) {
            return 1;
        }
        return this.filterItem.definition.filterRender.main.multiPeriodAutoSelect
            .typeSelectionSizes[this.getPeriodLevel(period)]?.size ?? 1;
    }

    private getPrecedentSiblings(period: TFilterValuePeriod, limit: number = 1): TFilterValuePeriod[] {
        const siblings = [period];
        if (limit === 1) {
            return siblings;
        }
        const level = this.getPeriodLevel(period);
        const periods = this.filterList.periods;

        let start = periods.indexOf(period);
        for ( let i=start-1; i>=0; i-- ) {
            if (level === this.getPeriodLevel(periods[i])) {
                siblings.unshift(periods[i]);
            }
            if (siblings.length === limit) {
                break;
            }
        }

        return siblings;
    }

    private getLeafs(period?: TFilterValuePeriod): TFilterValuePeriod[] {
        const leafs = [];
        if (!period) {
            this.filterItem.definition.values?.forEach(p => leafs.push(...this.getLeafs(p)));
        } else {
            if (period.values?.length) {
                period.values.forEach(p => leafs.push(...this.getLeafs(p)));
            } else {
                leafs.push(period);
            }
        }
        return leafs;
    }

    private getCheckedLeafs(): TFilterValuePeriod[] {
        return this.getLeafs().filter(p => this.filterList.getPeriodState(p.id));
    }

    private getLeafsInterval(begin: TFilterValuePeriod, end:TFilterValuePeriod) {
        const periods = this.filterList.periods;
        const beginIndex = periods.indexOf(begin);
        const endIndex = periods.indexOf(end);

        let start = false, finish = false;
        const beginLeaf = this.getLeafs(beginIndex <= endIndex ? begin : end).shift();
        const endLeaf = this.getLeafs(beginIndex <= endIndex ? end : begin).pop();

        return this.getLeafs().filter(l => {
            start = start || beginLeaf === l;
            const res = start && !finish;
            finish = start && finish || endLeaf === l;
            return res;
        });
    }

    private union(a: TFilterValuePeriod[], b:TFilterValuePeriod[]): TFilterValuePeriod[] {
        return this.filterList.periods.filter(p => a.includes(p) || b.includes(p));
    }

    private subtract(a: TFilterValuePeriod[], b:TFilterValuePeriod[]): TFilterValuePeriod[] {
        return a.filter(p => !b.includes(p));
    }

    /**
     * Get all periods flatten, order same as side menu on web
     */
    private getFlattenValuePeriods(period?: TFilterValuePeriod): TFilterValuePeriod[] {
        const subPeriods = [];
        if (!period) {
            this.filterItem.definition.values?.forEach(p => subPeriods.push(...this.getFlattenValuePeriods(p)));
        } else {
            subPeriods.push(period);
            period.values?.forEach(p => subPeriods.push(...this.getFlattenValuePeriods(p)));
        }
        return subPeriods;
    }

    /**
     * Add parents, which have all leafs in leafs parameter
     */
    private addParentsWithAllLeafsChecked(leafs: TFilterValuePeriod[]): TFilterValuePeriod[] {
        const completed = [];
        return this.filterList.periods.filter(p => {
            return leafs.includes(p) || completed.includes(p) || this.getLeafs(p).every(l => leafs.includes(l));
        });
    }

    /**
     * Add parents, which some leafs checked
     */
    private addParentsWithSomeLeafsChecked(leafs: TFilterValuePeriod[]): TFilterValuePeriod[] {
        const completed = [];
        return this.filterList.periods.filter(p => {
            return leafs.includes(p) || completed.includes(p) || this.getLeafs(p).some(l => leafs.includes(l));
        });
    }

}
