import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";

export default class RangeChartHideDatasetOnLegendLick implements IOptions {

    options(data: any): Partial<Options> {

        return {
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: function (e) {
                            [1,2,3].map(i => this.chart.series[this.index+i])
                                .forEach(serie => serie.setVisible(!serie.visible));
                        }
                    }
                }
            }
        }
    }

}