import IPrintChange from "./IPrintChange";

export default class ToggleDataLabels implements IPrintChange {
    prepare(): void {
        $('input.toggle-datalabels').each((i, input) => {
            const $input = $(input);
            if (!$input.prop('checked')) {
                $input.prop('checked', true);
                $input.trigger('change');
            }
        });
    }

    revert(): void {
        $('input.toggle-datalabels').each((i, input) => {
            const $input = $(input);
            $input.prop('checked', false);
            $input.trigger('change');
        });
    }

}
