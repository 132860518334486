import TDataPoint from "../../../shared/highcharts/TDataPoint";
import ValueFormatter from "../../../../model/ValueFormatter";
import {PointOptionsObject} from "highcharts";


function isTotalBetweenMinMax(low?: number, total?: number, high?: number): boolean {
    // In case of filtered data by LowBase, can be total out of min max range
    return typeof low === "number" && typeof high === "number" && typeof total === "number" && total >= low && total <= high;
}

export function transformRangeDataBoxplotPoint(
    points: {
        min: TDataPoint,
        total: TDataPoint,
        max: TDataPoint,
    },
    backendDataIndex: number,
    formatter: ValueFormatter,
): PointOptionsObject {
    let low = points.min?.value;
    let total = points.total?.value;
    let high = points.max?.value;

    if (!isTotalBetweenMinMax(low, total, high)) {
        low = points.total.value;
        high = points.total.value;
    }

    return {
        name: points.total.label || '',
        x: backendDataIndex,

        low: low,
        q1: points.total.value,
        median: points.total.value,
        q3: points.total.value,
        high: high,

        custom: {
            index: backendDataIndex,
            formatter: formatter,
            min: points.min,
            total: points.total,
            max: points.max,
            count: points.total.count,
        }
    }
}

export function transformRangeDataScatterPoint(
    points: {
        min: TDataPoint,
        total: TDataPoint,
        max: TDataPoint,
    },
    backendDataIndex: number,
    formatter: ValueFormatter,
): PointOptionsObject|null[] {

    const createOptionsPoint = (type: string) => {
        const point = points[type]
        return point?.count ? {
            name: point.label || '',
            x: backendDataIndex,
            y: point.value,
            custom: {
                index: backendDataIndex,
                value: point.value,
                count: point.count,
                label: point.label,
                link: point.link,
                formatter: formatter,
                point: point,
                type: type,
            },
        } : null;
    }


    if (isTotalBetweenMinMax(points.min?.value, points.total?.value, points.max?.value) && points.min?.value !== points.max?.value) {
        return ['min', 'total', 'max'].map(type => createOptionsPoint(type));
    }

    return [null, createOptionsPoint('total'), null];

}
