import m, {Vnode, VnodeDOM} from "mithril";
import Filter from "./Filter";
import ISearchable from "./filterItems/ISearcheable";
import normalizeString from "../utilities/normalizeString";
import translator from "../../../translator";

export default class SearchFilter {

    private searchableComponents: Map<ISearchable, null> = new Map();
    private isSearching = false;
    public $element: JQuery;
    private self; // due the $element

    constructor(
        public readonly filter: Filter
    ) {
        this.self = this;
    }

    public register(component: ISearchable): void {
        this.searchableComponents.set(component, null);
    }

    public reset(): void {
        this.self.$element?.find('input').val('');
        this.onSearchInputChange('');
        this.isSearching = false;
    }

    private onSearchInputChange(needle: string) {
        this.searchableComponents.forEach((v, component) => component.onSearch(needle));
    }

    public view(): Vnode {
        return m('.FilterItem.FilterItem-Search', {}, [
            m('i.icon.ico-search'),
            m('input', {
                placeholder: translator.translate('search_filter'),
                'data-ga': 'filter-search',
                oninput: (e: InputEvent) => {
                    const needle = (<HTMLInputElement>e.currentTarget).value;
                    this.onSearchInputChange(normalizeString(needle));
                    this.isSearching = Boolean(needle); // nonempty searching phrase
                }
            }),
            this.isSearching ? m('.reset-filter-search', {
               onclick: () => this.reset(),
            }, [
                m('i.icon.ico-close'),
            ]) : null,
        ]);
    }

    public oncreate(vnode: VnodeDOM): void {
        this.self.$element = $(vnode.dom);
    }
}
