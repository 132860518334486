import IOptions from "./IOptions";
import {Options} from "highcharts";
import _ from "lodash";

export default class OptionsComposer implements IOptions {

    constructor(
        private readonly sources: IOptions[]
    ) { }

    options(data: any): Partial<Options> {

        return _.merge(
            {},
            ...this.sources.map(o => o.options(data))
        );
    }

}
