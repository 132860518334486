import m, {Vnode} from "mithril";
import {TFilterItemTilesMatrix} from "../../model/filterTypes";
import Filter from "../Filter";
import IFilterComponent from "../IFilterComponent";
import ISearchable from "./ISearcheable";
import FilterItemTiles from "./FilterItemTiles";
import SidebarItemTileMatrix from "./tile/SidebarItemTileMatrix";

export default class FilterItemTilesMatrix extends FilterItemTiles implements IFilterComponent, ISearchable {

    constructor(
        definition: TFilterItemTilesMatrix,
        filter: Filter,
        parent?: IFilterComponent
    ) {
        super(definition, filter, parent);

        definition.values?.forEach(valueDefinition => this.subTiles.push(
            new SidebarItemTileMatrix(this, valueDefinition)
        ));
    }

    viewModalPanel(): Vnode | null {
        return null;
    }

    viewModalSidebar(): Vnode | null {
        return null;
    }

    viewSidebar(): Vnode | null {
        return m('.FilterItem.FilterItemTilesMatrix', {}, [
            m('.item', {}, [
                m('.control', {}, [
                    m('.switch', {
                        // TODO tooltip s enkama a možná labelem....
                    }, this.subTiles.map(st => m(st)))
                ])
            ])
        ]);
    }



}
