import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import {createValueFormatterFromDataset} from "../../../shared/highcharts/formatters";
import TDataBarChart from "../types/TDataBarChart";
import BarChartConstants from "../BarChartConstants";

export default class YAxisBarOptions implements IOptions {

    options(data: TDataBarChart): Partial<Options> {
        const tickFormatter = createValueFormatterFromDataset(data.datasets[0]);

        return  {
            yAxis: [{
                title: {
                    text: ""
                },
                endOnTick: true,
                startOnTick: true,
                maxPadding: BarChartConstants.COLUMN_Y_AXIS_MAX_PADDING,
                minPadding: 0,
                plotBands: [],
                plotLines: [],
                labels: {
                    formatter: function () {
                        return tickFormatter.format(this.value, null);
                    },
                    style: {
                        textOverflow: 'none',
                        fontSize: "12px"
                    }
                }
            }],
        }
    }

}
