import PeriodSelector from "./PeriodSelector";
import {TFilterValuePeriod} from "../filterTypes";

/**
 * Determine if period would be checked, semi-checked or not checked before confirmation
 */
export default class PeriodPreSelector {

    /**
     * Cache result to optimise preselect computation for large filters
     * @private
     */
    private cache: {last?: TFilterValuePeriod, preselected?: TFilterValuePeriod, extend: boolean, checked: TFilterValuePeriod[], semiChecked: TFilterValuePeriod[]} = {
        last: undefined,
        preselected: undefined,
        extend: false,
        checked: [],
        semiChecked: [],
    };
    
    constructor(
        private readonly selector: PeriodSelector
    ) { }

    public isPreselected(
        current: TFilterValuePeriod,
        extend: boolean,
        preselected?: TFilterValuePeriod,
        last?: TFilterValuePeriod,
    ): false|'semi-checked'|'checked' {
        if (!preselected) {
            return false;
        }
        if (this.cache.extend !== extend || last !== this.cache.last || this.cache.preselected !== preselected) {
            this.cache = {
                preselected: preselected,
                last: last,
                extend: extend,
                checked: extend ? this.selector.selectExtend(preselected, last, false) : this.selector.select(preselected, false),
                semiChecked: extend ? this.selector.selectExtend(preselected, last, true) : this.selector.select(preselected, true),
            }
        }

        if (this.cache.checked.includes(current)) {
            return 'checked';
        }
        if (this.cache.semiChecked.includes(current)) {
            return 'semi-checked';
        }
        return false;
    }
    
}
