import {round} from '../../../js/utility/roundTo';

type Value = string|number|null|undefined;

export default class ValueFormatter {
	public static readonly DECIMAL_SEPARATOR = (0.1).toLocaleString().replace(/\d/g, '');

	public static defaultFormatter = new ValueFormatter();

	public static format(
		value: Value,
		precision?: null | number,
		unitMark?: string,
		emptyValue?: string,
		decimalSeparator?: string
	): string {
		return this.defaultFormatter.format(value, precision, unitMark, emptyValue, decimalSeparator);
	}

	constructor(
		private precision: number = 0,
		private unitMark: string = '',
		private emptyValue: string = '-',
		private readonly decimalSeparator?: string
	) {
		// pick by browser locale decimal separator
		this.decimalSeparator ??= ValueFormatter.DECIMAL_SEPARATOR;
	}

	/**
	 * Format given value by rules of value formatter
	 * You can override any of rules
	 *
	 * @param value             Given value
	 * @param precision         Round (number | null => no round | undefined => default precision)
	 * @param unitMark
	 * @param emptyValue
	 * @param decimalSeparator
	 */
	public format(value: Value, precision?: null | number, unitMark?: string, emptyValue?: string, decimalSeparator?: string): string {
		precision = precision !== undefined ? precision : this.precision;
		unitMark ??= this.unitMark;
		emptyValue ??= this.emptyValue;
		decimalSeparator ??= this.decimalSeparator;

		if (value === null || value === undefined) {
			return emptyValue;
		}
		if (typeof value === 'string') {
			value = parseFloat(value);
        }
        return String(typeof precision === "number" ? round(value, precision) : value)
            .replace('.', decimalSeparator) + unitMark;
    }

    getUnit(): string {
        return this.unitMark || '';
    }

    getRoundedValue(value: Value, precision?: null|number): number|null {
        if (value === null || value === undefined) {
            return null;
        }
        if (typeof value === 'string') {
            value = parseFloat(value);
        }
        precision = precision !== undefined ? precision : this.precision;
        return typeof precision === 'number' ? round(value, precision) : value;
    }
}