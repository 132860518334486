import ClassicExtension from "../../ClassicExtension";

export default class ChangeValueTargetExtension extends ClassicExtension {

    public attach($root: JQuery) {
        $root.find('[data-change-value-target]').on('click', (e) => {
            e.preventDefault();
            const $clickedElement = $(e.currentTarget);
            const targetSelector = $clickedElement.data('change-value-target');
            const targetValue = $clickedElement.data('value') || '';
            if (!targetSelector) {
                return;
            }

            const $target = <JQuery<HTMLInputElement>>$(targetSelector);
            if ($target.is(':disabled')) {
                console.log('Target is disabled. Sorry.');
                return;
            }

            const confirmMessage = $clickedElement.data('confirm');
            if (confirmMessage && !confirm(confirmMessage)) {
                return;
            }

            // Trigger change
            $target.val(targetValue);
            $target.trigger('change');

            // and submit
            $target.parent()
                .find('input[type="submit"]')
                .trigger('click');

        }).on('mouseenter',  (e) => {
            const $element = $(e.currentTarget);
            if (this.willBeChanged($element)) {
                $element.removeClass('not-allowed');
            } else {
                $element.addClass('not-allowed');
            }
        });
    }

    private willBeChanged($element: JQuery): boolean {
        const targetSelector = $element.data('change-value-target');
        if (!targetSelector) {
            return false;
        }

        const $target = <JQuery<HTMLInputElement>>$(targetSelector);
        return $target.length && !$target.is(':disabled');
    }

}

