export default class OptionFilter
{
    constructor($element, name, options) {
        this._$element = $element;
        this._name = name;
        this._options = options;

        this._$input = this._$element.find('input[type="search"]');
        this._$btnSubmit = this._$element.find('button');

        this._$iconSearch = this._$element.find('i.glyphicon-search');
        this._$iconRemove = this._$element.find('i.glyphicon-remove');

        this._$noOptionFound = this._$element.find('[data-no-option-found]');

        this.init();
    }

    init() {
        const value = this._loadFromStorage();

        this._$input.val(value);

        this._$input.on('focus', () => {
            this._expandMenu();
        });

        this._$input.on('keydown', (e) => {
            if(e.key === "Enter") { // do not send on hit Enter
                e.preventDefault();
            }
        });

        this._$input.on('change keyup', (e) => {
            e.preventDefault();
            this.handleFilter();
            this._setIcon();
        });

        this._$btnSubmit.on('click', (e) => {
           e.preventDefault();
           this._$input.val('');
           this._resetFilter();
           this._setIcon();
        });

        if (value) {
            setTimeout(() => {
                this._expandMenu();
                this.handleFilter();
                this._setIcon();
            }, 1000)
        } else {
            this._resetFilter();
        }
    }

    handleFilter() {
        const needle = String(this._$input.val()).toLowerCase().trim();
        this._saveToStorage(needle);

        if (needle) {
            this._filter(needle);
        } else {
            this._resetFilter();
        }
    }

    _filter(needle) {
        const $options = this._getOptions();
        let resultsCount = 0;

        $.each($options, (index, option) => {
            const $option = $(option);
            const keyword = String($option.data('option-name')).toLowerCase().trim();
            if (keyword.includes(needle)) {
                resultsCount++;
                $option.show();
            } else {
                $option.hide();
            }
        });

        if (resultsCount) {
            this._$noOptionFound.hide();
        } else {
            this._$noOptionFound.show();
        }

    }

    _resetFilter() {
        const $options = this._getOptions();
        $.each($options, (index, option) => {
            const $option = $(option);
            $option.show();
        });
        this._$noOptionFound.hide();
        this._setIcon();
        this._saveToStorage('');
    }

    _getOptions() {
        return this._$element.closest('.site-menu').find('[data-options-list] [data-option-name]');
    }

    _setIcon() {
        if (this._$input.val()) {
            this._$iconRemove.show();
            this._$iconSearch.hide();
        } else {
            this._$iconRemove.hide();
            this._$iconSearch.show();
        }
    }

    _expandMenu()
    {
        this._$element
            .closest('.site-menu')
            .find('[data-options-list] .site-menu-item.has-sub, [data-options-list].site-menu-item.has-sub')
            .trigger('open.site.menu');
    }

    _saveToStorage(value) {
        window.localStorage.setItem('search_in_filters', value);
    }
    _loadFromStorage() {
        const value = window.localStorage.getItem('search_in_filters');
        return value === null ? '' : value;
    }
}