import {colorCrossFade} from "./colorCrossFade";
import colorToString, {RgbaColor} from "./colorToString";


const rgba = require('color-rgba');

export default class Transition3Rgba {
    private readonly minColor: RgbaColor;
    private readonly middleColor: RgbaColor|null;
    private readonly maxColor: RgbaColor;
    private readonly middle: number;

    constructor(
        minColor: string|RgbaColor,
        middleColor: string|RgbaColor|undefined,
        maxColor: string|RgbaColor,
        private readonly min: number,
        private readonly max: number,
        middle?: number,
    ) {
        this.minColor = typeof minColor === 'string' ? rgba(minColor) : minColor;
        if (middleColor) {
            this.middleColor = typeof middleColor === 'string' ? rgba(middleColor) : middleColor;
        }
        this.maxColor = typeof maxColor === 'string' ? rgba(maxColor) : maxColor;
        this.middle = middle === undefined ? ((min+max)/2) : middle;
    }

    public getColor(value: number): string {
        if (isNaN(value)) {
            return 'transparent';
        }
        if (value === this.middle) {
            return this.middleColor ? colorToString(this.middleColor) : 'transparent';
        }
        if (value <= this.min) {
            return colorToString(this.minColor);
        }
        if (value >= this.max) {
            return colorToString(this.maxColor);
        }

        if (value > this.middle) {
            const k = (value-this.middle) / (this.max-this.middle);
            const c = this.middleColor || [this.maxColor[0], this.maxColor[1], this.maxColor[2], 0];
            return colorToString(colorCrossFade(k, this.maxColor, c));
        } else {
            const k = (this.middle-value) / (this.middle-this.min);
            const c = this.middleColor || [this.minColor[0], this.minColor[1], this.minColor[2], 0];
            return colorToString(colorCrossFade(k, this.minColor, c));
        }
    }

}
