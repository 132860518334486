/**
 * Example: " Začíná   jaro!!!" => "Zacina jaro!!!"
 *
 * Remove diacritics
 * To lowercase
 * Remove extra spaces
 *
 * @param s
 */
export default function normalizeString(s: string): string {
    // IE 11 fix
    if (s.normalize) {
        s = s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
    return s.replace(/ +/, ' ').trim().toLocaleLowerCase();
}