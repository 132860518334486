import INetteExtension from "./INetteExtension";

export default class PreventDoubleSubmitExtension implements INetteExtension {

    before(jqXHR: XMLHttpRequest, settings: any): void | false {
        if (settings?.nette?.el) {
            const $el = $(settings.nette.el);
            if ($el.is('input[type="submit"]')) {
                $el.attr('disabled', 1);
            }
        }
    }

    complete(jqXHR: XMLHttpRequest, status, error, settings: any) {
        if (settings?.nette?.el) {
            const $el = $(settings.nette.el);
            if ($el.is('input[type="submit"]')) {
                $el.prop('disabled');
            }
        }
    }

}
