import {Chart, Point} from "highcharts";
import translator from "../../../../translator";
import ExtendedPointOptionsObject from "../../../shared/highcharts/ExtendedPointOptionsObject";


function createHeaderRow(chart: Chart, format: boolean): string[] {
    const row = [''];
    const m = {
        'min': translator.translate('min'),
        'max': translator.translate('max'),
        'name': translator.translate('name'),
    };

    chart.series
        .filter(s => s.type === 'boxplot')
        .forEach(s => {
            const point = <Point & ExtendedPointOptionsObject>s.data.filter(p => p)[0];
            const unit = point?.custom?.formatter?.getUnit().trim() ?? '';
            const formattedUnit = (unit && !format) ? ' ['+unit+']' : '';

            row.push(
                'n',
                s.name + formattedUnit,
                m.min +' '+ m.name,
                m.min +' n',
                m.min + formattedUnit,
                m.max +' '+ m.name,
                m.max +' n',
                m.max + formattedUnit
            );
        });

    return row;
}

function getCategories(chart: Chart): (string|number)[] {
    return chart.series?.[0].data.map((s,i) => s.name || chart.xAxis?.[0]?.categories[i] || (i+1)) || [];
}

/**
 * Export chart data in 2 dimensional table-like array
 *
 * @param chart          Highcharts object
 * @param formatNumbers  Should format numbers like in chart
 * @param lowBaseArray Array to be filled with booleans which values are marked as low base
 */
export default function exportRangeChartToArray(
    chart: Chart,
    formatNumbers: boolean,
    lowBaseArray?: (boolean|null)[][]
): (string|number)[][] {

    lowBaseArray ??= [];
    const dataArray: (string|number)[][] = [createHeaderRow(chart, formatNumbers)];
    const categories = getCategories(chart);

    categories.forEach((category, y) => {

        const dataRow = [category];
        const lowBaseRow = [null];
        const trinities = [];

        chart.series
            .filter(s => s.type === 'scatter')
            .forEach((serie,x) => {
                trinities[x-x%3] ??= {};
                // @ts-ignore
                const point: Point & ExtendedPointOptionsObject = serie.options?.data[y];
                if (point) {
                    trinities[x-x%3][point.custom.type] = point.custom;
                }
        });

        trinities.forEach(trinity => {
            const formatter = trinity.total?.formatter;
            dataRow.push(
                trinity.total?.count ?? '',
                formatNumbers && formatter ? formatter.format(trinity.total?.value) : trinity.total?.value ?? '',
                trinity.min?.label ?? '',
                trinity.min?.count ?? '',
                formatNumbers && formatter ? formatter.format(trinity.min?.value) : trinity.min?.value ?? '',
                trinity.max?.label ?? '',
                trinity.max?.count ?? '',
                formatNumbers && formatter ? formatter.format(trinity.max?.value) : trinity.max?.value ?? '',
            );
            lowBaseRow.push(
                trinity.total?.isLowBase,
                trinity.total?.isLowBase,
                trinity.min?.isLowBase,
                trinity.min?.isLowBase,
                trinity.min?.isLowBase,
                trinity.max?.isLowBase,
                trinity.max?.isLowBase,
                trinity.max?.isLowBase,
            );
        });

        dataArray.push(dataRow);
        lowBaseArray.push(lowBaseRow);
    });

    return dataArray;

}
