import fullscreenConditionAndChartOptions from "../../shared/highcharts/responsive/fullscreenConditionAndChartOptions";
import ChartConstants from "../../shared/highcharts/ChartConstants";

export default function scatterChartDefinition() {
    return {
        "chart": {
            "type": "scatter",
            "zoomType": "xy",
            "style": {
                "fontFamily": ChartConstants.FONT_FAMILY,
            },
            "styledMode": false,
        },
        "title": [],
        "subtitle": [],
        "xAxis": {
            "gridLineColor": undefined,
            "title": {
                "enabled": true,
                "text": "X"
            },
            "startOnTick": true,
            "endOnTick": true,
            "showLastLabel": true,
            "style": {
                "fontSize": "12px"
            }
        },
        "yAxis": {
            "gridLineColor": undefined,
            "lineWidth": 0,
            "title": {
                "enabled": true,
                "text": "Y"
            },
            "style": {
                "fontSize": "12px"
            }
        },
        "tooltip": {
            "shadow": false,
        },
        "legend": {
            "layout": "horizontal",
            "align": "center",
            "verticalAlign": "bottom",
            "useHTML": true,
            "floating": false
        },
        "plotOptions": {
            "scatter": {
                "marker": {
                    "symbol": "circle",
                    "radius": 8,
                    "states": {
                        "hover": {
                            "enabled": true,
                            "lineColor": "rgb(100,100,100)"
                        }
                    }
                },
                "states": {
                    "hover": {
                        "marker": {
                            "enabled": false
                        }
                    }
                },
                "tooltip": {
                    "headerFormat": "<b>{series.name}</b><br>",
                    "pointFormat": "{point.x:.2f}, {point.y:.2f}",
                }
            },
            "series": {
                "dataLabels": {
                    "allowOverlap": true,
                    "align": "center",
                    "pointFormat": "{point.x:.2f}, {point.y:.2f}",
                    "style": {
                        "fontWeight": "normal",
                        "fontSize": "11px",
                        "color": "black",
                    }
                }
            }
        },
        "exporting": {
            "enabled": false,
        },
        "responsive": {
            "rules": [],
        },
        "credits": false,
    };
}
