import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import TDataBarChart from "../types/TDataBarChart";

export default class ResponsiveBarOptions implements IOptions {
    options(data: TDataBarChart): Partial<Options> {

        return {
            responsive: {
                rules: [],
            },
        }

    }

}
