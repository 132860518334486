import ExtendedPointOptionsObject from "../ExtendedPointOptionsObject";
import {TLowBase} from "../../../../Board/src/model/TBoardOptions";
import IModifier from "./IModifier";
import Board from "../../../../Board";
import TDataPieChart from "../../../PieChart/js/types/TDataPieChart";
import {Options, PointOptionsObject, SeriesOptions} from "highcharts";

type TPointCallback = (point: ExtendedPointOptionsObject) => void;
type TSerieOptionsWithData = SeriesOptions&{data:PointOptionsObject[]};

/**
 * Search points by given LowBase and apply callback on these points
 */
export default class LowBaseModifier implements IModifier {

    static loadLowBase(data: TDataPieChart|any, defaults: Partial<TLowBase> = {}): TLowBase {
        const mode = data.chart?.custom?.lowBase?.mode ?? Board.instance?.options.settings.lowBase?.mode ?? defaults.mode ?? 'disabled';
        const threshold = data.chart?.custom?.lowBase?.threshold ?? Board.instance?.options.settings.lowBase?.threshold ?? defaults.threshold ?? 0;
        const base = data.chart?.custom?.lowBase?.base ?? Board.instance?.options.settings.lowBase?.base ?? defaults.base ?? 'point';
        return {mode, threshold, base};
    }

    static invisibleCallback: TPointCallback = point => {
        point.visible = false;
    }

    static hideCallback: TPointCallback = point => {
        point.visible = false;
        point.y = null;
    }

    static createCountString(n: number, isLowBase: boolean): string {
        return `<span ${isLowBase?'style="color:red"':''}>n=${n}</span>`;
    }


    constructor(
        private readonly lowBase: TLowBase,
        private readonly options: Partial<{
            pointCallback: TPointCallback,
            removeCategories: boolean,
            removeDatasets: boolean,
        }> = {}
    ) { }

    public modify(chart: Options) {
        const series = <TSerieOptionsWithData[]><unknown>chart.series;
        this.setPointLowBase(series);
        this.setPointAttributesByLowBase(series);

        if (this.options.removeCategories) {
            this.removeEmptyCategories(chart, series);
        }

        if (this.options.removeDatasets) {
            this.removeEmptyDatasets(chart, series);
        }
    }

    private setPointLowBase(series: TSerieOptionsWithData[]): void {
        if (this.lowBase.mode === 'disabled' || this.lowBase.threshold < 1) {
            return;
        }

        const getCount = (pointIndex: number, serieIndex: number): number => {

            if (this.lowBase.base === 'dataset') {
                return series[serieIndex].data.map(point => point.custom?.count)
                    .filter(count => count)
                    .reduce((p,c) => p+c, 0);
            } else if (this.lowBase.base === 'category') {
                return series.map(serie => serie.data[pointIndex]?.custom.count)
                    .filter(count => count)
                    .reduce((p,c) => p+c, 0);
            } else if (this.lowBase.base === 'group' && series[serieIndex]?.stack) {
                const stack = series[serieIndex].stack;
                return series.filter(serie => serie.stack === stack)
                    .map(serie => serie.data[pointIndex]?.custom.count)
                    .filter(count => count)
                    .reduce((p,c) => p+c, 0);
            }

            return series[serieIndex]?.data[pointIndex]?.custom.count ?? 0;
        };

        series?.forEach((serie, serieIndex) => {
            serie.data?.forEach((point: ExtendedPointOptionsObject, pointIndex) => {
                if (!point || !point.custom) {
                    return;
                }
                point.custom.isLowBase = getCount(pointIndex, serieIndex) < this.lowBase.threshold;
            });
        });
    }

    private setPointAttributesByLowBase(series: TSerieOptionsWithData[]): void {
        series?.forEach(serie => {
            serie.data?.forEach((point: ExtendedPointOptionsObject) => {
                if (point?.custom?.isLowBase === true) {
                    this.options.pointCallback?.(point);
                }
            });
        });
    }

    private removeEmptyCategories(chart: Options, series: TSerieOptionsWithData[]) {
        if (['filter','hide'].includes(this.lowBase.mode)) {
            if (chart.xAxis?.['categories']) {
                chart.xAxis['categories'].forEach( (category,categoryIndex) => {
                    const removeIt = series.every(serie => serie.data[categoryIndex] === null || serie.data[categoryIndex]?.custom.isLowBase);
                    if (removeIt) {
                        chart.xAxis['categories'][categoryIndex] = undefined;
                        series.forEach(serie => serie.data[categoryIndex] = undefined);
                    }
                });

                chart.xAxis['categories'] = chart.xAxis['categories'].filter(c => c !== undefined);
            } else if (chart.xAxis?.['type'] === 'category') {
                for (let categoryIndex=0; categoryIndex < series[0]?.data?.length; categoryIndex++) {
                    const removeIt = series.every(serie => serie.data[categoryIndex] === null || serie.data[categoryIndex]?.custom.isLowBase)
                    if (removeIt) {
                        series.forEach(serie => serie.data[categoryIndex] = undefined);
                    }
                }
            }
            series.forEach(serie => serie.data = serie.data?.filter(p => p !== undefined));
        }
    }

    private removeEmptyDatasets(chart: Options, series: TSerieOptionsWithData[]) {
        if (['filter','hide'].includes(this.lowBase.mode)) {
            series.forEach((serie, i) => {
                 if (!serie.data?.length || serie.data.every(p => !p || p?.custom?.isLowBase)) {
                     chart.series[i] = undefined;
                 }
            });
        }

        // @ts-ignore
        chart.series = chart.series.filter(serie => serie);
    }

}
