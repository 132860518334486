// Application components
import Board from "./Board";
import CopyAnchor from "./appComponents/CopyAnchor";
import CopyToClipboard from "./appComponents/CopyToClipboard";
import DocumentUploader from './appComponents/DocumentUploader'
import ExportComponentToImage from './appComponents/ExportComponentToImage';
import ExportDashboardToImage from './appComponents/ExportDashboardToImage';
import FoldingMenu from "./appComponents/FoldingMenu";
import OpenCloseToggle from "./appComponents/OpenCloseToggle";
import OptionFilter from './appComponents/OptionFilter';
import PrintButton from "./appComponents/PrintButton";
import PrintiloFillLink from "./appComponents/PrintiloFillLink";
import ResetFilters from './appComponents/ResetFilters';
import ScreenshotEmailPdfAttachment from "./appComponents/ScreenshotEmailPdfAttachment";
import SwitchProject from "./appComponents/SwitchProject";
import UploadedFileSetting from "./appComponents/UploadedFileSetting";
import UserSco from './appComponents/UserSco';
import UserScoCell from './appComponents/UserScoCell';
import TranslatableElement from "./appComponents/TranslatableElement";
import PasswordCheckerList from "./appComponents/PasswordCheckerList";
import Jezisek from "./appComponents/Jezisek";

import replaceContentByLoader from "../../js/utility/replaceContentWithLoader";

function initComponent(name, Constructor, root = document.body) {
	if ($.components === undefined) {
		$.components = {};
	}

	$(root).find('[data-component-' + name + ']').each(function() {
		let $element = $(this);
		const instanceName = $element.data('component-' + name);
		let options = $element.data('options');

		$.components[instanceName] = new Constructor($element, instanceName, options);
	});
}

function initComponents(root = document.body) {
    initComponent('board', Board, root);
    initComponent('copy-anchor', CopyAnchor, root)
    initComponent('copy-to-clipboard', CopyToClipboard, root)
	initComponent('document-uploader', DocumentUploader, root);
	initComponent('user-sco', UserSco, root);
	initComponent('user-sco-cell', UserScoCell, root);
	initComponent('option-filter', OptionFilter, root);
	initComponent('export-component-to-image', ExportComponentToImage, root);
	initComponent('export-dashboard-to-image', ExportDashboardToImage, root);
	initComponent('reset-filters', ResetFilters, root);
	initComponent('uploaded-file-setting', UploadedFileSetting, root);
	initComponent('folding-menu', FoldingMenu, root);
	initComponent('open-close-toggle', OpenCloseToggle, root);
	initComponent('translatable-element', TranslatableElement, root);
	initComponent('switch-project', SwitchProject, root);
    initComponent('print-button', PrintButton, root);
    initComponent('printilo-fill-link', PrintiloFillLink, root);
    initComponent('password-checker-list', PasswordCheckerList, root);
    initComponent('screenshot-email-pdf-attachment', ScreenshotEmailPdfAttachment, root);
    initComponent('jezisek', Jezisek, root);
}

$.nette.ext('init-app-components', {
	success: function(payload) {
		for (let snippet in payload.snippets) {
            // Init components after snippet redraw
			initComponents('#' + snippet);
		}
	}
});

$.nette.ext('form-loader', {
   init: function () {
       initFormLoader();
   } ,
});

function initFormLoader() {
    $("form[data-show-loader]").submit(function (e) {
         $(this).netteAjax(e).done(function () {
             initFormLoader();
         });

         let requiredFieldsAreFilled = true;
         $(this).find('input[required]').each((i, el) => {requiredFieldsAreFilled &&= el.value;});
         if (requiredFieldsAreFilled) {
             replaceContentByLoader($(this));
         }
    });
}

$('body').on('click', '.datagrid a[data-open]', function(e) {
    $(this).netteAjax(e);
    $('#' + $(this).data('open')).modal('show');
});

$(function () {
    // INIT
    initComponents();
    $.nette.init();

    $(window).trigger('resize').trigger('scroll');
    if ($('html#phantomjs').length){
        setTimeout(function (){
            $('.toggle-datalabels:not(:checked)').trigger('click');
        }, 1500);
    }
});
