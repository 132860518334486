import {
    MapDataFeature,
    MapDataFeatureArea,
    MapDataFeatureCluster,
    MapDataFeaturePoint,
    MapPosition, TStyle
} from "./MapDataTypes";
import Feature from "ol/Feature";
import {Point, Polygon} from "ol/geom";
import {Circle, Fill, Stroke, Style} from "ol/style";
import {Coordinate} from "ol/coordinate";
import {transform as coordsTransform} from "ol/proj";

export default class MapFeatureFactory {

    public defaultPointStyle: Style;
    public defaultAreaStyle: Style;

    constructor() {
        this.defaultPointStyle = new Style({
            image: new Circle({
                radius: 5,
                fill: new Fill({color: 'red'}),
                stroke: new Stroke({color: 'white', width: 2}),
            }),
            zIndex: 2,
        });

        this.defaultAreaStyle = new Style({
            stroke: new Stroke({color: 'white', width: 1}),
            fill: new Fill({color: 'rgba(25, 100, 170, 0.15)'}),
            zIndex: 1,
        });
    }

    /**
     * Transform data from backend to map feature (point,area,...etc)
     */
    public mapCreateFeature(featureData: MapDataFeature, style?: TStyle): Feature {
        switch (featureData.type) {
            case "area": return this.createArea(featureData, style);
            case "cluster": return this.createCluster(featureData, style);
            case "point": return this.createPoint(featureData, style);
        }
    }

    /**
     * Transform position from backend to internal map coords
     * @param position
     */
    public static createCoords(position: MapPosition): Coordinate {
        return coordsTransform([position.longitude, position.latitude], 'EPSG:4326', 'EPSG:3857');
    }


    private createPoint(pointData: MapDataFeaturePoint, style?: TStyle): Feature {
        const point =  new Feature({
            geometry: new Point(MapFeatureFactory.createCoords(pointData.position)),
            name: pointData.title,
            data: pointData,
            zIndex: 2,
        });

        point.setStyle(style ? new Style({
            image: new Circle({
                radius: style.radius || 5,
                fill: new Fill({
                    color: style.fill?.color || 'red',
                }),
                stroke: new Stroke({
                    color: style.stroke?.color || 'white',
                    width: style.stroke?.width || 2,
                }),
            }),
            zIndex: 2,
        }) : this.defaultPointStyle);

        return point;
    }


    private createArea(areaData: MapDataFeatureArea, style?: TStyle): Feature {
        if (areaData.positions.length < 3) {
            console.warn(`Area '${areaData.title}' need at least 3 points to create a polygon on map.`, areaData);
        }

        const coords = areaData.positions.map(p => MapFeatureFactory.createCoords(p));

        const area = new Feature({
            geometry: new Polygon([coords]),
            name: areaData.title,
            data: areaData,
            zIndex: 1,
        });

        area.setStyle(style ? new Style({
            stroke: new Stroke({
                color: style.stroke?.color || 'white',
                width: style.stroke?.width || 1,
            }),
            fill: new Fill({
                color: style.fill?.color || 'rgba(25, 100, 170, 0.15)',
            }),
            zIndex: 1,
        }) : this.defaultAreaStyle);

        return area;
    }


    private createCluster(clusterData: MapDataFeatureCluster, style?: TStyle): Feature {
        return new Feature({
            geometry: new Point([0,0]),
            data: clusterData,
        })
    }



}


