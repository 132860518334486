import Highcharts, {Options, SVGElement} from "highcharts";
import IModifier from "./IModifier";
import CustomTooltipPositioner from "../CustomTooltipPositioner";
import createTooltip from "../tooltip/createTooltip";

export default class CustomTooltipModifier implements IModifier {

    constructor(
        private readonly html: string,
        private readonly baseElementGetter: ((chart: Highcharts.Chart) => SVGElement),
    ) { }

    modify(chartOptions: Options): void {

        if (!chartOptions.chart) {
            chartOptions.chart = {};
        }

        if (!chartOptions.chart.events) {
            chartOptions.chart.events = {};
        }

        const originalRedrawCallback = chartOptions.chart.events.redraw;
        const self = this;

        chartOptions.chart.events.redraw = function (e) {
            let tooltip: SVGElement;
            let base = self.baseElementGetter(this);
            const positioner = new CustomTooltipPositioner();

            const redrawLabel = e => {
				tooltip?.destroy();
				const [x, y] = CustomTooltipPositioner.layerXY(e);
				tooltip = createTooltip(this, self.html, x, y);
				positioner.place(this, tooltip, x, y);
			};

            base.on('mouseover', e => {
                $(this.container).find('.highcharts-label.highcharts-tooltip').css('opacity', 0);
                redrawLabel(e);
            });

            base.on('mousemove', e => {
                if (tooltip) {
					const [x, y] = CustomTooltipPositioner.layerXY(e);
					positioner.place(this, tooltip, x, y);
				}
            })

            base.on('mouseout', () => {
                $(this.container).find('.highcharts-label.highcharts-tooltip').css('opacity', 1);
                tooltip?.destroy();
                tooltip = undefined;
            });

            originalRedrawCallback?.call(this, e);
        };

    }


}
