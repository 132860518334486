import html2canvas from 'html2canvas';


export default abstract class ExportToImage
{
    protected readonly $target: JQuery;
    protected readonly filename: string;

    private readonly $sidebar: JQuery;
    private lastDownload = 0;

    constructor(
        protected readonly $element: JQuery
    ) {
        const target = this.$element.data('target');
        const parent = this.$element.data('parent');
        const $parent = parent ? this.$element.closest(parent) : this.$element.parent();
        this.$target = target ? $parent.find(target) : $parent;
        this.filename = this.$element.data('filename') || 'graph';
        this.$sidebar = $('.body-wrap > .sidebar');

        this.init();
    }

    protected abstract init(): void;

    protected render(element: HTMLElement): Promise<HTMLCanvasElement> {
        return html2canvas(element, {
            allowTaint: true,
            scale: 2,
            scrollX: 0,
            scrollY: 0,
            onclone: document => document.body.classList.add('html2canvas'),
        })
    }

    protected saveAs(canvas: HTMLCanvasElement, filename) {
        filename = filename+'.png';
        const link = document.createElement('a');

        canvas.getContext("2d").imageSmoothingEnabled = false;

        if (typeof link.download === 'string') {
            link.href = canvas.toDataURL();
            link.download = filename;

            const startDownload = () => {
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            };

            // SAFARI hack for multiple files download at once (just wait until last file is saved)
            let timeout = 0;
            if (Date.now() - this.lastDownload < 4500) {
                timeout = this.lastDownload + 4500 - Date.now();
            }
            this.lastDownload = Date.now() + timeout;

            // @ts-ignore
            if (timeout && window.safari) {
                setTimeout(() => startDownload(), timeout);
            } else {
                startDownload();
            }
        // @ts-ignore
        } else if (window.navigator.msSaveOrOpenBlob) { // IE
            canvas.toBlob((blob) => {
                // @ts-ignore
                window.navigator.msSaveBlob(blob, filename);
            });
        }
    }

    /* Hide sidebar, respect size of dashboard due stupid IE behavior */
    protected IEBeforeRender(): void {
        // Firefox
        $("head").append('<style id="html2canvas_no_transitions">* {transition: none !important;}</style>');

        // IE11
        // @ts-ignore
        if (window.document.documentMode) {
            alert('This function could not work correctly in your outdated browser.');
            const w = this.$sidebar.width();
            this.$sidebar.detach();
            $('body').css('margin-right', w);
        }
    }

    /* Rollback stupid steps from before render part */
    protected IEAfterRender(): void {
        // Firefox
        $('#html2canvas_no_transitions').remove();

        // IE11
        // @ts-ignore
        if (window.document.documentMode) {
            $('.body-wrap').prepend(this.$sidebar);
            $('body').css('margin-right', '');
        }
    }
}