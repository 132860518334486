import {
    TFilterData, TFilterItem, TFilterItemPeriod,
    TFilterState,
    TFilterStructure,
    TFilterValueCheckbox,
    TFilterValuePeriod, TOptionData
} from "./filterTypes";
import FilterFactory from "./FilterFactory";
import IFilterComponent from "../components/IFilterComponent";

export default class FilterList {

    public readonly filters: IFilterComponent[] = [];
    private readonly state: {
        options: Map<string, true>,
        periods: Map<string, true>,
        shortenedPeriod: string|null,
        favourite: string|null,
    }

    private readonly data: {
        options: Map<string, TOptionData>,
    }

    // Helping structures
    public readonly checkboxParents: Map<TFilterValueCheckbox, TFilterValueCheckbox>;
    public readonly periods: TFilterValuePeriod[] = [];
    public readonly periodParents: Map<TFilterValuePeriod, TFilterValuePeriod>;
    public readonly periodsById: {[id: string]: TFilterValuePeriod};
    public readonly optionsById: {[id: string]: TFilterValueCheckbox};

    constructor(
        public readonly structure: TFilterStructure,
        public readonly factory: FilterFactory,
    ) {
        this.state = {
            options: new Map<string, true>(),
            periods: new Map<string, true>(),
            shortenedPeriod: null,
            favourite: null,
        };

        this.data = {
            options: new Map<string, TOptionData>(),
        }

        const periodDefinitions: TFilterItemPeriod[] = [];
        const filterDefinitions = [];

        const recurse = (definitions: TFilterItem[], parent?: IFilterComponent) => {
            definitions.forEach(definition => {
               const filterItem = this.factory.createItem(definition, parent);

               if (!parent) {
                   this.filters.push(filterItem);
               }

               if (definition.type === "group" && definition.filters) {
                    recurse(definition.filters, filterItem);
               } else if (definition.type === "period") {
                   periodDefinitions.push(definition);
               } else {
                   filterDefinitions.push(definition);
               }
            });
        };
        recurse(this.structure.filters, null);

        this.optionsById = this.createOptionsById(filterDefinitions);
        this.checkboxParents = this.createCheckboxParents(filterDefinitions);
        periodDefinitions.forEach(pd => pd.values.forEach( pvd => this.periods.push(...this.getFlattenValuePeriods(pvd)) ));
        this.periodsById = this.createPeriodsById();
        this.periodParents = this.createPeriodsParents();

    }

    private createOptionsById(filterDefinitions: TFilterValueCheckbox[]): {[id: string]: TFilterValueCheckbox} {
        const res = {};

        const recurse = (parent: TFilterValueCheckbox) => {
            res[parent.id] = parent;
            parent.values?.forEach(v => recurse(v));
        };

        filterDefinitions.forEach( fd => fd.values?.forEach(v => recurse(v)) );

        return res;
    }

    private createCheckboxParents(filterDefinitions: TFilterValueCheckbox[]): Map<TFilterValueCheckbox, TFilterValueCheckbox> {
        const map = new Map<TFilterValueCheckbox, TFilterValueCheckbox>();

        const fillParent = (parent: TFilterValueCheckbox) => {
            parent.values?.forEach(v => {
               map.set(v, parent);
               fillParent(v);
            });
        };

        filterDefinitions.forEach( fd => fd.values?.forEach(v => fillParent(v)) );

        return map;
    }

    private getFlattenValuePeriods(period: TFilterValuePeriod): TFilterValuePeriod[] {
        const subPeriods = [period];
        period.values?.forEach(p => subPeriods.push(...this.getFlattenValuePeriods(p)));
        return subPeriods;
    }

    private createPeriodsById(): {[id: string]: TFilterValuePeriod} {
        const periodsById: {[id: string]: TFilterValuePeriod} = {};
        this.periods.forEach( periodDefinition => periodsById[periodDefinition.id] = periodDefinition );
        return periodsById;
    }

    private createPeriodsParents(): Map<TFilterValuePeriod, TFilterValuePeriod> {
        const parents = new Map<TFilterValuePeriod, TFilterValuePeriod>();
        this.periods.forEach(parent => parent.values?.forEach(child => parents.set(child, parent)));
        return parents;
    }

    public getState(): TFilterState {
        return {
            favourite: this.state.favourite,
            options: Array.from(this.state.options.keys()),
            periods: Array.from(this.state.periods.keys()),
            shortenedPeriod: this.state.shortenedPeriod,
        }
    }
    public setState(state: TFilterState): void {
        this.state.options.clear();
        for (const option of state.options) {
            this.set(String(option), true, this.state.options);
        }

        this.state.periods.clear();
        for (const period of state.periods) {
            this.set(String(period), true, this.state.periods);
        }

        this.state.favourite = state.favourite || null;

        this.state.shortenedPeriod = state.shortenedPeriod || null;

        this.filters.forEach(i => i.onFilterLoadState());
    }


    private set(id: string, state: boolean, map: Map<string, true>) {
        if (state) {
            map.set(String(id), true);
        } else {
            map.delete(String(id));
        }
    }

    public setOptionState(id: string, state: boolean) {
        this.set(id, state, this.state.options);
    }
    public getOptionState(id: string) {
        return this.state.options.has(String(id));
    }
    public setOptionStateTree(valueDefinition: TFilterValueCheckbox, state :boolean) {
        // set option new state
        this.setOptionState(valueDefinition.id, state);

        // update children recursive
        valueDefinition.values?.forEach(v => this.setOptionStateTree(v, state));

        let parent = valueDefinition;
        if (state) {
            // check parents (without their children to avoid ∞ recursion)
            while (parent = this.checkboxParents.get(parent)) {
                this.setOptionState(parent.id, true);
            }
        } else {
            // uncheck parents
            while (parent = this.checkboxParents.get(parent)) {
                const areChildrenFalsy = parent.values.every(v => !this.getOptionState(v.id));
                if (areChildrenFalsy) {
                    this.setOptionState(parent.id, false);
                } else {
                    break;
                }
            }
        }
    }
    public clearOptions(): void {
        this.state.options.clear();
    }

    public setPeriodState(id: string, state: boolean) {
        this.set(id, state, this.state.periods);
    }
    public getPeriodState(id: string) {
        return this.state.periods.has(id);
    }
    public clearPeriods() {
        this.state.periods.clear();
    }

    public setShortenedPeriodState(id: string): void {
        this.state.shortenedPeriod = id;
    }
    public getShortenedPeriodState(): string|null {
        return this.state.shortenedPeriod;
    }
    public clearShortenedPeriodState(): void {
        this.state.shortenedPeriod = null;
    }

    public setFavouriteState(id: string): void {
        this.state.favourite = id;
    }
    public getFavouriteState(): string|null {
        return this.state.favourite;
    }
    public clearFavouriteState(): void {
        this.state.favourite = null;
    }

    public setFilterData(data: TFilterData) {
        this.data.options.clear();
        data.forEach(v => this.data.options.set(v.id, v));
    }
    public getOptionData(id: string): TOptionData|undefined {
        return this.data.options.get(id);
    }


}
