import BaseDashboardComponent from "./BaseDashboardComponent";
import Table from "./shared/Table";
import arrayToCsvDownload from "../../../js/utility/arrayToCsvDownload";

export default abstract class BaseTableDashboardComponent extends BaseDashboardComponent {
    protected $table: JQuery;
    protected table?: Table;
    protected $search: JQuery<HTMLInputElement>;

    public init(): void {
        this.$table = this._$element.find('[data-table]');
        this.$search = <JQuery<HTMLInputElement>>this._$element.find('[data-search]');

        this._$element.find('[data-chart-export-csv]').on('click', (e) => {
            e.preventDefault();
            if (this.table) {
                arrayToCsvDownload(this.table.arrayExport(), e.currentTarget.dataset.chartExportCsv);
            } else {
                console.error('Unable to export empty table.')
            }
        });
    }

}
