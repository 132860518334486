import {Chart} from "highcharts";
import exportChartToArray from "./exportChartToArray";
import translator from "../../../../translator";
import {TLowBase} from "../../../../Board/src/model/TBoardOptions";
import Table from "../../Table";
import {Column} from "../../Table/js/Table";

export default class TableExporter {

    private arrayExportFnc = exportChartToArray;

    constructor(
        private readonly $target: JQuery,
        private lowBase?: TLowBase
    ) { }

    private hasTable(): boolean {
        return Boolean(this.$target.find('.chart-data-container').length);
    }

    private createColumnClasser(lowBaseArray:(boolean|null)[][]) {
        if (this.lowBase?.mode === "mark") {
           return (value, row, context) => {
               return lowBaseArray?.[context.rowIndex]?.[context.colIndex] ? 'lowBase' : '';
           };
        }
        return undefined;
    }

    private createColumnFormatter(label, isLowBaseArray:(boolean|null)[][]) {
        if (this.lowBase?.threshold && (this.lowBase?.mode === "filter" || this.lowBase?.mode === "hide")) {
            if (label === 'n') {
                return (value, row, context) => {
                    return isLowBaseArray?.[context.rowIndex]?.[context.colIndex] ? '' : value;
                }
            } else {
                return (value, row, context) => {
                    return (value === null || value === undefined || isLowBaseArray?.[context.rowIndex]?.[context.colIndex]) ? '' : value;
                };
            }
        }
    }

    private getColumns(chartData, isLowBaseArray:(boolean|null)[][]): Column[] {
        return chartData[0].map((label, i) => {
            if (i===0) {
                return {
                    id: i,
                    label: '',
                    class: 'strong',
                    th: true,
                }
            }
            return {
                id: i,
                label: label,
                classer: this.createColumnClasser(isLowBaseArray),
                formatter: this.createColumnFormatter(label, isLowBaseArray),
            }
        });
    }

    private export(chart: Chart): void {
        this.hide();

        const isLowBaseArray = [];
        const chartData = this.arrayExportFnc(chart, true, isLowBaseArray);
        const $container = $('<div class="chart-data-container table-responsive"></div>');
        const $table = $('<table class="table table-fixed table-bordered table-hover table-sm small"></table>');
        const table = new Table($table, this.getColumns(chartData, isLowBaseArray));
        // @ts-ignore
        table.load(chartData.slice(1))

        const $close = $(`<div class="text-right small m-3" data-html2canvas-ignore><a href="#">${translator.translate('close')}</a></div>`);
        $close.on('click', e => {
            e.preventDefault();
            this.hide();
        });

        $container.append($table);
        this.$target.append($container);
        this.$target.append($close);
    }


    public update(chart: Chart): void {
        if (this.hasTable()) {
            this.export(chart);
        }
    }

    public hide(): void {
        this.$target.html('');
    }

    public toggle(chart: Chart): boolean {
        if (this.hasTable()) {
            this.hide();
            return false;
        }

        this.export(chart);
        return true;
    }

    public setLowBase(lowBaseSetting?: TLowBase): void {
        if (lowBaseSetting) {
            this.lowBase = lowBaseSetting;
        }
    }

    public setArrayExportFnc(exportFnc): void {
        this.arrayExportFnc = exportFnc;
    }

}