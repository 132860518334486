/**
 * Base class for filter items
 *  - items are in tree structure in Filter (sidebar)
 *  - item is container for filter of group of values
 *  - responsible for mutating FilterList
 */
export default abstract class FilterItem {

    onFilterLoadState(): void {
        // just relax and do nothing, give a chance to my children
    }
}
