import Highcharts from "highcharts";

let defined = false;

// Define custom series type for displaying min/total/max values using boxplot as a base
(function() {
    if (defined) {
        return;
    }

    Highcharts.seriesType('lowmedhigh', 'boxplot', {
        keys: ['low', 'median', 'high'],
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: ' +
                'Low <b>{point.low} NOK</b> - Median <b>{point.median} NOK</b> - High <b>{point.high} NOK</b><br/>'
        }
    }, {
        // Change point shape to a line with three crossing lines for low/median/high
        // Stroke width is hardcoded to 1 for simplicity
        drawPoints: function () {
            var series = this;
            this.points.forEach(function (point) {
                var graphic = point.graphic,
                    verb = graphic ? 'animate' : 'attr',
                    shapeArgs = point.shapeArgs,
                    width = shapeArgs.width,
                    left = Math.floor(shapeArgs.x) + 0.5,
                    right = left + width,
                    crispX = left + Math.round(width / 2) + 0.5,
                    highPlot = Math.floor(point.highPlot) + 0.5,
                    medianPlot = Math.floor(point.medianPlot) + 0.5,
                    // Sneakily draw low marker even if 0
                    lowPlot = Math.floor(point.lowPlot) +
                        0.5 - (point.low === 0 ? 1 : 0);

                if (point.isNull) {
                    return;
                }

                if (!graphic) {
                    point.graphic = graphic = series.chart.renderer
                        .path('point')
                        .add(series.group);
                }

                graphic.attr({
                    stroke: point.color || series.color,
                    "stroke-width": 1
                });

                graphic[verb]({
                    d: [
                        'M', left, highPlot,
                        'H', right,
                        'M', left, medianPlot,
                        'H', right,
                        'M', left, lowPlot,
                        'H', right,
                        'M', crispX, highPlot,
                        'V', lowPlot
                    ]
                });
            });
        }
    });

    defined = true;
})();