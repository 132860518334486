import ExportToImage from "./ExportToImage";

export default class ExportComponentToImage extends ExportToImage
{
    private scroll = [0, 0];
    private scrollBehavior = 'smooth';

    protected init(): void {
        this.$element.on('click', (e) => {
            e.preventDefault();
            this.beforeRender();
            this.render(this.$target[0])
                .then((canvas) => this.saveAs(canvas, this.filename))
                .finally(() => this.afterRender());
        });
    }


    protected beforeRender(): void {
        $('#loader').show();
        this.IEBeforeRender();

        this.scroll = [window.scrollX, window.scrollY];
        this.scrollBehavior = document.documentElement.style.scrollBehavior;

        document.documentElement.style.scrollBehavior = 'auto';
        document.documentElement.scrollTop = 0;
        document.documentElement.scrollLeft = 0;

        const originalWidth = this.$target.width();
        const target = this.$target[0];
        target.style.position = 'fixed';
        target.style.top = '0px';
        target.style.left = '0px';
        target.style.zIndex = '2';
        target.style.width = originalWidth+'px';
        target.style.setProperty('height', 'unset', 'important');
    }

    protected afterRender(): void {
        this.IEAfterRender()

        const target = this.$target[0];
        target.style.position = '';
        target.style.top = '';
        target.style.left = '';
        target.style.zIndex = '';
        target.style.width = '';
        target.style.setProperty('height', '');

        document.documentElement.scrollLeft = this.scroll[0];
        document.documentElement.scrollTop = this.scroll[1];
        document.documentElement.style.scrollBehavior = this.scrollBehavior;

        $('#loader').hide();
    }
}
