import {ExportToCsv} from "export-to-csv";

export default function arrayToCsvDownload(
    array: (string|number|boolean|null)[][],
    filename: string
): void {
    const decimalSeparator = (1.1).toLocaleString().substring(1, 2);
    const exportToCsv = new ExportToCsv({
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator,
        showLabels: false,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        filename,
        // title: filename,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    });

    exportToCsv.generateCsv(array);
}
