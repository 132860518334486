import FilterItem from "./FilterItem";
import m, {Vnode} from "mithril";
import {TFilterItemFavourite, TFilterValueFavourite} from "../../model/filterTypes";
import Filter from "../Filter";
import IFilterComponent from "../IFilterComponent";
import ISearchable from "./ISearcheable";
import SidebarItemFavourite from "./favourite/SidebarItemFavourite";
import translator from "../../../../translator";

export default class FilterItemFavourite extends FilterItem implements IFilterComponent, ISearchable {

    public subtreeItems: SidebarItemFavourite[] = [];

    constructor(
        public readonly definition: TFilterItemFavourite,
        public readonly filter: Filter,
        public parent?: IFilterComponent
    ) {
        super();
        this.filter.search?.register(this);

        this.initSubtreeItems();
    }

    private initSubtreeItems(): void {
        this.subtreeItems = this.definition.values?.map(v => new SidebarItemFavourite(this, v)) || [];
    }

    public  isActive(): boolean {
        return true;
    }

    public reset(): void {
        // chill and do nothing
    }


    public onFilterLoadState(): void {
        // do nothing
    }

    public onSearch(query: string): void {
        this.subtreeItems.forEach(i => i.onSearch(query));
    }

    public usePreset(definition: TFilterValueFavourite): void {
        this.filter.list().setFavouriteState(definition.id);

        this.filter.board.api.useFavouriteFilter(definition.id).then(
            () => this.filter.loadState(
                (f) => {
                    f.list().setFavouriteState(definition.id);
                }
            ),
            () => {
                throw new Error(`Unable to set filter '${this.definition.label}' (${definition.id}).`)
            }
        );
    }

    public removePreset(definition: TFilterValueFavourite): void {
        this.filter.board.api.deleteFavouriteFilter(definition.id).then(
            () => {
                this.definition.values = this.definition.values.filter(v => v.id !== definition.id);
                this.initSubtreeItems();
                m.redraw();
            },
            () => {
                throw new Error(`Unable to remove filter '${this.definition.label}' (${definition.id}).`);
            }
        );
    }



    // view methods
    viewModalPanel(): Vnode | null {
        return null;
    }

    viewModalSidebar(): Vnode | null {
        return null;
    }

    viewSidebar(): Vnode | null {

        const cssClass = '.FilterItem.FilterItem-Favourite.open'
        return m(cssClass, {}, [
            this.subtreeItems.length ? m('.subtree', {}, this.subtreeItems.map(si => m(si))) : m('.no-items', {}, translator.translate('no_preset_filters')),
        ]);
    }

}
