import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import TDataRangeChart from "../types/TDataRangeChart";
import ChartConstants from "../../../shared/highcharts/ChartConstants";

export default class XAxisOptions implements IOptions {
    options(data: TDataRangeChart): Partial<Options> {
        return {
            xAxis: {
                type: "category",
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true,
                labels: {
                    style: {
                        fontSize: "12px"
                    }
                },
                categories: data.categories,
                crosshair: {
                    color: ChartConstants.CROSS_HAIR_COLOR
                },
            }
        }
    }

}