import IOptions from "../../../shared/highcharts/options/IOptions";
import TDataPieChart from "../types/TDataPieChart";
import {Options} from "highcharts";

export default class ResponsivePieOptions implements IOptions {
    options(data: TDataPieChart): Partial<Options> {
        return {
            "responsive": {
                "rules": [
                    {
                        "condition": {
                            "minWidth": 800,
                        },
                        "chartOptions": {
                            "title": {
                                "y": 63,
                                "style": {
                                    "fontSize": "120px"
                                }
                            },
                            "subtitle": {
                                "y": 88,
                                "style": {
                                    "fontSize": "35px",
                                }
                            }
                        }
                    },
                    {
                        "condition": {
                            "maxWidth": 300
                        },
                        "chartOptions": {
                            "title": {
                                "y": 28,
                                "style": {
                                    "fontSize": "40px"
                                }
                            },
                            "subtitle": {
                                "y": 37,
                                "style": {
                                    "fontSize": "12px",
                                }
                            }
                        }
                    },
                    {
                        "condition": {
                            "maxWidth": 260
                        },
                        "chartOptions": {
                            "title": {
                                "y": 24,
                                "style": {
                                    "fontSize": "35px"
                                }
                            },
                            "subtitle": {
                                "y": 32,
                                "style": {
                                    "fontSize": "12px",
                                }
                            }
                        }
                    },
                    {
                        "condition": {
                            "maxWidth": 225
                        },
                        "chartOptions": {
                            "chart": {
                                "margin": [0, 0, 0, 0],
                            },
                            "title": {
                                "y": 20,
                                "style": {
                                    "fontSize": "30px"
                                }
                            },
                            "subtitle": {
                                "y": 28,
                                "style": {
                                    "fontSize": "12px",
                                }
                            }
                        }
                    },
                    {
                        "condition": {
                            "maxWidth": 180
                        },
                        "chartOptions": {
                            "chart": {
                                "margin": [0, 0, 0, 0],
                            },
                            "title": {
                                "y": 14,
                                "style": {
                                    "fontSize": "23px"
                                }
                            },
                            "subtitle": {
                                "y": 27,
                                "style": {
                                    "fontSize": "12px",
                                }
                            }
                        }
                    },
                    {
                        "condition": {
                            "maxWidth": 135
                        },
                        "chartOptions": {
                            "chart": {
                                "margin": [0, 0, 0, 0],
                            },
                            "title": {
                                "y": 11,
                                "style": {
                                    "fontSize": "16px"
                                }
                            },
                            "subtitle": {
                                "y": 20,
                                "style": {
                                    "fontSize": "9px",
                                }
                            },
                            "plotOptions": {
                                "pie": {
                                    "size": "100%",
                                }
                            }
                        }
                    },
                ],
            },
        }
    }
}