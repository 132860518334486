import IOptions from "../../../shared/highcharts/options/IOptions";
import TDataPieChart from "../types/TDataPieChart";
import {Options, Point} from "highcharts";
import ExtendedPointOptionsObject from "../../../shared/highcharts/ExtendedPointOptionsObject";
import {formatPointCount, formatPointValue} from "../../../shared/highcharts/formatters";

export default class PlotOptionsPieOptions implements IOptions {
    options(data: TDataPieChart): Partial<Options> {
        const pointDataLabelFormatter = function() {
            const point: ExtendedPointOptionsObject = this.point;
            if (this.point.custom.count === 0) { // rest to 100%
                return '';
            }
            const value = formatPointValue(point);
            const count = formatPointCount(point);
            const countColor = point.custom.isLowBase ? 'color:red' : '';
            return `<span>${value}</span> <span style="${countColor}">${count}</span>`;
        };

        return {
            "plotOptions": {
                "series": {
                    "dataLabels": {
                        "enabled": false,
                        "allowOverlap": true,
                        "format": null,
                        "formatter": pointDataLabelFormatter,
                        "overflow": "justify",
                        "align": "center",
                        // @ts-ignore
                        "distance": -20,
                        "style": {
                            "fontWeight": "normal",
                            "fontSize": "11px",
                            "color": "black",
                            "textOverflow": "none",
                        }
                    },
                    "cursor": "pointer",
                    "point": {
                        "events": {
                            "click": (e) => {
                                const point = <Point & ExtendedPointOptionsObject> e.point;
                                const link = point.custom?.link;
                                if (link) {
                                    window.open(link).focus();
                                }
                            },
                        }
                    }
                },
                "pie": {
                    "startAngle": 0,
                    "endAngle": 360,
                    "innerSize": "73%",
                    "size": "100%",
                    "cursor": "pointer",
                    "states": {
                        "hover": {
                            "halo": {
                                "size": 0,
                            },
                        }
                    }
                }
            },
        }
    }
}