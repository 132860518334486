export default function fullscreenConditionAndChartOptions() {
    return {
        "condition": {
            "minWidth": 800,
            "minHeight": 700,
        },
        "chartOptions": {
            "legend": {
                "itemStyle": {
                    "fontSize": "18px",
                }
            },
            "xAxis": {
                "labels": {
                    "style": {
                        "fontSize": "22px"
                    }
                }
            },
            "yAxis": {
                "labels": {
                    "style": {
                        "fontSize": "22px"
                    }
                }
            },

            "plotOptions": {
                "series": {
                    "dataLabels": {
                        "style": {
                            "fontSize": "18px",
                        }
                    },
                    "label": {
                        "style": {
                            "minFontSize": "18px",
                        }
                    }
                },
            },
        }
    };
}
