// remove loader
import INetteExtension from "../../INetteExtension";

export default class LoadingExtension implements INetteExtension {

    init() {
        const $loader = $('body > .loader-overlay');
        $loader.fadeTo("fast", 0, () => $loader.remove());
    }

}
