import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import {createValueFormatterFromDataset} from "../../../shared/highcharts/formatters";
import TDataRangeChart from "../types/TDataRangeChart";

export default class YAxisOptions implements IOptions {

    options(data: TDataRangeChart): Partial<Options> {
        const tickFormatter = createValueFormatterFromDataset(data?.datasets?.[0]);

        return  {
            yAxis: [{
                title: {
                    text: ""
                },
                endOnTick: true,
                startOnTick: true,
                plotBands: [],
                plotLines: [],
                labels: {
                    formatter: function () {
                        // only 1 value ? round to 3 numbers : no round
                        const tickPrecision = (this.isFirst && this.isLast) ? 3 : null;
                        return tickFormatter.format(this.value, tickPrecision);
                    },
                    style: {
                        textOverflow: 'none',
                        fontSize: "12px"
                    }
                }
            }],
        }
    }

}
