import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import ExtendedPointOptionsObject from "../ExtendedPointOptionsObject";

export default class PlotOptionsSeriesPointClickOpenLinkOptions implements IOptions {
    options(data: any): Partial<Options> {

        return {
            plotOptions: {
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: (e) => {

                                const link = (<ExtendedPointOptionsObject>e.point).custom?.link;
                                if (link) {
                                    window.open(link).focus();
                                }

                            }
                        }
                    }
                }
            }
        }

    }

}
