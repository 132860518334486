import INetteExtension from "../../INetteExtension";

export default class ModalsExtension implements INetteExtension {

    init() {
        const $modal = $('.modal');

        // move all modals into modal container on begin of page
        $modal.each((i, el) => {
            $('.modals-container').first().append($(el));
        })

        let showModalLoaderOnLoad = true;
        let $modalSnippetBodyEl = $('.modal-body .snippet-body');
        if(window.location.href.indexOf('fragment=ratingRequestDetail-modal') !== -1) {
            showModalLoaderOnLoad = false;
            $('#ratingRequestDetail-modal').modal('show');
        }

        if (showModalLoaderOnLoad) {
            $modalSnippetBodyEl.html('<div class="dashboard-component-loader"></div>');
        }

        $modal.on('shown.bs.modal', () => {
            $('.modals-container').show();
            $('html').addClass('modal-opened')
        }).on('hidden.bs.modal', () => {
            $modalSnippetBodyEl.html('<div class="dashboard-component-loader"></div>');
            $('.modals-container').show();
            $('html').removeClass('modal-opened');
        });

    }

}
