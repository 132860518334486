/**
 * Scroll to given element with given id (hash) to the center of page
 * and add class highlight-blink to highlight that element
 */
export default function scrollToHash(hash?: string): boolean {
    hash ??= window.location.hash;
    if (hash && hash !== '#') {
        if (!hash.startsWith('#')) {
            hash = '#'+hash;
        }
        const $target = $(hash);
        if ($target.length) {
            $target.addClass('highlight-blink');
            window.setTimeout(() => $target.removeClass('highlight-blink'), 2000);
            window.location.hash = '';
            $target.get(0)?.scrollIntoView({block: "center", inline: "end", behavior: "auto"});
            return true;
        }
    }
    return false;
}
