import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import Table from "../../shared/Table";
import arrayToCsvDownload from "../../../../../js/utility/arrayToCsvDownload";

type TRow = {reason: string, score:number};

type TEncodedOpenQuestionFrequencyTableData = {
    count: number;
    rows: TRow[]
}

export default class EncodedOpenQuestionFrequencyTable extends DashboardComponent {
	private table: Table;
	public _data: TEncodedOpenQuestionFrequencyTableData;

	init(): void {
		super.init();

		const $table = this._$element.find('[data-table]');

		this.table = new Table($table, [
			{
				id: 'reason',
				label: $table.find('[data-field="reason"]').first().text().trim(),
				sortable: true,
			},
			{
				id: 'score',
				label: $table.find('[data-field="score"]').first().text().trim(),
				sortable: true,
				formatter: value => value + ' %',
				exportFormatter: value => value + '%',
				styler: () => 'text-align:right;',
			}
        ]);

        this.table.sortOptions.column = 'score';
        this.table.sortOptions.order = 'DESC';

        this._$element.find('[data-chart-export-csv]').on('click', (e) => {
			e.preventDefault();
			arrayToCsvDownload(this.table.arrayExport(), e.currentTarget.dataset.chartExportCsv);
        });
    }

	onUpdate(data: TEncodedOpenQuestionFrequencyTableData)
	{
        this.table.load(data.rows);
	}

    registerOwnFilterChange()
    {
        this._$ownFilter.find('form').on('change submit', e => {
            e.preventDefault();
            this._componentManager.applyComponentFilter(this, new FormData(e.currentTarget));
        });

        this._$ownFilter.find('form input[type="radio"]').on('change', function() {
            // checked option needs to be saved to DOM - because of prinitlo
            $(this).closest('form').find('input[type="radio"]:not(:checked)').removeAttr('checked');
            $(this).closest('form').find('input[type="radio"]:checked').attr('checked', 'checked');
        });
    }
}

ComponentManager.registerComponentClass('EncodedOpenQuestionFrequencyTable', EncodedOpenQuestionFrequencyTable);