import webalize from "../../../js/utility/webalize";

export default class ScreenshotEmailPdfAttachment {

    private readonly $nameInput: JQuery;
    private readonly $webalizedInput: JQuery;
    private readonly $urlInput: JQuery;
    private readonly $attachment: JQuery;
    private readonly $submit: JQuery;

    private readonly defaultName: string;
    private readonly maxAttachmentLength: number;

    private printed = false;

    constructor(
        private readonly $component: JQuery
    ) {
        this.$nameInput = $component.find('input[name="attachmentName"]')
        this.$webalizedInput = $component.find('input[name="attachmentNameWebalized"]')
        this.$urlInput = $component.find('input[name="attachment"]');
        this.$attachment = $component.find('[data-attachment]');
        this.$submit = $component.find('input[type="submit"]');

        this.defaultName = this.$component.data('default-name');
        this.maxAttachmentLength = this.$component.data('max-attachment-length') ?? 60;

        this.init();
    }

    private init(): void {
        this.$urlInput.on('change', () => {
            this.printed = true;
            this.$attachment.attr('class', 'form-group d-flex align-items-center');
            this.$submit.removeAttr('disabled');
            this.redraw();
        });

        this.$nameInput.on('input', () => {
            this.$webalizedInput.val(this.getFileName());
            this.redraw();
        });
    }

    private getFileName(): string {
        const fileName = webalize(String(this.$nameInput.val() ?? '')).substring(0,this.maxAttachmentLength);
        return fileName || webalize(this.defaultName) || 'print';
    }

    private createAttachmentLink(url: string): JQuery {
        const fileName = this.getFileName() + '.pdf';
        const $link = $(`<i class="icon ico-attachment"></i> <a href="${url}" target="_blank" download="${fileName}">${fileName}</a>`);
        $link.on('click', async e => {
            e.preventDefault();
            await this.convertFileToBlobAndDownload(url, fileName);
        });
        return $link;
    }

    /**
     * Download file into browser first and save it with different name
     * https://stackoverflow.com/a/75190424/11350567
     */
    private convertFileToBlobAndDownload = async (fileUrl: string, newName: string) => {
        const blob = await fetch(fileUrl).then(r => r.blob())
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = newName; // add custom extension here
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        // Remove "a" tag from the body
        a.remove()
    }

    private createErrorMessage(): JQuery {
        return $(`<span class="text-danger">Could not print to pdf.</span>`);
    }


    private redraw(): void {
        if (!this.printed) {
            return;
        }

        this.$attachment.html('');

        const url = <string>this.$urlInput.val();
        const $content = url ? this.createAttachmentLink(url) : this.createErrorMessage();
        this.$attachment.append($content);
    }

}