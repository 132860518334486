import {Options} from "highcharts";
import OptionsComposer from "../../shared/highcharts/options/OptionsComposer";
import PlotOptionsRangeOptions from "./options/PlotOptionsRangeOptions";
import XAxisOptions from "./options/XAxisOptions";
import YAxisOptions from "./options/YAxisOptions";
import CreditsOptions from "../../shared/highcharts/options/CreditsOptions";
import ExportingOptions from "../../shared/highcharts/options/ExportingOptions";
import PlotOptionsDataLabelsOptions from "../../shared/highcharts/options/PlotOptionsDataLabelsOptions";
import PlotOptionsSeriesPointClickOpenLinkOptions
    from "../../shared/highcharts/options/PlotOptionsSeriesPointClickOpenLinkOptions";
import TitleOptions from "../../shared/highcharts/options/TitleOptions";
import CustomOptions from "../../shared/highcharts/options/CustomOptions";
import LowBaseModifier from "../../shared/highcharts/modifiers/LowBaseModifier";
import LegendOptions from "../../shared/highcharts/options/LegendOptions";
import ChartConstants from "../../shared/highcharts/ChartConstants";
import ResponsiveOptions from "./options/ResponsiveOptions";
import TooltipOptions from "./options/TooltipOptions";
import SeriesOptions from "./options/SeriesOptions";
import RangeChartOptions from "./options/RangeChartOptions";
import TDataRangeChart from "./types/TDataRangeChart";
import RangeChartHideDatasetOnLegendLick from "./options/RangeChartHideDatasetOnLegendLick";
import Color from "color";
import AddTargetsModifier from "../../shared/highcharts/modifiers/AddTargetsModifier";

export default class RangeOptionsTransformer {

    private readonly composer: OptionsComposer;

    constructor() {
        this.composer = new OptionsComposer([
            new RangeChartOptions(),

            new CreditsOptions(),
            new ExportingOptions(),
            new ResponsiveOptions(),
            new TitleOptions(),

            new XAxisOptions(),
            new YAxisOptions(),
            new LegendOptions(),
            new TooltipOptions(),
            new SeriesOptions(),

            new PlotOptionsRangeOptions(),
            new PlotOptionsDataLabelsOptions(),
            new RangeChartHideDatasetOnLegendLick(),
            new PlotOptionsSeriesPointClickOpenLinkOptions(),

            new CustomOptions(),
        ]);
    }

    transform(data: TDataRangeChart): Options {
        const options = this.composer.options(data);

        this.applyLowBase(data, options);
        (new AddTargetsModifier(data)).modify(options);

        return options;
    }

    private applyLowBase(data: TDataRangeChart, options: Options): Options {
        const lowBase = LowBaseModifier.loadLowBase(data, {base: "point"});

        const hideCallback = p => {
            if (p.custom?.type) { // scatter points
                p.visible = false;
            } else { // boxplot points
                p.color = 'transparent';
                p.medianColor = 'transparent';
            }
        }
        const markCallback = p => {
            if (p.custom?.type) { // scatter points
                p.marker ??= {};
                p.marker.fillColor = Color(p.lineColor).mix(new Color('white'), 1-ChartConstants.LOW_BASE_OPACITY_LINE).rgb().string();
            }
        };

        const pointCallback = ['filter','hide'].includes(lowBase.mode) ? hideCallback : markCallback;

        const lowBaseOptions = {
            pointCallback: pointCallback,
            removeCategories: false,
            removeDatasets: false,
        };
        (new LowBaseModifier(lowBase, lowBaseOptions)).modify(options);

        return options;
    }

}
