import FilterItem from "./FilterItem";
import m, {Vnode} from "mithril";
import {TFilterItemCheckbox, TFilterValueCheckbox} from "../../model/filterTypes";
import Filter from "../Filter";
import IFilterComponent from "../IFilterComponent";
import SidebarItemCheckbox from "./checkbox/SidebarItemCheckbox";
import ISearchable from "./ISearcheable";
import StringMatcher from "../../utilities/StringMatcher";
import FilterGroup from "./FilterGroup";
import CheckboxSelector from "../../model/Selector/CheckboxSelector";
import FilterItemLabel from "./FilterItemLabel";

export default class FilterItemCheckbox extends FilterItem implements IFilterComponent, ISearchable {
    private readonly subtreeItems: SidebarItemCheckbox[] = [];
    public isOpen: boolean = false;
    private isSearching = false;
    private searchMatch = false;
    private searchMatchSelf = false;
    private readonly searchMatcher: StringMatcher;

    private readonly label: FilterItemLabel;

    public readonly selector: CheckboxSelector;

    constructor(
        public readonly definition: TFilterItemCheckbox,
        public readonly filter: Filter,
        public parent?: IFilterComponent
    ) {
        super();
        this.filter.search?.register(this);

        this.searchMatcher = new StringMatcher(definition.label);

        if (definition.values) {
            this.subtreeItems = definition.values.map(v => new SidebarItemCheckbox(this, null, v));
        }

        this.selector = new CheckboxSelector(this);

        this.label = new FilterItemLabel(this.definition.label);
    }

    isActive(): boolean {
        return !this.isAllChecked();
    }

    public isAllChecked(): boolean {
        return this.getFlattenValues().every(v => this.getState(v));
    }

    public getFlattenValues(): TFilterValueCheckbox[] {
        const values = [];
        const traverse = (v: TFilterValueCheckbox) => {
            values.push(v);
            v.values?.forEach(sv => traverse(sv));
        }
        this.definition.values?.forEach(v => traverse(v));
        return values;
    }

    public onFilterLoadState(): void {
        this.subtreeItems.map(i => i.onFilterLoadState());
        this.isOpen = this.isActive();
    }

    public setState(state: boolean, valueDefinition?: TFilterValueCheckbox): void {

        const list = this.filter.list();
        list.clearFavouriteState();

        if (valueDefinition) {
            this.selector.setLastInteractedCheckbox(valueDefinition);
            list.setOptionStateTree(valueDefinition, state);
        } else {
           this.definition.values?.forEach(v => this.setState(state, v));
        }
    }

    public extendSelection(valueDefinition: TFilterValueCheckbox): void {
        this.selector.extendSelection(valueDefinition);
    }

    public getState(valueDefinition: TFilterValueCheckbox): boolean|null {
        return this.filter.list().getOptionState(valueDefinition.id);
    }

    public getCheckboxCheckedState(valueDefinition: TFilterValueCheckbox): "checked"|"unchecked"|"semichecked" {

        // is unchecked
        if (!this.getState(valueDefinition)) {
            return "unchecked";
        }

        // has some of children unchecked
        if (valueDefinition.values?.some(v => this.getCheckboxCheckedState(v) === "unchecked")) {
            return "semichecked"
        }

        // is checked
        return "checked";
    }

    public onSearch(query: string) {
        this.isSearching = Boolean(query);
        this.searchMatchSelf = this.searchMatcher.match(query);
        if (this.searchMatchSelf) {
            this.subtreeItems.forEach(si => si.onSearch(''));
            this.searchMatch = true;
        } else {
            this.searchMatch = this.subtreeItems.filter(si => si.onSearch(query)).length > 0;
        }
        this.isOpen = this.isSearching && this.searchMatch;
    }

    public open(): void {
        this.isOpen = true;
        (<FilterGroup>this.parent)?.open?.();
    }

    // view methods
    viewModalPanel(): Vnode | null {
        return null;
    }

    viewModalSidebar(): Vnode | null {
        return null;
    }

    viewSidebar(): Vnode | null {
        if (this.isSearching && !this.searchMatch) {
            return null;
        }

        const cssClass = '.FilterItem.FilterItem-Checkbox' + (this.isOpen ? '.opened' : '.closed');
        return m(cssClass, {}, [
            m('.item', {
                onclick: (e: MouseEvent) => {
                    e.preventDefault();
                    this.isOpen = !this.isOpen
                },
            }, [
                m('div.item-label', {}, [
                    m(this.label)
                ]),
                m('.control', {}, [
                    this.isActive() ? m('i.icon.ico-filter-remove', {
                        onclick: (e: MouseEvent) => {
                            e.stopPropagation();
                            this.reset();
                            this.filter.triggerChange();
                        }
                    }) : null,
                    this.subtreeItems.length ? m('i.icon.ico-dropdown') : null,
                ]),
            ]),
            m('.subtree', {}, this.isOpen ? this.subtreeItems.map(si => m(si)) : null),
        ]);
    }

    public reset() {
        this.setState(true);
    }

}
