import IOptions from "../../../shared/highcharts/options/IOptions";
import TDataPieChart from "../types/TDataPieChart";
import {Options} from "highcharts";

export default class LegendPieOptions implements IOptions {
    options(data: TDataPieChart): Partial<Options> {

        return {
            "legend": {
                "floating": false,
                "align": "center",
                "useHTML": true,
                "verticalAlign": "bottom"
            },
        }
    }
}