import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import TDataWordCloud from "./TDataWordCloud";
import Highcharts from 'highcharts';
import wordCloudDefinition from "./wordCloudDefinition";
import _ from 'lodash';
import BaseHighchartsComponent from "../../BaseHighchartsComponent";
import ValueFormatter from "../../../model/ValueFormatter";

class WordCloud extends BaseHighchartsComponent {

    public onUpdate(data: TDataWordCloud): void {

        this.chart?.destroy();

        const series = data.datasets?.map(dataset => {
            const r = dataset.representation;
            const formatter = new ValueFormatter(r?.precision, r?.unitMark, r?.emptyValue, r?.decimalSeparator);
            return {
                type: "wordcloud",
                name: dataset.label,
                color: dataset.color,
                rotation: {
                    from: 0,
                    to: 0,
                    orientations: 1
                },
                data: dataset.data.map((p) => {
                    return {
                        name: p.label,
                        weight: p.count,
                        custom: {
                            count: p.count,
                            value: p.value,
                            link:  p.link,
                            label: p.label,
                            datasetLabel: dataset.label,
                            formatter,
                        }
                    }
                }),
            };
        }) ?? [];


        // FORMATTERS
        const tooltipFormatter = function() {
            let label, value, count;
            label = this.point.name;
            value = this.point.custom.formatter.format(this.point.custom.value);
            value = value ? value+', ' : '';
            count = 'n='+this.point.custom.count;
            return `<span style="color:${this.point.color}">●</span> <span style="font-size:11px">${label}</span><br>
                &nbsp;&nbsp;<span>${value}${count}</span>`;
        };


        const chartOptions = _.merge(wordCloudDefinition(), {
            series: series,
            tooltip: {
                formatter: tooltipFormatter
            },
            plotOptions: {
                series: {
                    point: {
                        events: {
                            click: (e) => {
                                const link = e.point.custom.link;
                                if (link) {
                                    window.open(link).focus();
                                }
                            },
                        },
                    }
                }
            }
        }, data.chart);


        this.chart = Highcharts.chart(this._$element.find('[data-chart]')[0], chartOptions);

    }
}

ComponentManager.registerComponentClass('WordCloud', WordCloud);
