import {Chart, Point, Series} from "highcharts";
import ExtendedPointOptionsObject from "../ExtendedPointOptionsObject";

type TRawResult = (string|number)[][];

function isGeneratedSeries(series: Series): boolean {
    return (series?.userOptions?.id || '').indexOf('__') === 0 || (series?.userOptions?.name || '').indexOf('__') === 0;
}

function getValue(point: Point & ExtendedPointOptionsObject, format: boolean): string|number {
    const formatter = point.custom?.formatter;
    const value = point.custom?.value ?? point.y;
    if (format && formatter) {
        return formatter.format(value);
    } else {
        return value ?? '';
    }
}

function readPointValues(point: Point & ExtendedPointOptionsObject, format: boolean) {
    if (isGeneratedSeries(point.series)) {
        return [getValue(point, format)];
    }

    const type = point.series.type;
    switch (type) {
        case 'wordcloud': return [point.custom.count, point.name, getValue(point, format)];
        case 'scatter': return [point.x, point.y];
        default: return [point.custom?.count ?? '', getValue(point, format)];
    }
}

function readHeaderForSeries(series: Series, format: boolean) {

    const type = series.type;
    const point = <Point & ExtendedPointOptionsObject>series.data.filter(p => p)[0];
    const unit: string|undefined = point?.custom?.formatter?.getUnit().trim();
    let formattedUnit = '';
    if (unit && !format) {
        formattedUnit = ' ['+unit+']';
    }
    if (isGeneratedSeries(series)) {
        return [series.name+formattedUnit];
    }
    switch (type) {
        case 'wordcloud': return ['n', 'word', series.name+formattedUnit];
        case 'scatter': return [series.name+' x', series.name+' y'];
        default: return ['n', series.name+formattedUnit];
    }
}

function getSeries(chart: Chart): Series[] {
    const series = chart.options?.legend?.reversed ? chart.series.reverse() : chart.series;
    return series.filter(series => !isGeneratedSeries(series));
}

function getCategories(chart: Chart): (string|number)[] {
    return chart.series?.[0]?.data.map((s,i) => s.name || chart.xAxis?.[0]?.categories[i] || (i+1)) || [];
}

function filterResult(chart: Chart, rawResult: TRawResult): TRawResult {
    if (chart.series?.[0]?.type === 'pie') {
        return rawResult.filter(row => String(row[1]) !== '0'); // filter away PieChart points with n=0
    }
    return rawResult;
}

function sortResult<T>(chart: Chart, rawResult: T[][]): T[][] {
    let reference: number[];
    let reversed: number;

    // Search first serie with sort enabledDataSorting and read its values as sorting reference
    chart.series.some(serie => {
        if (serie['enabledDataSorting']) {
            reference = serie.data.map((point: Point & ExtendedPointOptionsObject) => point.custom?.value ?? point.y);
            const axisReversed = serie.xAxis.reversed ? 1 : -1;
            const chartInverted = serie.xAxis.chart.inverted ? 1 : -1;
            reversed = axisReversed * chartInverted;
            return true;
        }
    });

    // No sorting
    if (!reference) {
        return rawResult;
    }

    // Sort results rows by reference
    const pairs: [number, T[]][] = rawResult.map((row,i) => [reference[i], row]);
    const sortedPairs = pairs.sort( (a,b) => reversed*(b[0]-a[0]));
    return sortedPairs.map(pair => pair[1]);
}

/**
 * Export chart data in 2 dimensional table-like array
 *
 * @param chart          Highcharts object
 * @param formatNumbers  Should format numbers like in chart
 * @param isLowBaseArray Array to be filled with booleans which values are marked as low base
 */
export default function exportChartToArray(
    chart: Chart,
    formatNumbers: boolean,
    isLowBaseArray?: (boolean|null)[][]
): TRawResult {

    isLowBaseArray ??= [];

    const chartSeries = getSeries(chart);

    // header
    const headerArray = [''];
    chartSeries.forEach(series => headerArray.push(...readHeaderForSeries(series, formatNumbers)));

    // categories
    const categories = getCategories(chart);

    // points
    let rawResult: TRawResult = [];

    chartSeries
        .forEach((series, /* x */) => {
            series.data.forEach((point: Point & ExtendedPointOptionsObject, y) => {
                const category = String(categories[y] || (y+1));
                const row = rawResult[y] || [category];
                row.push(...readPointValues(point, formatNumbers));
                rawResult[y] = row;

                const lowBaseRow = isLowBaseArray[y] || [null];
                lowBaseRow.push(point.custom?.isLowBase, point.custom?.isLowBase);
                isLowBaseArray[y] = lowBaseRow;
            });
        });

    // sort results
    rawResult = sortResult(chart, rawResult);

    // sort isLowBaseArray in-place
    const isLowBaseArraySorted = sortResult(chart, isLowBaseArray);
    isLowBaseArray.length = 0;
    isLowBaseArraySorted.forEach(row => isLowBaseArray.push(row));

    return filterResult(chart, [headerArray, ...rawResult]);
}