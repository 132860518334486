import Feature from "ol/Feature";
import {MapDataFeature} from "./MapDataTypes";
import {Overlay} from "ol";
import {Coordinate} from "ol/coordinate";
import MapFeatureFactory from "./MapFeatureFactory";
import ValueFormatter from "../../../model/ValueFormatter";

type RichFeature = Feature & {values_: {data: MapDataFeature}};

/**
 * Map popup creates overlay layer with popup window.
 */
export default class MapPopup {

    private readonly $content: JQuery<HTMLElement>;
    public readonly mapOverlay: Overlay; // <-- This must be connected to OL map as overlay

    constructor(
        public readonly $popup: JQuery<HTMLElement>,
    ) {
        this.$content = this.$popup.find('.ol-popup-content');
        this.$popup.find('.ol-popup-closer').on('click', () => this.hidePopup());
        this.mapOverlay = new Overlay({
            element: this.$popup[0],
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        })
    }

    public hidePopup(): void {
        this.mapOverlay.setPosition(undefined); // unset overlay
    }

    public showPopup(coordinate: Coordinate, features: RichFeature[]): void {
        this.$popup.css('display', 'block');
        this.refreshPopupContent(features);
        this.mapOverlay.setPosition(this.pickCoordinates(coordinate, features));
    }

    private refreshPopupContent(features: RichFeature[]): void {
        this.$content.empty();
        for (const feature of features) {
            const featureData = feature.values_.data;
            const $feature = $(`<div class="feature"><h3>${featureData.title}</h3></div>`);
            for (const data of featureData.data) {
                const formatter = new ValueFormatter(data.settings?.precision, data.settings?.unitMark);
                const value = formatter.format(data.data.value);
                const count = data.data.count || 0;
                $feature.append($(`<div class="data"><label>${data.label}:</label> <span class="value">${value}</span>, <span class="count">n=${count}</span></div>`));
            }
            this.$content.append($feature)
        }
    }

    /**
     * Pick coordinate of first intersected point.
     * Fallback to clicked coordinate.
     * @param coordinate
     * @param features
     * @private
     */
    private pickCoordinates(coordinate: Coordinate, features: RichFeature[]): Coordinate {
        const picked = features.filter(f => f.values_.data.type === "point")[0];
        // @ts-ignore
        return picked?.values_.data.position ? MapFeatureFactory.createCoords(picked.values_.data.position) : coordinate;
    }



}