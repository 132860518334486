import INetteExtension from "../../INetteExtension";

export default class TooltipExtension implements INetteExtension {
    init(): void {
        let interval;

        $("body").tooltip({
            selector: '[data-toggle=tooltip]',
        }).on('shown.bs.tooltip', () => {
            // Remove dead tooltips
            if (interval) {
                return;
            }
            interval = setInterval(() => {
                const $tooltips = $('body > .tooltip');
                if ($tooltips.length === 0) {
                    clearInterval(interval);
                    interval = null;
                    return;
                }

                $tooltips.each((i,tooltip) => {
                    if ($(`[aria-describedby="${tooltip.id}"]`).length === 0) {
                        tooltip.remove();
                    }
                });
            }, 1500);
        });

    }
}
