import INetteExtension from "./INetteExtension";

export default abstract class ClassicExtension implements INetteExtension {

    init() {
        this.attach($('body'));
    }

    success(payload, status, jqXHR: XMLHttpRequest, setting) {
        for (let snippet in payload.snippets) {
            this.attach( $('#'+snippet) );
        }
    }

    /**
     * Attach events to body element or snippet element
     * @param $root
     */
    abstract attach($root: JQuery);

}
