// Inspired by https://github.com/sindresorhus/round-to/blob/master/index.js
// Thank you sindresorhus <3

type TMethod = 'round'|'ceil'|'floor';

function roundTo(method: TMethod, number: number, precision: number): number {
    if (typeof number !== 'number') {
        throw new TypeError('Expected value to be a number');
    }

    if (precision === Infinity) {
        return number;
    }

    if (!Number.isInteger(precision)) {
        throw new TypeError('Expected precision to be an integer');
    }

    const isRoundingAndNegative = method === 'round' && number < 0;
    if (isRoundingAndNegative) {
        number = Math.abs(number);
    }

    const power = Math.pow(10, precision);

    let result = Math[method](Number((number * power).toPrecision(15))) / power;

    if (isRoundingAndNegative) {
        result = -result;
    }

    return result;
}

export function round(number: number, precision: number): number {
    return roundTo("round", number, precision);
}

export function ceil(number: number, precision: number): number {
    return roundTo("ceil", number, precision);
}

export function floor(number: number, precision: number): number {
    return roundTo("floor", number, precision);
}