import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import TDataRangeChart from "../types/TDataRangeChart";

export default class ResponsiveOptions implements IOptions {
    options(data: TDataRangeChart): Partial<Options> {

        return {
            responsive: {
                rules: [],
            },
        }

    }

}
