import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import Highcharts from 'highcharts';
import BaseHighchartsComponent from "../../BaseHighchartsComponent";
import TDataRangeChart from "./types/TDataRangeChart";
import RangeOptionsTransformer from "./RangeOptionsTransformer";
import exportRangeChartToArray from "./exporter/exportRangeChartToArray";

export default class RangeChart extends BaseHighchartsComponent {

    private readonly optionsTransformer: RangeOptionsTransformer

    constructor(
        componentManager: ComponentManager,
        $element: JQuery<HTMLElement>,
        name: string,
        options: {[name: string]: any}
    ) {
        super(componentManager, $element, name, options);
        this.optionsTransformer = new RangeOptionsTransformer();

        this.tableExporter.setArrayExportFnc(exportRangeChartToArray);
        this.csvExporter.setArrayExportFnc(exportRangeChartToArray);
    }

    public onUpdate(data: TDataRangeChart): void {

        this.chart?.destroy();

        const chartOptions = this.optionsTransformer.transform(data);
        this.validate(chartOptions);

        this.chart = Highcharts.chart(this._$element.find('[data-chart]')[0], chartOptions);


        this.chart.container.onmousedown = null;
        this.chart.container.onclick = null;
    }
}

ComponentManager.registerComponentClass('RangeChart', RangeChart);
