import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import Table from "./shared/Table";
import {Column} from "./shared/Table/js/Table";
import BaseTableDashboardComponent from "./BaseTableDashboardComponent";

type TRow = {_attributes: {[attr: string]: string}} & {[field: number|string]: string|number};

type TResponseRateTableData = {
    columns: {class?:string, label?: string}[],
    rows: TRow[],
}

export default class ResponseRateTable extends BaseTableDashboardComponent
{

    public update(data: TResponseRateTableData) {
        super.update(data)

        const columns: Column[] = data.columns.map( (col,i) => {
                return {
                    id: String(i),
                    label: col.label ?? '',
                    class: col.class,
                    styler: value => typeof value === 'number' ? 'text-align:right' : '',
                };
            });

        this.table = new Table(this.$table, columns);

        this.table.setSearch(this.$search);

        this.table.setRowFormatter( ($tr, row, i, rows) => {
            for (const [attr, value] of Object.entries(row?._attributes ?? {})) {
                $tr.attr(attr, <string>value);
            }
        });

        this.table.load(data.rows);
    }

}

ComponentManager.registerComponentClass('ResponseRateTable', ResponseRateTable);