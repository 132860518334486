import {TFilterItem} from "./filterTypes";
import FilterItemPeriod from "../components/filterItems/FilterItemPeriod";
import FilterGroup from "../components/filterItems/FilterGroup";
import Filter from "../components/Filter";
import IFilterComponent from "../components/IFilterComponent";
import FilterItemCheckbox from "../components/filterItems/FilterItemCheckbox";
import FilterItemRange from "../components/filterItems/FilterItemRange";
import FilterItemFavourite from "../components/filterItems/FilterItemFavourite";
import FilterItemTilesRow from "../components/filterItems/FilterItemTilesRow";
import FilterItemTilesMatrix from "../components/filterItems/FilterItemTilesMatrix";

export default class FilterFactory {

    constructor(
        private filter: Filter,
    ) { }

    public createItem(
        definition: TFilterItem,
        parent?: IFilterComponent&{addItem?: (IFilterItem)=>void}
    ): IFilterComponent {
        let item;
        switch (definition.type) {
            case "group": {
                item = new FilterGroup(definition, this.filter, parent);
                break;
            }
            case "period": {
                item = new FilterItemPeriod(definition, this.filter, parent);
                break;
            }
            case "checkbox": {
                item = new FilterItemCheckbox(definition, this.filter, parent);
                break
            }
            case "range": {
                item = new FilterItemRange(definition, this.filter, parent);
                break
            }
            case "tiles_row": {
                item = new FilterItemTilesRow(definition, this.filter, parent);
                break
            }
            case "tiles_matrix": {
                item = new FilterItemTilesMatrix(definition, this.filter, parent);
                break
            }
            case "favourite": {
                item = new FilterItemFavourite(definition, this.filter, parent);
                break
            }
            default: {
                throw new Error(`Unable to create filter item. Type '${(<TFilterItem>definition).type}' is not supported.`)
            }
        }

        if (parent && typeof parent.addItem === 'function') {
            parent.addItem(item);
        }
        return item;
    }
}