import {Chart, SVGElement} from "highcharts";

export default function createTooltip(chart: Chart, html: string, x: number, y: number): SVGElement {
	const tooltip = chart.renderer.label(html, x, y, 'rectangle');

	tooltip
		.css({
			color: '#333333',
			pointerEvents: 'none',
			filter: 'drop-shadow(3px 3px 8px rgba(0, 0, 0, 0.3))',
		})
		.attr({
			fill: '#FFFFFF',
			padding: 8,
			r: 2,
		})
		.shadow({
			color: "rgba(0,0,0,0.15)",
			width: 8,
		})
		.add()
		.toFront();

	return tooltip;
}