import IOptions from "./IOptions";
import {Options} from "highcharts";
import {formatPointCount, formatPointValue} from "../formatters";
import ExtendedPointOptionsObject from "../ExtendedPointOptionsObject";

export default class PlotOptionsDataLabelsOptions implements IOptions {
    options(data: any): Partial<Options> {

        return {
            plotOptions: {
                series: {
                    dataLabels: {
                        formatter: function() {
                            const point = <ExtendedPointOptionsObject>this.point;
                            const value = formatPointValue(point);
                            const count = formatPointCount(point);
                            const countColor = point.custom?.isLowBase ? 'color:red' : '';
                            return `<span>${value}</span>, <span style="${countColor}">${count}</span>`;
                        },
                        enabled: false,
                        allowOverlap: true,
                        format: null,
                        overflow: "justify",
                        style: {
                            fontWeight: "normal",
                            fontSize: "11px",
                            color: "black",
                        }
                    }
                }
            }
        }

    }

}
