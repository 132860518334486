import _ from 'lodash';
import ChartColors from "../../../../../js/utility/ChartColors";
import TDataSankeyDiagram from "./TDataSankeyDiagram";
import sankeyDiagramDefinition from "./sankeyDiagramDefinition";
import renderColumnLabels from "./renderColumnLabels";

// Get color for dataset from:
//  1 custom chart series setting
//  2 given dataset
//  3 from default color palette

function getColor(data: TDataSankeyDiagram, dataset, i: number): string {
    const index = typeof dataset.index === 'number' ? dataset.index - 1 : i;

    // @ts-ignore nodes are on sankey series
    const customChartSeriesColor = data.chart?.series?.[0]?.nodes?.[index]?.color;
    return customChartSeriesColor ?? dataset.color ?? ChartColors.getColorByIndex(index);
}


export default function transformSankeyDiagramData(data: TDataSankeyDiagram) {

    const seriesData = [];
    const nodes = [];

    data.datasets.forEach((d, i) => {
        nodes.push({
            id: String(d.id),
            color: getColor(data, d, i),
            name: d.label,
            order: i, // behind the HC, check sankeyOrderNodes.ts
        });

        d.data?.forEach(p => {
            if (!p.count) {
                return;
            }
            seriesData.push({
                from: String(d.id),
                to: String(p.value),
                weight: p.count,
            })
        });
    });

    const spacingBottom = data.categories?.length ? 50 : 0;

    // CHART OPTIONS
    return _.merge(sankeyDiagramDefinition(), {
        chart: {
            spacingBottom: spacingBottom,
            events: {
                render: function () {
                    renderColumnLabels(this, data.categories);
                },
            }
        },
        series: [{
            type: "sankey",
            nodes: nodes,
            data: seriesData,
            name: "",
        }],
    }, data.chart);

}
