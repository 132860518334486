export default class PasswordCheckerList {
    private readonly $passwordInput: JQuery<HTMLInputElement>
    private readonly $passwordInputSecond: JQuery<HTMLInputElement>
    private readonly $passwordSpanInfo: JQuery<HTMLInputElement>
    private readonly $conditionsList: JQuery<HTMLInputElement>
    constructor(
        private readonly $element: JQuery
    ) {
        this.$passwordInput = <JQuery<HTMLInputElement>>this.$element.find('[data-password-checker="input"]');
        this.$passwordInputSecond = <JQuery<HTMLInputElement>>this.$element.find('[data-password-checker="input-second"]');
        this.$passwordSpanInfo = <JQuery<HTMLInputElement>>this.$element.find('[data-password-checker="equals"]');
        this.$conditionsList = <JQuery<HTMLInputElement>>this.$element.find('[data-password-checker="conditions"]');

        this.init();
    }

    private init() {
        this.$passwordInput.on('input', () => {
            // PASSWORD CONFIRMATION CHECK
            this.checkPasswords();

            // SHOW ONLY IF MAIN PASSWORD INPUT IS FILLED
            this.showHideList();


            // CHECK EACH REQUIREMENT IF IT`S VALID
            // @ts-ignore
            this.$conditionsList.children().each((index, element: HTMLElement) => {
                if (!element.getAttribute('data-password-checker')){
                    return true;
                }

                if(this.getInputVal(this.$passwordInput).match(element.getAttribute('data-password-checker-regex'))) {
                    this.valid(element)
                } else {
                    this.invalid(element)
                }
            });
        });

        this.$passwordInputSecond.on('input', () => {
            this.checkPasswords();
        });
    }

    private valid(element: HTMLElement): void
    {
        element.classList.remove("text-danger")
        element.classList.add("text-success")
    }

    private invalid(element: HTMLElement): void
    {
        element.classList.remove("text-success")
        element.classList.add("text-danger")
    }

    private getInputVal($value): string
    {
        return String($value.val() ?? '');
    }

    private checkPasswords(): void
    {
        if (this.getInputVal(this.$passwordInputSecond) !== this.getInputVal(this.$passwordInput)) {
            this.$passwordSpanInfo.removeAttr('hidden');
        } else {
            this.$passwordSpanInfo.prop('hidden', true);
        }
    }

    private showHideList() {
        if (this.getInputVal(this.$passwordInput) === '') {
            this.$passwordSpanInfo.prop('hidden', true);

            if (this.$conditionsList.hasClass('active')){
                this.$conditionsList.toggle('fast');
                this.$conditionsList.removeClass('active');
            }
        } else {
            if (!this.$conditionsList.hasClass('active')){
                this.$conditionsList.toggle('fast');
                this.$conditionsList.addClass('active');
            }
        }
    }
}
