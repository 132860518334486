import IModifier from "./IModifier";
import Target from "../../../shared/highcharts/TTarget";
import {
	Annotation,
	AnnotationsLabelsOptions,
	AnnotationsShapesOptions, Chart, Options,
	XAxisPlotBandsOptions, YAxisOptions, YAxisPlotBandsOptions,
} from "highcharts";
import CustomTooltipPositioner from "../CustomTooltipPositioner";
import {formatTooltip} from "../formatters";
import createTooltip from "../tooltip/createTooltip";
import ValueFormatter from "../../../../model/ValueFormatter";

type TargetOptions = { where: "plotBandX", yAxis: number, options: XAxisPlotBandsOptions, target: Target }
	| { where: "plotBandY", yAxis: number, options: YAxisPlotBandsOptions, target: Target }
	| {
	where: "annotation",
	yAxis: number,
	options: AnnotationsShapesOptions,
	label: AnnotationsLabelsOptions,
	target: Target
}
	| { where: "nowhere", target: Target };


export default class AddTargetsModifier implements IModifier {
	constructor(
		private readonly data: any
	) {
	}

	public modify(
		chartOptions: Options,
		callback: (modifiedPeace: any) => void = () => {
		}
	): void {
		this.addTargetsToHighchartOptions(chartOptions);
		this.addTooltips(chartOptions);
	}

	private addTargetsToHighchartOptions(chartOptions): void {
		let targetOptions: TargetOptions[] = [];

		// Global targets
		this.data.targets?.forEach(t => targetOptions.push(this.createTargetOptions(t, 0)));

		// Variable targets
		this.data.datasets.forEach(d => {
			const yAxisIndex = Number(Object.values(chartOptions.yAxis)
				.filter((a: YAxisOptions) => d.targetAxis && a.id === d.targetAxis)[0]?.[0] ?? 0); // Search axis index by its id // Found at Roswell 1947
			d.targets?.forEach(t => targetOptions.push(this.createTargetOptions(t, yAxisIndex)));
		});

		// Merge duplicities
		targetOptions = this.mergeDuplicities(targetOptions);

		// Set softMin and softMax by targets
		this.setSoftMinMax(targetOptions, chartOptions);

		// Create chart options annotation structure, if needed
		const annotations = chartOptions.annotations ?? [];
		chartOptions.annotations = annotations;
		let annotation = annotations[annotations.length - 1];
		if (!annotation) {
			annotation = {};
			annotations.push(annotation);
		}
		const labels = annotation.labels ?? [];
		annotation.labels = labels;
		const shapes = annotation.shapes ?? [];
		annotation.shapes = shapes;
		this.setZIndex(annotation);
		annotation.draggable = '';

		// Add annotations and plotBands to chart
		targetOptions.forEach(t => {
			switch (t.where) {
				case "annotation": {
					labels.push(t.label);
					shapes.push(t.options);
					break;
				}
				case "plotBandX": {
					const xAxis = chartOptions.xAxis[0] ?? {};
					chartOptions.xAxis[0] = xAxis;
					const xPlotBands = xAxis.plotBands ?? [];
					xAxis.plotBands = xPlotBands;
					xPlotBands.push(t.options);
					break;
				}
				case "plotBandY": {
					const yAxis = chartOptions.yAxis[t.yAxis] ?? {};
					chartOptions.yAxis[t.yAxis] = yAxis;
					const yPlotBands = yAxis.plotBands ?? [];
					yAxis.plotBands = yPlotBands;
					yPlotBands.push(t.options);
					break;
				}
				default: {
					// do nothing
				}
			}
		});
	}

	private createPlotBand(range: [number, number], target: Target): XAxisPlotBandsOptions | YAxisPlotBandsOptions {
		return {
			from: range[0] ?? -Infinity,
			to: range[1] ?? Infinity,
			color: target.color ?? "#EFEFFF",
			label: {
				text: target.label,
				style: {
					color: "#999999"
				},
			},
			zIndex: target.zIndex ?? -2,
			// @ts-ignore
			custom: {
				target: target
			}
		};
	}

	private createLineAnnotation(target: Target, yAxisIndex: number): AnnotationsShapesOptions {

		return {
			points: [
				function (annotation: Annotation & { chart: Chart }) {
					return {
						x: target.from ?? annotation.chart.xAxis[0].min - 1, // -1 due full wide of chart
						y: target.min ?? annotation.chart.yAxis[0].min,
						xAxis: 0,
						yAxis: yAxisIndex,
					}
				},
				function (annotation: Annotation & { chart: Chart }) {
					return {
						x: target.to ?? annotation.chart.xAxis[0].max + 1, // +1 due full wide of chart
						y: target.max ?? annotation.chart.yAxis[0].max,
						xAxis: 0,
						yAxis: yAxisIndex,
					}
				},
			],
			fill: target.color ?? "red",
			strokeWidth: 2,
			stroke: target.color ?? "red",
			type: 'path',
			// @ts-ignore
			custom: {
				target: target
			}
		};
	}

	private createLineAnnotationLabel(target: Target, yAxisIndex: number): AnnotationsLabelsOptions {
		return {
			text: target.label,
			shape: 'connector',
			point: function (annotation: Annotation & { chart: Chart }) {
                // connect to x-axis value if "from" is defined, otherwise set x-axis to null and use pixel coordinates
                const xAxis = typeof target.from === "number" ? 0 : null; // null => px unit
                const x = Math.max(target.from ?? 0, annotation.chart.xAxis[0].min); // 0px => pull point to left

                const y = Math.min(target.max ?? annotation.chart.yAxis[0].max, annotation.chart.yAxis[0].max);
                const yAxis = yAxisIndex;
                return {x, y, xAxis, yAxis};
			},
			y: 0,
			align: 'left',
			verticalAlign: 'bottom',
			style: {
				color: target.color ?? "red",
				textOutline: '1px white',
			},
			borderWidth: 0,

			// @ts-ignore
			custom: {
				target: target
			}
		};
	}

	private createAreaAnnotation(target: Target, yAxisIndex: number): AnnotationsShapesOptions {
		const min = a => target.min ?? a.chart.yAxis[0].min;
		const max = a => target.max ?? a.chart.yAxis[0].max;
		const from = a => target.from ?? a.chart.xAxis[0].min;
		const to = a => target.to ?? a.chart.xAxis[0].max;

		return {
			points: [
				function (annotation) {
					return {
						x: from(annotation),
						y: max(annotation),
						xAxis: 0,
						yAxis: yAxisIndex,
					}
				},
				function (annotation) {
					return {
						x: to(annotation),
						y: max(annotation),
						xAxis: 0,
						yAxis: yAxisIndex,
					}
				},
				function (annotation) {
					return {
						x: to(annotation),
						y: min(annotation),
						xAxis: 0,
						yAxis: yAxisIndex,
					}
				},
				function (annotation) {
					return {
						x: from(annotation),
						y: min(annotation),
						xAxis: 0,
						yAxis: yAxisIndex,
					}
				}
			],
			fill: target.color ?? "#EFEFFF",
			strokeWidth: 0,
			type: 'path',
			// @ts-ignore
			custom: {
				target: target
			}
		};
	}

	private createAreaAnnotationLabel(target: Target, yAxisIndex: number): AnnotationsLabelsOptions {
		return {
			text: target.label,
			shape: 'connector',
			point: function (annotation: Annotation & { chart: Chart }) {
				return {
					x: ((target.from ?? annotation.chart.xAxis[0].min) + (target.to ?? annotation.chart.xAxis[0].max)) / 2,
					y: target.max ?? annotation.chart.yAxis[0].max,
					xAxis: 0,
					yAxis: yAxisIndex,
				}
			},
			y: 0,
			align: 'center',
			verticalAlign: 'top',
			style: {
				color: target.color ?? "red",
				textOutline: '1px white',
			},
			borderWidth: 0,

			// @ts-ignore
			custom: {
				target: target
			}
		};
	}

	private createTargetOptions(target: Target, yAxisIndex: number): TargetOptions {

		const max = target.max ?? null;
		const min = target.min ?? null;
		const to = target.to ?? null;
		const from = target.from ?? null;

		if (target.type === "line") {

			return {
				where: "annotation",
				target: target,
				yAxis: yAxisIndex,
				options: this.createLineAnnotation(target, yAxisIndex),
				label: this.createLineAnnotationLabel(target, yAxisIndex)
			};
		}
		if (target.type === "area") {
			if (min === null && max === null) {
				return {
					where: "plotBandX",
					target: target,
					yAxis: yAxisIndex,
					options: this.createPlotBand([from, to], target),
				};
			}
			if (from === null && to === null) {
				return {
					where: "plotBandY",
					target: target,
					yAxis: yAxisIndex,
					options: this.createPlotBand([min, max], target),
				};
			}

			return {
				where: "annotation",
				target: target,
				yAxis: yAxisIndex,
				options: this.createAreaAnnotation(target, yAxisIndex),
				label: this.createAreaAnnotationLabel(target, yAxisIndex)
			};
		}
		return {
			where: "nowhere",
			target: target,
		};
	}

	private equalPositionTargets(a: Target, b: Target): boolean {
		return a.max === b.max && a.min === b.min && a.to === b.to && a.from === b.from;
	}

	private mergeTargets(targets: Target[]): Target {
		const target: Target = {color: "", label: "", type: undefined};
		for (const prop in targets[0]) {
			target[prop] = targets[0][prop];
		}
		target.label = targets.map(t => t.label).join(', ');
		return target;
	}

	private mergeDuplicities(targetOptions: TargetOptions[]): TargetOptions[] {
		const result: TargetOptions[] = [];
		const usedIndexes: true[] = [];
		targetOptions.forEach((to1, i) => {
			if (to1.where === 'nowhere') {
				return;
			}
			const duplicities = [];
			if (!usedIndexes[i]) {
				duplicities.push(to1);
				usedIndexes[i] = true;
			}
			targetOptions.forEach((to2, j) => {
				if (!usedIndexes[j] && to2.where !== 'nowhere' && this.equalPositionTargets(to1.target, to2.target)) {
					duplicities.push(to2);
					usedIndexes[j] = true;
				}
			});
			if (duplicities.length >= 2) {
				result.push(this.createTargetOptions(this.mergeTargets(duplicities.map(to => to.target)), to1.yAxis));
			} else if (duplicities.length === 1) {
				result.push(to1);
			}
		});
		return result;
	}

	private setSoftMinMax(targetOptions: TargetOptions[], chartOptions): void {
		let minis = [], maxis = [];
		targetOptions.forEach(to => {
			if (to.where !== 'nowhere') {
				const min = minis[to.yAxis] ?? Infinity;
				const max = maxis[to.yAxis] ?? -Infinity;
				minis[to.yAxis] = (to.target.min ?? Infinity) < min ? to.target.min : min;
				maxis[to.yAxis] = (to.target.max ?? -Infinity) > max ? to.target.max : max;
			}
		});

		if (Array.isArray(chartOptions.yAxis)) {
			chartOptions.yAxis.forEach((yAxis, i) => {
				yAxis.softMin = yAxis.softMin ?? minis[i] ?? null;
				yAxis.softMax = yAxis.softMax ?? maxis[i] ?? null;
			});
		}
	}

	/**
	 * Set zIndex of whole annotation layer to the zIndex maximum from targets
	 */
	private setZIndex(annotation): void {
		const dataTargets = [...(this.data.targets ?? [])];
		this.data.datasets.forEach(d => {
			d.targets?.forEach(t => dataTargets.push(t));
		});
		const max = Math.max(...dataTargets.map(t => t.zIndex).filter(zIndex => typeof zIndex === "number"));
		if (max !== -Infinity) {
			annotation.zIndex = max;
		} else {
			annotation.zIndex = 6;
		}
	}

	private findValueFormatter(chartOptions): ValueFormatter | undefined {
		return chartOptions.series[0]?.data[0]?.custom.formatter;
	}

	/**
	 * Add tooltip to targets (it is not supported by Highcharts)
	 *  1. on load event read annotation labels and shapes, plot-lines and plot-bands
	 *  2. add eventListener to show and hide tooltip
	 *  3. format content of tooltip
	 *   - label
	 *   - guess value from target
	 *   - guess value formatter from data
	 *  4. display tooltip
	 *
	 * @param chartOptions
	 * @private
	 */
	private addTooltips(chartOptions): void {

		const originalLoadEvent = chartOptions.chart?.events?.load;
		const self = this;

		chartOptions.chart ??= {};
		chartOptions.chart.events ??= {};
		chartOptions.chart.events.load = function (e) {

			const chart: Chart = this;
			originalLoadEvent?.call(chart, e);

			let tooltip;
			let tooltipDestroyTimeout;
			let positioner = new CustomTooltipPositioner(2, 0, "left");

			// Save pairs of element and corresponding target
			const elementsTargets: [HTMLElement, Target, boolean][] = [];

			// @ts-ignore Annotation plugin does not provide typing for Chart object
			chart.annotations?.forEach((annotation: Annotation) => {
				// @ts-ignore Use internal highcharts structures
				annotation.labels?.forEach(label => elementsTargets.push([label.graphic.element, label.options.custom.target, false]));
				// @ts-ignore Use internal highcharts structures
				annotation.shapes.forEach(shape => {
					// Find shape element, which can be hovered (line annotation creates visible line and thicker invisible container)
					const element = <HTMLElement>shape.graphic.element;
					const children = Array.from(element.parentElement.children);
					const count = children.length;
					const index = children.indexOf(element);
					const targetElement1 = <HTMLElement>children[index % (count / 2)];
					const targetElement2 = <HTMLElement>children[count / 2 + index % (count / 2)];
					// @ts-ignore Use internal highcharts structures
					elementsTargets.push([targetElement1, shape.options.custom.target, true]);
					elementsTargets.push([targetElement2, shape.options.custom.target, true]);
				});
			});

			const elementsByTargetsLabel: Map<string, [HTMLElement, Target, boolean][]> = new Map();
			elementsTargets.forEach(([element, target, line]) => {
				if (elementsByTargetsLabel.has(target.label)) {
					elementsByTargetsLabel.get(target.label).push([element, target, line]);
				} else {
					elementsByTargetsLabel.set(target.label, [[element, target, line]]);
				}
			});

			// Attach events to annotation elements
			elementsByTargetsLabel.forEach((elementsByTarget) => {
				elementsByTarget.forEach(([element, target]) => {
					element.addEventListener('mouseenter', () => {
						elementsByTarget.forEach(([el, , l]) => el.style.filter = l ? 'brightness(1.7)' : 'brightness(1.1)');
					});
					element.addEventListener('mousemove', e => {
						const vf = self.findValueFormatter(chartOptions);
						let value, formattedValue;
						if (typeof target.min === 'number' && target.min === target.max) {
							value = target.min;
						} else if (vf && typeof target.min === 'number' && typeof target.max === 'number' && target.min !== target.max) {
							formattedValue = vf.format(target.min) + ' - ' + vf.format(target.max);
						}

						clearTimeout(tooltipDestroyTimeout);
						tooltip?.destroy();
						tooltip = undefined;

						const html = formatTooltip({
							color: target.color,
							label: target.label,
							value: value,
							formattedValue: formattedValue,
							formatter: vf,
						});

						const [x, y] = CustomTooltipPositioner.layerXY(e);

						tooltip = createTooltip(chart, html, x, y);
						positioner.place(chart, tooltip, x, y);
					});

					element.addEventListener('mouseleave', () => {
						elementsByTarget.forEach(([el]) => el.style.filter = '');
						tooltipDestroyTimeout = setTimeout(() => {
							tooltip?.destroy();
							tooltip = undefined;
						}, 800);
					});
				});
			});

		}

	}

}
