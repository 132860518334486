import IOptions from "./IOptions";
import {Options, SVGElement as HCSVGElement} from "highcharts";

/**
 * HC extends their own SVGElement with properties
 */
type RuntimeHCSVGElement = HCSVGElement & {
    parentGroup: RuntimeHCSVGElement
};

export default class PlotOptionsSeriesLegendHighlightingOptions implements IOptions {

    options(data: any): Partial<Options> {

        return {
            plotOptions: {
                series: {
                    point: {
                        events: {
                            mouseOver: function (e) {

                                const legendGroup: RuntimeHCSVGElement|undefined = this.series['legendGroup'];

                                if (!legendGroup) {
                                    return;
                                }

                                const elementToHighlight = <SVGElement> legendGroup.element;

                                legendGroup.parentGroup.element.childNodes.forEach((itemGroup: SVGElement) => {
                                    itemGroup.style.opacity = elementToHighlight === itemGroup ? '1' : '0.25';
                                });
                            },

                            mouseOut: function (e) {
                                const legendGroup: RuntimeHCSVGElement|undefined = this.series['legendGroup'];

                                legendGroup?.parentGroup.element.childNodes.forEach((itemGroup: SVGElement) => {
                                    itemGroup.style.opacity = '1';
                                });
                            }
                        }
                    }
                }
            }
        }

    }

}
