import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import ChartConstants from "../../../shared/highcharts/ChartConstants";
import TDataRangeChart from "../types/TDataRangeChart";

export default class RangeChartOptions implements IOptions {

    options(data: TDataRangeChart): Partial<Options> {

        return {
            chart: {
                inverted: true,
                plotBorderWidth: 0,
                backgroundColor: ChartConstants.PLOT_BACKGROUND,
            },
        }
    }

}
