import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import Highcharts from 'highcharts';
import BaseHighchartsComponent from "../../BaseHighchartsComponent";
import transformSankeyDiagramData from "./transformSankeyDiagramData";
import TDataSankeyDiagram from "./TDataSankeyDiagram";

import "./sankeyOrderNodes";

class SankeyDiagram extends BaseHighchartsComponent {

    public onUpdate(data: TDataSankeyDiagram): void {
        this.chart?.destroy();
        const chartOptions = transformSankeyDiagramData(data);

        // Delayed drawing of chart to get real chart width
        //Highcharts.chart(this._$element.find('[data-chart]')[0], chartOptions);
        setTimeout(() => {
            this.chart = Highcharts.chart(this._$element.find('[data-chart]')[0], chartOptions)
        }, 1);
    }


    protected toggleDataLabels() {
        // do nothing
    }
}

ComponentManager.registerComponentClass('SankeyDiagram', SankeyDiagram);
