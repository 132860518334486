import IModifier from "../../../shared/highcharts/modifiers/IModifier";
import {Options} from "highcharts";
import ValueFormatter from "../../../../model/ValueFormatter";
import DrawImageModifier from "../../../shared/highcharts/modifiers/DrawImageModifier";
import TDataPieChart from "../types/TDataPieChart";
import CustomTooltipModifier from "../../../shared/highcharts/modifiers/CustomTooltipModifier";
import {TLowBase} from "../../../../Board/src/model/TBoardOptions";
import {formatTooltip} from "../../../shared/highcharts/formatters";

export default class PreviousDatasetPieModifier implements IModifier {

    constructor(
        private readonly data: TDataPieChart,
        private readonly lowBase: TLowBase,
    ) { }

    modify(chartOptions: Options) {
        let previousDatasetHtml, previousDatasetIcon;

        if (this.data.totalDatasets?.[0]?.data[0] && this.data.previousTermDatasets?.[0]?.data[0]) {
            ( () => {
				if (['filter', 'hide'].includes(this.lowBase.mode) && this.lowBase.threshold > this.data.previousTermDatasets[0].data[0].count) {
					return;
				}
				const r = this.data.totalDatasets[0].representation;
				const vf = new ValueFormatter(r?.precision, r?.unitMark, r?.emptyValue, r?.decimalSeparator);
				const value = this.data.totalDatasets[0].data[0].value;
				const prevValue = this.data.previousTermDatasets[0].data[0].value;
				const diffValue = vf.getRoundedValue(value) - vf.getRoundedValue(prevValue);

				previousDatasetIcon = vf.getRoundedValue(diffValue) === 0 ? DrawImageModifier.IMAGE_TRENDING_FLAT : (diffValue < 0 ? DrawImageModifier.IMAGE_TRENDING_DOWN : DrawImageModifier.IMAGE_TRENDING_UP);
				previousDatasetHtml = formatTooltip({
					label: this.data.categories[0],
					value: value,
					count: this.data.totalDatasets[0].data[0].count,
					representation: this.data.totalDatasets[0].representation,
					isLowBase: this.lowBase.threshold > this.data.totalDatasets[0].data[0].count,
					previous: {
						value: prevValue,
						count: this.data.previousTermDatasets[0].data[0].count,
						representation: r,
						isLowBase: this.lowBase.threshold > this.data.previousTermDatasets[0].data[0].count,
					}
				});
			})();
        }

        if (previousDatasetHtml && previousDatasetIcon) {
            (new CustomTooltipModifier(previousDatasetHtml, chart => chart['customImage'])).modify(chartOptions);
            (new DrawImageModifier(previousDatasetIcon, 'center', '57%', 40, 40)).modify(chartOptions);
        }
    }


}