import IOptions from "./IOptions";
import {Axis, Options, Series, SVGElement} from "highcharts";

/**
 * Hide / show associated total plot lines with series
 * @see transformBarChartPointToPlotLine()
 */
export default class PlotOptionsSeriesVisibilityOfAssociatedPlotLines implements IOptions {

	private setVisibilityOfPlotLines(serie: Series, visibility: boolean): void {
        // @ts-ignore Read
        if (!serie.options.custom) {
            return;
        }
        // @ts-ignore Read
        const index: number = serie.options.custom.index;
		const yAxis: Axis = serie.chart.yAxis[0];

		// @ts-ignore
		yAxis.plotLinesAndBands?.forEach((plotLine => {
			if (plotLine.id === '__total_' + index) {
				const element: SVGElement = plotLine.svgElem;
				element.element.style.visibility = visibility ? '' : 'hidden';
			}
		}));
	}

	options(data: any): Partial<Options> {
		const self = this;

		return {
			plotOptions: {
				series: {
					events: {
						show: function () {
							self.setVisibilityOfPlotLines(this, true);
						},
						hide: function () {
							self.setVisibilityOfPlotLines(this, false);
						},
					}
				}
			}
		}
	}

}