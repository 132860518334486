import PrintButton from "./PrintButton";

export default class PrintiloFillLink extends PrintButton {

    private readonly target: string;

    constructor(
        $element: JQuery,
    ) {
        super($element);

        this.target = this.$element.data('target');
        if (!this.target) {
            console.warn(`FillPdfLink: attribute data-target missing.`)
        }

        if (!this.$attachment.length) {
            console.warn(`FillPdfLink: target '${this.target}' does not exist or not include attachment input field.`)
        }
    }

    private get $attachment(): JQuery {
        return $(this.target+' input[name="attachment"]');
    }

    init(): void {
        this.$element.on('click', async () => {
            try {
                const url = await this.printAction.getPdfUrl();
                this.$attachment.val(url);
            } catch (e) {
                console.error(e);
            }
            this.$attachment.trigger('change');
        });
    }

}
