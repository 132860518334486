import Board from "../Board";
import FilterList from "../model/FilterList";
import FilterFactory from "../model/FilterFactory";
import m, {Vnode} from "mithril";
import IFilterComponent from "./IFilterComponent";
import {TFilterSettings, TFilterState} from "../model/filterTypes";
import FilterModal from "./filterItems/FilterModal";
import SearchFilter from "./SearchFilter";
import translator from "../../../translator";

export default class Filter {

    public static CHANGE_EVENT = "filter:change";

    // state
    private loading = true;
    private delayingChange;

    // model
    private filterList?: FilterList;
    public settings?: TFilterSettings;

    // components
    public readonly groups = [];
    public readonly items: IFilterComponent[] = [];
    public search: SearchFilter|null = null;
    public modal: FilterModal|null = null;

    constructor(
        public readonly board: Board,
        onLoadStructure?: (filter: Filter)=>any,
        onLoadState?: (filter: Filter)=>any,
    ) {
        this.board.api.loadFilterStructure().then(
            structure => {
                this.settings = structure.filterStructure.settings;
                this.search = this.settings.optionSearchEnabled ? new SearchFilter(this) : null;
                this.filterList = new FilterList(structure.filterStructure, new FilterFactory(this));

                onLoadStructure?.(this);
                m.redraw();

                this.loadState(onLoadState);
            },
            reason => {
                console.error('Filter structure loading fail.', reason)
            }
        );
    }

    /**
     * Load / reload filter state from backend
     * @param onLoadState
     */
    public loadState( onLoadState?: (filter: Filter)=>any ): void {
        this.loading = true;

        this.board.api.loadFilterState().then(
            state => {
                this.filterList.setState(state.filterState);
                this.loading = false;
                if (this.settings?.initialDataRequestEnabled) {
                    this.triggerChange();
                }
                onLoadState?.(this);
                m.redraw();
            },
            reason => {
                console.warn('Filters state (default values) loading failed.', reason);
                this.loading = false;
            }
        );
    }

    // API
    public list(): FilterList|undefined {
        return this.filterList;
    }
    public getData(): TFilterState|undefined {
        const state = this.filterList?.getState();
        if (state?.shortenedPeriod) {
            state.periods = [state.shortenedPeriod]; // replace list of all checked periods with one period
        }
        return state;
    }

    // view methods
    public view(): Vnode|Vnode[] {
        return this.loading ? this.viewLoading() : [
            this.viewSearch(),
            this.viewSidebar(),
            this.viewModal(),
        ];
    }

    private viewLoading(): Vnode {
        return m('.loading');
    }

    private viewSearch(): Vnode|null {
        return this.search ? m(this.search) : null;
    }

    public viewSidebar(): Vnode {
        return m('.tree', {}, [
                ...this.filterList.filters.map(f => f.viewSidebar()),
                m('.filter-reset', {
                    'data-ga': 'filter-resetFilters'
                }, [
                    m('a', {
                        href: "javascript:void(0)",
                        onclick: (e: MouseEvent) => {
                            e.preventDefault();
                            this.reset();
                        }
                    }, translator.translate('reset_filters'))
                ]),
        ]);
    }

    public viewModal(): Vnode|null {
        return this.modal ? m(this.modal) : null;
    }

    public resetOptions(options: string|string[]): void {
        options = Array.isArray(options) ? options : [options];

        options.map(o => this.filterList.optionsById[o])
            .filter(vd => vd)
            .forEach(vd => this.filterList.setOptionStateTree(vd, true));

        this.filterList.filters.forEach(f => f.onFilterLoadState());
        m.redraw();
        this.triggerChange();
    }

    public resetPeriod(): void {
        this.filterList.clearPeriods();
        const lastPeriod = this.filterList.periods.slice(-1)[0];
        if (lastPeriod) {
            this.filterList.setPeriodState(lastPeriod.id, true);
        }

        m.redraw();
        this.triggerChange();
    }

    public reset(): void {
        this.filterList.filters.forEach((f) => {
            f.reset?.();
        });
        this.search?.reset();
        m.redraw();
        this.triggerChange();
    }

    public triggerChange(delay = 600): void {
        if (this.delayingChange) {
            clearTimeout(this.delayingChange);
        }
        this.delayingChange = setTimeout( () => {
            this.board.$element.trigger(Filter.CHANGE_EVENT, this.filterList?.getState());
            setTimeout(() => this.refreshFilterData(), 300); // Better to call after receive components data, then timeout.
        }, delay);
    }

    private refreshFilterData(): void {
        this.filterList.setFilterData([]);
        m.redraw();
        this.board.api.loadFilterData().then(
            data => {
                this.filterList.setFilterData(data.filterData);
                m.redraw();
            },
            reason => {
                console.warn('Filter data loading failed.', reason);
            }
        );
    }

}
