import m, {Vnode} from "mithril";
import {TFilterValueCheckbox} from "../../../model/filterTypes";
import FilterItemCheckbox from "../FilterItemCheckbox";

export default class OptionCount {
    constructor(
        private readonly filterItem: FilterItemCheckbox,
        private readonly definition: TFilterValueCheckbox,
    ) { }

    view(): Vnode|null {
        const optionData = this.filterItem.filter.list()?.getOptionData(this.definition.id);
        if (!optionData) {
            return null;
        }

        const lowBase = this.filterItem.filter.board.options.settings.lowBase;

        let lowBaseClass = '';
        if (lowBase?.threshold && optionData.count < lowBase.threshold) {
            lowBaseClass = '.lowBase'
        }

        return m('span.count'+lowBaseClass, {}, ''+optionData.count);
    }
}