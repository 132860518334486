import iziToast from "izitoast";
import INetteExtension from "../../INetteExtension";

export default class IzitoastExtension implements INetteExtension {
    init(): void {
        iziToast.settings({
            resetOnHover: true,
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
        });

        window.iziToast = iziToast;
    }
}
