import m, {Vnode} from "mithril";
import {TFilterValueFavourite} from "../../../model/filterTypes";
import FilterItemFavourite from "../FilterItemFavourite";

export default class SidebarItemFavourite {

    public removing: boolean = false;

    constructor(
        private readonly filterItem: FilterItemFavourite,
        public readonly definition: TFilterValueFavourite,
    ) { }

    public onSearch(query: string): void {
        // TODO
    }

    view(): Vnode|null {
        const cssClass = '.FilterItem.FilterItem-Favourite.opened' + (this.removing?'.removing':'');
        return m(cssClass, {}, [
            m('.item', {
                tabindex: "1",
                onclick: (e: MouseEvent) => {
                    e.stopPropagation();
                    this.filterItem.usePreset(this.definition);
                }
            }, [
                m('div.item-label', {}, [
                    m('label', {}, this.definition.label),
                ]),
                m('.control', {}, [
                    m('i.icon.ico-delete', {
                        onclick: (e: MouseEvent) => {
                            e.stopPropagation();
                            this.removing = true;
                            this.filterItem.removePreset(this.definition);
                        }
                    }),
                ]),
            ])
        ]);
    }
}
