import DashboardComponent from "nms-dashboard-js/src/DashboardComponent";
import ComponentManager from "nms-dashboard-js/src/ComponentManager";

class ClientSpecificCsobPositiveFeedbackEmail extends DashboardComponent {
    init() {
        super.init();

        this._$element.find('form').each((i, form) => {
            window.Nette?.initForm(form);
        });
    }
}

ComponentManager.registerComponentClass('ClientSpecificCsobPositiveFeedbackEmail', ClientSpecificCsobPositiveFeedbackEmail);