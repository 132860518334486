import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import Highcharts from 'highcharts';
import BaseHighchartsComponent from "../../BaseHighchartsComponent";
import TDataLineChart from "./TDataLineChart";
import transformLineChartData from "./transformLineChartData";

class LineChart extends BaseHighchartsComponent {


    public onUpdate(data: TDataLineChart): void {
        this.chart?.destroy();
        const chartOptions = transformLineChartData(data);
        this.chart = Highcharts.chart(this._$element.find('[data-chart]')[0], chartOptions);
    }
}

ComponentManager.registerComponentClass('LineChart', LineChart);
