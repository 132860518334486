import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import ValueFormatter from "../model/ValueFormatter";

export default class VarValue extends DashboardComponent
{
    init() {
        this.$value = this._$element.find('.var-value-value').first();
        this.$count = this._$element.find('.var-value-count').first();
        this.$diffValue = this._$element.find('.var-value-diff .value').first();
        this.$previousPeriod = this._$element.find('.var-value-diff .period').first();
    }

    onUpdate(data) {
        this._setData(data);
    }

    _setData(data) {
        const {value, count, diffValue, previousPeriod, valueStyle, countStyle, unitMark, precision} = data;
        const formatter = new ValueFormatter(precision, unitMark);

        this.$value.text(formatter.format(value)).attr('style', valueStyle || '');
        this.$count.text('n = '+count).attr('style', countStyle || '');

        this.$diffValue.text((diffValue>0?'+':'') + formatter.format(diffValue))
            .removeClass('text-success').removeClass('text-danger')
            .addClass(diffValue>0?'text-success':'').addClass(diffValue<0?'text-danger':'');

        this.$previousPeriod.text(previousPeriod);
    }


}

ComponentManager.registerComponentClass('VarValue', VarValue);