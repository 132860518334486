import Highcharts from "highcharts";

// @ts-ignore Sankey already loaded 🤞
const { createNodeColumns } = Highcharts.seriesTypes.sankey.prototype;

// @ts-ignore Sankey already loaded
Highcharts.seriesTypes.sankey.prototype.createNodeColumns = function (...args) {
    const columns = createNodeColumns.apply(this, args);

    columns.forEach(column => {
        column.sort((a, b) => {
            const { order: aOrder = 0 } = a.options;
            const { order: bOrder = 0 } = b.options;
            return aOrder - bOrder;
        });
    });

    return columns;
};
