import {TDatasetBar} from "../types/TDataBarChart";
import {SeriesBarOptions} from "highcharts";
import {createValueFormatterFromDataset} from "../../../shared/highcharts/formatters";
import ChartColors from "../../../../../../js/utility/ChartColors";
import transformBarChartPoint from "./transformBarChartPoint";
import LegendHoverHighlightsTotalLine from "../modifiers/LegendHoverHighlightsTotalLine";

export default function transformBarChartDataset(
    dataset: TDatasetBar,
    backendDatasetIndex: number,
    color: string|string[],
    categories: string[]
): SeriesBarOptions {
	const formatter = createValueFormatterFromDataset(dataset);

	const points = [];

	// Total data points
	if (dataset.displayDataTotalAs !== 'line') {
		dataset.dataTotal.forEach((d, i) => {
			const point = transformBarChartPoint(
				d,
				i,
				categories[i] ?? '',
				dataset.colorTotal || ChartColors.pickColor(color, i, ChartColors.COLOR_TOTAL),
				formatter
			);
			points.push(point);
		});
	}

	// Common data points
	dataset.data.forEach((d, i) => {
		const point = transformBarChartPoint(
			d,
			i,
			categories[dataset.dataTotal.length + i] ?? '',
			ChartColors.pickColor(color, i) || '#909090',
			formatter);
		points.push(point);
	});

	// @ts-ignore
	return {
		name: dataset.label,
		data: points,
		custom: {
			index: backendDatasetIndex,
			dataset: dataset,
		},
		color: ChartColors.pickColor(color, 0, '#909090'),
		yAxis: dataset.targetAxis ?? undefined,
		stack: dataset.groupId,
		zIndex: 3,
		events: {
			mouseOver: function () {
				LegendHoverHighlightsTotalLine.highlight(this);
			},
			mouseOut: function () {
				LegendHoverHighlightsTotalLine.dismissHighlight(this.chart);
			},
		}
	};
}