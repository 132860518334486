// Example: sticky searchbar in sidebar
// <div class="sidebar" data-sticky-child="">

import ClassicExtension from "../../ClassicExtension";

export default class StickyChildElementExtension extends ClassicExtension {

    attach($root: JQuery) {

        $root.find('[data-sticky-child]').each((i, parent) => {
            const $parent = $(parent)

            let lastChangeOnScroll = 0;
            $parent.on('scroll', e => {
                const scrollOfParent = parent.scrollTop;
                const stickyElement = $parent.find($parent.data('sticky-child')).first();
                if (!stickyElement.length) {
                    return;
                }
                const stickyElementOffset = stickyElement[0].offsetTop;
                const stickyElementHeight = stickyElement[0].offsetHeight;

                if (scrollOfParent >= stickyElementOffset+stickyElementHeight) {
                    lastChangeOnScroll = scrollOfParent;
                    stickyElement.addClass("stick-to-top");
                } else if (scrollOfParent < lastChangeOnScroll-stickyElementHeight) {
                    lastChangeOnScroll = 0;
                    stickyElement.removeClass("stick-to-top");
                }

            });
        });
    }

}
