import _ from 'lodash';
import TDataLineChart from "./TDataLineChart";
import lineChartDefinition from "./lineChartDefinition";
import ValueFormatter from "../../../model/ValueFormatter";
import {Point, Series, SeriesLineOptions} from "highcharts";
import ChartColors from "../../../../../js/utility/ChartColors";
import {HighchartsRegressionSetting} from "../../../../../js/@types/highcharts-regression";
import ExtendedPointOptionsObject from "../../shared/highcharts/ExtendedPointOptionsObject";
import Color from "color";
import TDataset from "../../shared/highcharts/TDataset";
import LowBaseModifier from "../../shared/highcharts/modifiers/LowBaseModifier";
import UseRegressionModifier from "../../shared/highcharts/modifiers/UseRegressionModifier";
import ChartConstants from "../../shared/highcharts/ChartConstants";
import TRepresentation from "../../shared/highcharts/TRepresentation";
import AddTargetsModifier from "../../shared/highcharts/modifiers/AddTargetsModifier";

type ExtendedSeriesOptions = Partial<SeriesLineOptions> & {
    data: ExtendedPointOptionsObject[],
    regression?: boolean,
    regressionSettings?: HighchartsRegressionSetting
}

// Get color for dataset from given data or from default color palette
function getColor(dataset: TDataset, i: number): string {
    if (dataset.color) {
        return typeof dataset.color === 'string' ? dataset.color : dataset.color[0];
    } else {
        const index = typeof dataset.index === 'number' ? dataset.index - 1 : i;
        return ChartColors.getColorByIndex(index);
    }
}

function transformDataset(dataset, color: string): ExtendedSeriesOptions {
	const r: TRepresentation = dataset.representation;
	const formatter = new ValueFormatter(r?.precision, r?.unitMark, r?.emptyValue, r?.decimalSeparator);

    return {
        name: dataset.label,
        data: dataset.data.map( (d, i) => {
            return {
                y: d.value,
                x: i,
                custom: {
                    formatter: formatter,
                    count: d.count,
                    link: d.link,
                    value: d.value,
                },
                marker: {
                    enabled: true,
                    fillColor: color,
                    lineColor: color,
                    lineWidth: 2,
                },
            }
        }),
        color: color,
        zIndex: dataset.zIndex,
        yAxis: dataset.targetAxis ?? undefined,
        regression: false,
        regressionSettings: {
            name: '',
            hideInLegend: true,
            type: dataset.trend,
            color: color,
            dashStyle: 'dash',
            lineWidth: 1,
            regressionSeriesOptions: {
                enableMouseTracking: false,
            }
        },
    }
}

export default function transformLineChartData(data: TDataLineChart) {
    const lowBase = LowBaseModifier.loadLowBase(data);

    // SERIES
    let series = data.datasets.map((dataset, i) => {
        const color = getColor(dataset, i);
        return transformDataset(dataset, color);
    })
    if (data.totalDatasets) {
        data.totalDatasets.reverse().forEach((dataset) => {
            series.unshift(transformDataset(dataset, dataset.color ? getColor(dataset, 0) : ChartColors.COLOR_TOTAL));
        })
    }
    series = series.filter(s => s.data.some(v => v !== null)); // remove empty datasets
    series.forEach( (s, i) => s.regressionSettings.name = '__regression_'+i);


    // HELPERS
    const pointValueFormatter = function(point: ExtendedPointOptionsObject) {
        const vf = point.custom?.formatter;
        return vf ? vf.format(point.y) : point.y;
    };

    const getOverlappingPoints = (point: Point, includeSelf = true) => {
        const points = point.series.chart.series.filter(s => s.visible)
            .map(s => s.points.filter(p => p.category === point.category)[0]) // points in one column (by category)
            .filter(p => p && p['custom']) // filter undefined values and regression points
            .filter(p => p.y === point.y && p !== point); // got points with same y position
        if (includeSelf) {
            points.unshift(point);
        }
        return points;
    }


    // FORMATTERS
    const pointDataLabelFormatter = function() {
        if (String(this.series.name).indexOf("__regression_") === 0) {
            return; // skip regression line
        }
        const point: ExtendedPointOptionsObject  = this.point;
        let value = pointValueFormatter(point)
        value = value ? value+', ' : '';
        const count = 'n='+point.custom.count;
        const countColor = point.custom.isLowBase ? 'color:red' : '';
        return `${value}<span style="${countColor}">${count}</span>`;
    };

    const pointsTooltipFormatter = function() {
        const overlappingPoints: Point[] = getOverlappingPoints(this.point);
        if (!overlappingPoints.length) {
            return false;
        }

        return overlappingPoints.map( (p: ExtendedPointOptionsObject) => {
            let value = pointValueFormatter(p);
            value = value ? value+', ' : '';
            const count = 'n='+p.custom.count;
            const countColor = p.custom.isLowBase ? 'color:red' : '';
            return `<span style="color:${p.color}">●</span> <span>${value}</span><span style="${countColor}">${count}</span>`;
        }).join('<br />');

    };

    const tickValueFormatter = function() {
        const vf = series[this.axis.series[0].index]?.data[0]?.custom.formatter;
        return vf ? vf.format(this.value, null) : this.value;
    };


    // EVENTS
    const onMouseOverPoint = function (e: {target: Point, preventDefault: ()=>void, type: "mouseOver"}) {
        if (!this.series.legendGroup) {
            return;
        }

        const itemsToHighlight: SVGElement[] = getOverlappingPoints(e.target).map((p) => {
            // @ts-ignore
            return p.series.legendGroup?.element;
        });

        this.series.legendGroup.parentGroup.element.childNodes.forEach((itemGroup) => {
            itemGroup.style.opacity = itemsToHighlight.includes(itemGroup) ? '1' : '0.25';
        });

    }

    const onMouseOutPoint = function (/*e: {target: Point, preventDefault: ()=>void, type: "mouseOut"}*/) {
        this.series.legendGroup?.parentGroup.element.childNodes.forEach((itemGroup) => {
            itemGroup.style.opacity = '1';
        });
    }

    // CHART OPTIONS
    const chartOptions = _.merge(lineChartDefinition(), {
        series: series,
        xAxis: {
            categories: data.categories,
        },
        yAxis: [{
            labels: {
                formatter: tickValueFormatter,
            },
        }],
        tooltip: {
            formatter: pointsTooltipFormatter,
        },
        plotOptions: {
            series: {
                dataLabels: {
                    formatter: pointDataLabelFormatter,
                },
                events: {
                    legendItemClick: function (e) {
                        const clickEvent: PointerEvent = e.browserEvent;
                        if (clickEvent.metaKey || clickEvent.ctrlKey) {
                            this.chart.series.forEach(s => s.hide());
                        } else if (clickEvent.altKey) {
                            this.chart.series.forEach(s => s.show());
                        } else if (clickEvent.shiftKey) {
                            this.chart.series.forEach(s => s.visible ? s.hide() : s.show());
                        }

                        // Hide / show regression of clicked series
                        const series: Series[] = this.chart.series;
                        series
                            .filter(s => s.name === '__regression_'+this.index)
                            .forEach(s => this.visible ? s.hide() : s.show());

                    },
                },
                point: {
                    events: {
                        mouseOut: onMouseOutPoint,
                        mouseOver: onMouseOverPoint,
                    },
                },
            },
        },

    }, data.chart);


    // MODIFIERS
	const lowBasePointCallback = ['filter', 'hide'].includes(lowBase.mode) ? LowBaseModifier.hideCallback : (p) => {
		const lowBaseColor = Color(p.lineColor).mix(new Color('white'), 1 - ChartConstants.LOW_BASE_OPACITY_LINE).rgb().string();
		p.marker.fillColor = lowBaseColor;

	};
	const lowBaseOptions = {
		pointCallback: lowBasePointCallback,
		removeDatasets: true,
	};
	(new LowBaseModifier(lowBase, lowBaseOptions)).modify(chartOptions);

	(new AddTargetsModifier(data)).modify(chartOptions);

	(new UseRegressionModifier()).modify(chartOptions);

	return chartOptions;
}
