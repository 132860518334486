export default class Messages {
    constructor(
        private messages: {[key: string]: string}
    ) { }

    public setMessages(messages: {[key: string]: string}): void {
        this.messages = messages;
    }

    public get(message: string, replace?: {[key: string]: string}): string {
        replace = typeof replace === 'object' ? replace : {};
        let translated = this.messages[message] || message;
        for (const key in replace) {
            translated = translated.replace(`{${key}}`, replace[key]);
        }
        return translated;
    }
}