import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';

type TAlert = {
    type: string,
    count: number,
    link: string|null,
};

export default class AlertSummary extends DashboardComponent
{
    private alertTemplate: string;
    private alertTemplate2: string;
    private alertTemplate5: string;
    private $infotaiment: JQuery;

    init() {
        this.$infotaiment = this._$element.find('.card-body').first();
        this.alertTemplate = this._$element.data('alert') || '';
        this.alertTemplate2 = this._$element.data('alert2') || '';
        this.alertTemplate5 = this._$element.data('alert5') || '';
    }

    onUpdate(data: {alerts?: TAlert[]}) {

        data.alerts?.forEach(alert => {
            this._$element.addClass('alert-'+alert.type);
            this.setAlert(alert);
        });

    }

    private setAlert(alert: TAlert) {
        const template = this._chooseTemplate(alert.count);
        const msg = template.replace('{type}', alert.type)
            .replace('{count}', (Number(alert.count)).toLocaleString())
            .replace('{link_begin}' ,alert.link ? `<a href="${alert.link}">` : '')
            .replace('{link_end}' ,alert.link ? `</a>` : '');

        this.$infotaiment.html(`<div class="label"><i class="icon ico-alert-circle"></i>${msg}</div>`);
    }

    _chooseTemplate(count) {
        if (count === 1) {
            return this.alertTemplate;
        } else if (count > 1 && count < 5) {
            return this.alertTemplate2;
        } else {
            return this.alertTemplate5;
        }
    }
}

ComponentManager.registerComponentClass('AlertSummary', AlertSummary);