export default class DocumentUploader {
    constructor(
        public readonly $element: JQuery,
        public readonly name: string,
        private readonly options: any
    ) {
        this.init();
    }

    init() {
        const $submitButton = this.$element.find('input[type="submit"]');
        const $fileUpload = this.$element.find('input[type="file"]');

        $fileUpload.on('change', () => {
            if ($fileUpload.val()) {
                $submitButton.trigger('click');
                this.$element.addClass('disabled');
            }
        });
    }
}
