import m, {Vnode} from "mithril";
import {TFilterValuePeriod} from "../../../model/filterTypes";
import FilterItemPeriod from "../FilterItemPeriod";

export default class SidebarItemPeriod {

    public readonly subtreeItems: SidebarItemPeriod[] = [];

    private isOpen: boolean = false;

    private static readonly ICONS = {
        checked: "checked-filled",
        unchecked: "checked-empty",
        semichecked: "checked-semi",
        unknown: "check-all",
    }

    constructor(
        private readonly filterItem: FilterItemPeriod,
        public readonly definition: TFilterValuePeriod,
        public readonly parent: SidebarItemPeriod|null = null,
    ) {
        if (definition.values) {
            this.subtreeItems = definition.values.map(v => new SidebarItemPeriod(filterItem, v, this));
        }
    }

    onFilterLoadState(): boolean {
        const shortenedPeriodId = this.filterItem.filter.list().getShortenedPeriodState();

        // open if any of condition is true:
        this.isOpen = this.subtreeItems.map(i => i.onFilterLoadState()).some(i => i)        //  - some child is opened
            || this.subtreeItems.some(si => si.definition.id === shortenedPeriodId)         //  - some child is shortened period
            || this.filterItem.getPeriodCheckedState(this.definition) === 'semichecked';    //  - some children are checked and some unchecked

        return this.isOpen;
    }

    view(): Vnode|null {
        const preselect = this.filterItem.isPreselected(this.definition);
        const cssClass = '.FilterItem.FilterItem-Period'
            + (this.showSubTree() ? '.opened' : '.closed')
            + (preselect ? '.preselected-'+preselect : '')
            + (this.definition.id === this.filterItem.filter.list().getShortenedPeriodState() ? '.shortenedPeriod' : '');

        const checkboxState = this.filterItem.getPeriodCheckedState(this.definition);
        return m(cssClass, {}, [
            m('.item', {
                tabindex: "1", // make it focusable
                onclick: (e: MouseEvent) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.isOpen = !this.isOpen;
                }
            }, [
                m('i.icon.ico-'+SidebarItemPeriod.ICONS[checkboxState], {
                    onclick: (e: MouseEvent) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (e.shiftKey) {
                            this.filterItem.extendSelection(this.definition);
                        } else {
                            this.filterItem.setPeriod(this.definition);
                        }
                        this.isOpen = true;
                        this.filterItem.filter.triggerChange();
                    },
                    onpointerenter: () => this.filterItem.setPreselectPeriod(this.definition),
                    onpointerout: () => this.filterItem.setPreselectPeriod(),
                    onpointercancel: () => this.filterItem.setPreselectPeriod(),
                }),
                m('div.item-label', {}, [
                    m('label', {
                        style: this.definition.style || '',
                    }, this.definition.label),
                ]),
                m('.control', {}, [
                    this.subtreeItems.length ? m('i.icon.ico-dropdown', {
                        onclick: (e) => {
                            e.stopPropagation();
                            this.isOpen = !this.isOpen
                        },
                    }) : null,
                ]),
            ]),
            m('.subtree', {}, this.showSubTree() ? this.subtreeItems.map(si => m(si)) : null),
        ]);
    }

    private showSubTree(): boolean {
        return Boolean(this.isOpen && this.subtreeItems.length);
    }

}
