import IModifier from "../../../shared/highcharts/modifiers/IModifier";
import {Chart, Options, Series, SVGElement} from "highcharts";

export default class LegendHoverHighlightsTotalLine implements IModifier {
	modify(chartOptions: Options, callback: (modifiedPeace: any) => void = () => {
	}): void {
		if (!chartOptions.chart) {
			chartOptions.chart = {};
		}

		if (!chartOptions.chart.events) {
			chartOptions.chart.events = {};
		}

		const originalLoadCallback = chartOptions.chart.events.load;

		chartOptions.chart.events.load = function (event) {
			originalLoadCallback?.call(this, event);

			const chart = this;
			this.series.forEach(serie => {

				const legendItem = <SVGElement>(<any>serie).legendItem;
				const legendSymbol = <SVGElement>(<any>serie).legendSymbol;

				legendItem?.element.addEventListener('mouseenter', () => LegendHoverHighlightsTotalLine.highlight(serie));
				legendSymbol?.element.addEventListener('mouseenter', () => LegendHoverHighlightsTotalLine.highlight(serie));

				legendItem?.element.addEventListener('mouseleave', () => LegendHoverHighlightsTotalLine.dismissHighlight(chart));
				legendSymbol?.element.addEventListener('mouseleave', () => LegendHoverHighlightsTotalLine.dismissHighlight(chart));
			});

		}
	}

	private static getLineId(serie: Series): string | void {
		const index = (<any>serie.options).custom?.index;
		if (index !== undefined) {
			return '__total_' + index;
		}
	}

	public static highlight(hoveredSerie: Series): void {
		const chart = hoveredSerie.chart;
		chart.series.forEach(serie => {
			if (serie === hoveredSerie) {
				return;
			}
			const lineId = this.getLineId(serie);
			if (lineId) {
				(<any>chart.yAxis[0]).plotLinesAndBands
					?.filter(line => line.id === lineId)
					.forEach(line => line.svgElem.element.style.opacity = 0.25);
			}
		});
	}

	public static dismissHighlight(chart: Chart): void {
		(<any>chart.yAxis[0]).plotLinesAndBands
			?.filter(line => line.id?.startsWith('__total'))
			.forEach(line => line.svgElem.element.style.opacity = 1);
	}

}
