import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import TDataRangeChart from "../types/TDataRangeChart";

export default class PlotOptionsRangeOptions implements IOptions {
    options(data: TDataRangeChart): Partial<Options> | any {

        return {
            plotOptions: {
                boxplot: {
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    },
                    enableMouseTracking: false,
                    stemWidth: 2,
                    medianWidth: 2,
                    whiskerWidth: 2,
                    whiskerLength: 2,
                    medianColor: '#444444',
                    pointWidth: 2,
                    tooltip: {
                        enabled: false,
                    },
                    animation: false,
                },
                scatter: {
                    stickyTracking: false,
                    tooltip: {
                        enabled: true
                    },
                    dataLabels: {
                        y: 0,
                    },
                    marker: {
                        symbol: 'circle',
                        radius: 5,
                        lineWidth: 2,
                    },
                    showInLegend: false,
                    animation: false,
                }
            }
        }
    }

}
