import ClassicExtension from "../../ClassicExtension";
import scrollToHash from "../../../../../js/utility/scrollToHash";

export default class ScrollToDashboardComponent extends ClassicExtension {
    attach($root: JQuery) {
        // #dc_2_0__pieChart
        $root.find('a[href*="#dc_"]').each((i, anchor) => {
            const hash = (<HTMLAnchorElement>anchor).href.split('#').pop();
            if (!hash.startsWith('dc_')) {
                return;
            }

            $(anchor).on('click', (e) => {
                e.preventDefault();
                scrollToHash(hash);
            })
        });
    }

}