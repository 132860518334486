import IModifier from "./IModifier";
import Highcharts, {Options} from "highcharts";

type TDirection = 'x'|'y';
type TPosition = 'center'|string|number|((chart: Highcharts.Chart, image: Highcharts.SVGElement, dir: TDirection)=>number);
type TSize = 'origin'|string|number|((chart: Highcharts.Chart, image: Highcharts.SVGElement, dir: TDirection)=>number);

export default class DrawImageModifier implements IModifier {

    public static readonly IMAGE_TRENDING_UP = "data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 14L26.29 16.29L21.41 21.17L17.41 17.17L10 24.59L11.41 26L17.41 20L21.41 24L27.71 17.71L30 20V14H24Z' fill='%2366B536 '/%3E%3C/svg%3E%0A";
    public static readonly IMAGE_TRENDING_DOWN = "data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 26L26.29 23.71L21.41 18.83L17.41 22.83L10 15.41L11.41 14L17.41 20L21.41 16L27.71 22.29L30 20V26H24Z' fill='%23E74C3C'/%3E%3C/svg%3E%0A";
    public static readonly IMAGE_TRENDING_FLAT = "data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30 20L26 16V19H11V21H26V24L30 20Z' fill='%23909090'/%3E%3C/svg%3E%0A";

    constructor(
        private readonly imageUrl: string,
        private readonly x: TPosition = 'center',
        private readonly y: TPosition = 'center',
        private readonly width: TSize = 'origin',
        private readonly height: TSize = 'origin',

    ) { }

    modify(chartOptions: Options): void {
        if (!chartOptions.chart) {
            chartOptions.chart = {};
        }

        if (!chartOptions.chart.events) {
            chartOptions.chart.events = {};
        }

        const originalRedrawCallback = chartOptions.chart.events.redraw;
        const self = this;

        let image: Highcharts.SVGElement;
        chartOptions.chart.events.redraw = function (e) {

            image?.destroy();
            image = this.renderer.image(
                self.imageUrl,
                0,
                0,
                self.sizeToNumber(self.width, this, image, "x"),
                self.sizeToNumber(self.height, this, image, "y"),
            );
            image.add();
            image.translate(
                self.positionToNumber(self.x, this, image, 'x'),
                self.positionToNumber(self.y, this, image, 'y')
            );
            this['customImage'] = image;
            originalRedrawCallback?.call(this, e);
        };

    }

    private positionToNumber(position: TPosition, chart: Highcharts.Chart, image: Highcharts.SVGElement, dir: TDirection): number {
        if (typeof position === 'number') {
            return position;
        }
        if (typeof position === 'function') {
            return position(chart, image, dir);
        }
        if (position === 'center') {
            const box = image.getBBox();
            return dir === 'x' ? chart.chartWidth/2 - box.width/2 : chart.chartHeight/2 - box.height/2;
        }
        if (this.isPercentNumber(position)) {
            const numericValue = this.getPercentNumber(position);
            if (!Number.isNaN(numericValue)) {
                return dir === 'x' ? chart.chartWidth*numericValue/100 : chart.chartHeight*numericValue/100;
            }
        }
        console.error('Cannot compute position of image. Expect number, percent value, "center" or function, got:', position);
        return 0;
    }

    private sizeToNumber(size: TSize, chart: Highcharts.Chart, image: Highcharts.SVGElement, dir: TDirection): number|undefined {
        if (typeof size === 'number') {
            return size;
        }
        if (typeof size === 'function') {
            return size(chart, image, dir);
        }
        if (this.isPercentNumber(size)) {
            const numericValue = this.getPercentNumber(size);
            if (!Number.isNaN(numericValue)) {
                return chart.chartWidth*numericValue;
            }
        }

        return undefined;
    }

    private isPercentNumber(something: any): boolean {
        return Boolean(typeof something === 'string' && something[something.length-1] === '%' && something.substring(0, something.length-1));
    }

    private getPercentNumber(percent: string): number {
        return Number( percent.substring(0, percent.length-1) );
    }

}
