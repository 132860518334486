import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import ValueFormatter from "../../../model/ValueFormatter";
import Transition3Rgba from "../../../../../js/utility/Transition3Rgba";
import Table from "../../shared/Table";
import {Column} from "../../shared/Table/js/Table";
import BaseTableDashboardComponent from "../../BaseTableDashboardComponent";

type TValue = null | string | number;
type TRow = TValue[];
type TFormatting = { minColor?: string, middleColor?: string, maxColor: string };
type TColumnDefinition = {
	label: string,
	renderAs?: 'link' | 'text' | 'image' | 'vimeo' | 'date' | 'number' | string,
	unitMark?: string,
	precision?: number,
	filter?: 'input' | 'select',
    formatting: TFormatting
};

type TKBTableData = {
    columns: TColumnDefinition[],
    rows: TRow[],
}

export default class KbTable extends BaseTableDashboardComponent {

    public onUpdate(data: TKBTableData): void {
		const columns: Column[] = data.columns.map((colDef, i) => {
			return {
				id: String(i),
				label: colDef.label,
				formatter: this.createFormatter(colDef),
				exportFormatter: this.createFormatter(colDef, true),
				class: 'render-as-' + (colDef.renderAs || ''),
				styler: this.createCellStyler(i, data),
				sortable: true,
				filterable: colDef.renderAs === 'text',
			};
		});

		this.table = new Table(this.$table, columns);
		this.table.setSearch(this.$search).load(data.rows);
	}

	private createFormatter(definition: TColumnDefinition, exp = false) {
		const eF = (value: TValue) => String(value ?? ''); // exportFormatter
		switch (definition.renderAs) {
			case 'link':
				return (value: TValue) => exp ? eF(value) : `<a href="${value}" target="_blank">Link</a>`;
			case 'text':
				return eF;
			case 'image':
				return (value: TValue) => exp ? eF(value) : `<img src="${value}" />`;
			case 'vimeo':
				return (value: TValue) => exp ? eF(value) : `<iframe src="${value}" width="160" height="90" frameborder="0" allowfullscreen></iframe>`;
			case 'date':
				return (value: TValue) => {
					const dates = String(value).split(',').map((date) => (new Date(date)).toLocaleDateString());
					while (dates[1] && dates[0] === dates[1]) { // remove duplicity
						dates.shift();
					}
					// hidden span is used for correct sorting by date
					return exp ? dates.join(' - ') : `<span style="display:none">${value}</span><span class="date">${dates.join('</span> - <span class="date">')}</span>`;
				}
			case 'numeric':
				return (value: TValue) => ValueFormatter.format(value, definition.precision, definition.unitMark, exp ? '' : undefined);
			default:
				return eF;
		}
    }

    private createCellStyler(colIndex: number, data: TKBTableData) {
        const definition = data.columns[colIndex];
        const formatting = definition?.formatting;
        if (!formatting) {
            return undefined;
        }

        const column = this.getColumnValues(colIndex, data);
        const columnWithoutNulls = <number[]>column.filter(n => typeof n === 'number');
        const transitionFactory = new Transition3Rgba(
            formatting.minColor,
            formatting.middleColor,
            formatting.maxColor,
            Math.min(...columnWithoutNulls),
            Math.max(...columnWithoutNulls),
        );

        return (value: number) => {
            return value ? `background-color: ${transitionFactory.getColor(value)};` : '';
        }
    }

    private getColumnValues(col: number, data: TKBTableData): TValue[] {
        return data.rows.map(row => row[col]);
    }

}

ComponentManager.registerComponentClass('KbTable', KbTable);