export default class UserSco
{
    constructor(
        private $element: JQuery,
        private name: string,
        private options: any
    ) {
        this.init();
    }

    init() {
        this.$element.find('input[type="submit"]').on('click', () => {
            this.$element.closest('.modal').modal('hide');
        });
    }
}