import {Chart} from "highcharts";
import exportChartToArray from "./exportChartToArray";
import arrayToCsvDownload from "../../../../../../js/utility/arrayToCsvDownload";

export default class CsvExporter {

    private arrayExportFnc = exportChartToArray;

    public export(chart: Chart, filename = 'chart'): void {
        const chartData = this.arrayExportFnc(chart, false);
        chartData[0][0] = filename;

        arrayToCsvDownload(chartData, filename);
    }

    public setArrayExportFnc(exportFnc): void {
        this.arrayExportFnc = exportFnc;
    }
}
