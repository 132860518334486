import INetteExtension from "../INetteExtension";

/**
 * Control collapsible elements from backend
 * @see app/services/CollapseControl.php
 */
export default class ControlCollapseExtension implements INetteExtension {
    private static readonly HEADER_KEY_HIDE = 'hideCollapse';
    private static readonly HEADER_KEY_SHOW = 'showCollapse';


    success(payload, status, jqXHR: XMLHttpRequest, setting) {
        this.hide(jqXHR);
        this.show(jqXHR);
    }

    private hide(jqXHR: XMLHttpRequest): void {
        const target = jqXHR.getResponseHeader(ControlCollapseExtension.HEADER_KEY_HIDE);
        if (!target) {
            return;
        }

        $(target).collapse('hide');

    }


    private show(jqXHR: XMLHttpRequest): void {
        const target = jqXHR.getResponseHeader(ControlCollapseExtension.HEADER_KEY_SHOW);
        if (!target) {
            return;
        }

        $(target).collapse('show');
    }


}