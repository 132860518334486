import ChartConstants from "../../shared/highcharts/ChartConstants";

export default function wordCloudDefinition() {
    return {
        "chart": {
            "type": "wordcloud",
            "style": {
                "fontFamily": ChartConstants.FONT_FAMILY,
            },
            "backgroundColor": ChartConstants.PLOT_BACKGROUND,
        },
        "title": [],
        "subtitle": [],
        "tooltip": {
            "shadow": false,
        },
        "plotOptions": {
            "series": {
                "dataLabels": {
                    "backgroundColor": "white",
                    "borderColor": "#5a6574",
                    "borderRadius": 2,
                    "borderWidth": 1,
                    "enabled": false,
                    "format": null,
                    "padding": 2,
                    "style": {
                        "fontWeight": "normal",
                        "fontSize": "9px",
                        "color": "#686868",
                    }
                }
            },
            "wordcloud": {
                "minFontSize": 5,
                "maxFontSize": 30
            },
        },
        "series": [],
        "exporting": {
            "enabled": false,
        },
        "credits": false,
    };
}

