import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import TDataBarChart from "../types/TDataBarChart";
import {formatTooltip} from "../../../shared/highcharts/formatters";
import LowBaseModifier from "../../../shared/highcharts/modifiers/LowBaseModifier";

export default class TooltipBarOptions implements IOptions {
    options(data: TDataBarChart): Partial<Options> {

        const pointsTooltipFormatter = function() {
			const previousPoint = data.previousTermDatasets?.[this.point.series.options.custom.index]?.data?.[this.point.custom.index];

			return formatTooltip({
				label: this.point.series.name,
				color: this.point.color,
				value: this.point.y,
				count: this.point.custom?.count ?? 0,
				isLowBase: this.point.custom.isLowBase,
				formatter: this.point.custom.formatter,
				previous: previousPoint ? {
					value: previousPoint.value,
					count: previousPoint.count,
					isLowBase: previousPoint.count < LowBaseModifier.loadLowBase(data).threshold,
					formatter: this.point.custom.formatter
				} : undefined,
			})
		};

        return {
            tooltip: {
                shadow: false,
                followPointer: true,
                followTouchMove: true,
                formatter: pointsTooltipFormatter,
            },
        }
    }

}
