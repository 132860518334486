import copy from 'copy-to-clipboard';

export default class CopyToClipboard {
    public static last_copied_value;

    constructor(
        private readonly $element: JQuery,
        private readonly name: string,
        private readonly options: any
    ) {
        this.init();
    }

    private init() {
        const $icon = this.$element.find('.icon.ico-content-copy');
        const copyValue = this.$element.data('copy');

        $icon.on('click', () => {
            CopyToClipboard.last_copied_value = copyValue;
            copy(copyValue);
            $icon.tooltip('show');
            setTimeout(() => $icon.tooltip('hide'), 1000);
        });

        $icon.tooltip({
            title: () => $icon.data(CopyToClipboard.last_copied_value === copyValue ? 'copied' : 'title'),
        })
    }
}
