import {defaults as OlDefaultInteractions, Interaction, MouseWheelZoom} from "ol/interaction";
import {Collection} from "ol";


// https://github.com/openlayers/openlayers/issues/7666
function createMapInteractions(): Collection<Interaction> {
    const interaction = new MouseWheelZoom();

    const oldFn = interaction.handleEvent;
    interaction.handleEvent = function(e) {
        if (e.type !== "wheel") {
            return true;
        }

        const originalEvent = <MouseEvent>e.originalEvent;
        if (!originalEvent.ctrlKey && !originalEvent.metaKey) {
            return true
        }

        oldFn.call(this,e);
    }

    return OlDefaultInteractions({mouseWheelZoom: false}).extend([interaction]);
};

export default createMapInteractions;