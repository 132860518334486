import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import TDataBarChart from "../types/TDataBarChart";

export default class PlotOptionsBarOptions implements IOptions {
    options(data: TDataBarChart): Partial<Options> {

        const stacking = data.datasets.some(d => d.groupId) ? 'normal' : undefined;

        return {
            plotOptions: {
                bar: {
                    colorByPoint: false,
                    minPointLength: 4,
                    maxPointWidth: 50,
                    stacking: stacking,
                },
                column: {
                    colorByPoint: false,
                    dataLabels: {
                        rotation: 270,
                        inside: false,
                        align: "left",
                        crop: false,
                    },
                    minPointLength: 4,
                    maxPointWidth: 50,
                    stacking: stacking,
                }
            }
        }
    }

}
