export default class TableExcelFilter {
    private $el: JQuery;
    private $items: JQuery;
    private $controls: JQuery;
    private $toggle: JQuery;
    private $clear: JQuery;

    private isOpen = false;
    private items: string[] = [];
    private selectedItems: string[] = [];

    constructor(
        private readonly $table: JQuery,
        private $th: JQuery,
        private readonly onChange: (selectedItems: string[]) => void,
    ) {
        this.init();
    }

    private init(): void {
        this.$table.addClass('has-excel-filter');

        this.$el = $(`<div class="excelFilter ${this.selectedItems.length?'active':''}"></div>`);
        this.$controls = $('<div class="excelFilter-controls"></div>');
        this.$items = $('<div class="excelFilter-items"></div>');

        this.$clear = $('<i class="icon ico-filter-remove"></i>');
        this.$clear.on('click', (e) => this.reset())
        this.$controls.append(this.$clear);

        this.$toggle = $('<i class="icon ico-filter"></i>');
        this.$toggle.on('click', () => this.isOpen ? this.close() : this.open())
        this.$controls.append(this.$toggle);

        this.$el.append(this.$controls);
        this.$el.append(this.$items);
        this.$th.append(this.$el);
    }

    public loadItems(): void {
        const $th = this.$th.is('th') ? this.$th : this.$th.closest('th');
        const th = $th.get(0);
        // @ts-ignore
        const colIndex = [...th.parentNode.children].indexOf(th);

        const items: string[] = [];
        for (const td of this.$table.find(`tbody td:nth-child(${colIndex+1})`).get()) {
            const item = $(td).html()
            if (items.indexOf(item) === -1) { // only unique strings
                items.push(item);
            }
        }
        this.items = items.sort();
    }

    private add(item: string): void {
        this.remove(item);
        this.selectedItems.push(item);
        this.$el.addClass('active');
    }

    private remove(item: string): void {
        this.selectedItems = this.selectedItems.filter(i => i !== item);
        if (this.selectedItems.length === 0) {
            this.$el.removeClass('active');
        }
    }


    // API
    public attach($th: JQuery) {
        this.$th = $th;
        if ($th.find('.excelFilter').length === 0) {
            this.init();
        }
        if (this.isOpen) {
            this.open();
        }
    }

    public reset(): void {
        this.selectedItems = [];
        this.open();
        this.close();
        this.$el.removeClass('active');
        this.onChange(this.selectedItems);
    }

    public open(): void {
        if (this.$table.find('tbody tr td').get().length <= 1) {
            return;
        }

        this.$toggle.removeClass('ico-filter').addClass('ico-close');
        this.$el.addClass('open');
        this.isOpen = true;
        this.items.forEach(item => {
            const $item = $(`<div class="item">${item}</div>`);
            if (this.selectedItems.indexOf(item) !== -1) {
                $item.addClass('selected');
            }
            $item.on('click', (e) => {
                if (this.selectedItems.indexOf(item) === -1) { // add to selected
                    this.add(item);
                    $item.addClass('selected');
                } else { // remove from selected
                    this.remove(item);
                    $item.removeClass('selected');
                }
                this.onChange(this.selectedItems);
            })
            this.$items.append($item);
        });
    }

    public close(): void {
        this.$toggle.removeClass('ico-close').addClass('ico-filter');
        this.$el.removeClass('open');
        this.isOpen = false;
        this.$items.empty();
    }

    public getSelected(): string[] {
        return this.selectedItems;
    }
}