import BaseDashboardComponent from "../../BaseDashboardComponent";
import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import OlMap from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import {MapData} from "./MapDataTypes";
import MapFeatureFactory from "./MapFeatureFactory";
import MapPopup from "./MapPopup";
import createMapInteractions from "./createMapInteractions";

export default class Map extends BaseDashboardComponent {

    private featureFactory: MapFeatureFactory;
    private map?: OlMap;
    private featureLayer?: VectorLayer;
    private mapLayer?: TileLayer;
    private popup: MapPopup;

    constructor(...args: [any,any,any,any]) {
        super(...args);
        this.featureFactory = new MapFeatureFactory();
        this.popup = new MapPopup(this._$element.find('.ol-popup'));
    }

    init() {
        super.init();

        $(window).on('beforeprint afterprint', () => {
            this.map.updateSize();
        });
    }

    onUpdate(data)
    {
        if (!this._errors) {
            this.updateMap(data);
        }
    }

    private createMap(data: MapData) {
        const $map = this._$element.find('.ol-map');
        const $zoomHint = this._$element.find('.ol-zoom-hint');
        const key = '⌘ / CTRL';
        $zoomHint.text($zoomHint.text().replace('{key}', key));

        $map.empty();


        this.featureLayer = this.createFeatureLayer();

        this.map = new OlMap({
            layers: [
                this.createMapLayer(),
                this.featureLayer,
            ],
            interactions: createMapInteractions(),
            overlays: [ this.popup.mapOverlay ],
            target: $map[0],
            view: new View({
                center: MapFeatureFactory.createCoords(data.center || {longitude: 50.07, latitude: 14.41}),
                zoom: data.zoom,
            }),
        });

        this.map.on('click', (e) => {
            const features = [];
            this.map.forEachFeatureAtPixel( e.pixel, (feature, layer) => {
                features.push(feature);
            });

            if (features.length) {
                this.popup.showPopup(e.coordinate, features);
            } else {
                this.popup.hidePopup()
            }
        });

        $map.on('wheel', (e) => {
            if (!e.ctrlKey && !e.metaKey) {
                $zoomHint.addClass('show');
                setTimeout(() => $zoomHint.removeClass('show'), 700);
            }
        });

    }

    private updateMap(data: MapData) {
        // create empty map, if not exists
        if (!this.featureLayer || !this.map) {
            this.createMap(data);
        }

        // Remove all features and draw new
        const source = this.featureLayer.getSource();
        source.clear();
        source.addFeatures(
            data.markers.map((f) => {
                return this.featureFactory.mapCreateFeature(f, f.style);
            })
        );

    }

    /* LAYERS */
    /**
     * Create basic map layer
     * @private
     */
    private createMapLayer(): TileLayer {
        this.mapLayer = new TileLayer({source: new OSM()});
        return this.mapLayer;
    }

    /**
     * Create empty feature layer
     * @private
     */
    private createFeatureLayer(): VectorLayer {
        return new VectorLayer({
            source: new VectorSource({ features: [] }),
        });
    }

}

ComponentManager.registerComponentClass('Map', Map);

