import IOptions from "./IOptions";
import {Options} from "highcharts";
import TDataBarChart from "../../../BarChart/js/types/TDataBarChart";

export default class ExportingOptions implements IOptions {
    options(data: TDataBarChart): Partial<Options> {
        return {
            exporting: {
                enabled: false
            }
        }
    }

}
