/**
 * Reset dashboard filters
 */
export default class ResetFilters
{
    constructor($element, name, options) {
        this._$element = $element;
        this._name = name;
        this._options = options;

        $element.on('click', (e) => {
            e.preventDefault();
            if ($.components.board) {
                $.components.board.filter.reset();
            }
        });
    }

}
