export default class OpenCloseToggle
{
    private readonly $target: JQuery;
    private lastWindowWidth: number = -1; // init value

    constructor(
        private readonly $element: JQuery,
        private readonly name: string,
        private readonly options: any
    ) {
        const target = this.$element.data('target')
        this.$target = $(target || '');
        if (!target || !this.$target.length) {
            console.warn(`OpenCloseToggle - no target '${target}', check data-target attribute.`);
            console.log(this.$element);
        }


        this.init();
    }

    private init() {

        this.$element.on('click', e => {
            e.preventDefault();
            if (this.$target.hasClass('closed')) {
                this.open();
            } else {
                this.close();
            }
        });

        $(window).on('resize', () => {
            const currentWindowWidth = $(window).width();
            if (this.lastWindowWidth === currentWindowWidth) {
                return;
            }
            this.lastWindowWidth = currentWindowWidth;

            if (currentWindowWidth > 991) {
                this.open();
            } else {
                this.close();
            }
        })
    }

    private close() {
        this.$target.addClass('closed');
        this.$target.scrollTop(0);
        this.$element.addClass('target-closed');
    }

    private open() {
        this.$target.removeClass('closed');
        this.$element.removeClass('target-closed');
    }
}