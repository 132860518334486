import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";

export default class XAxisBarOptions implements IOptions {
    options(data: any): Partial<Options> {
        return {
            xAxis: {
                type: "category",
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true,
                plotBands: [],
                plotLines: [],
                labels: {
                    style: {
                        fontSize: "12px"
                    }
                }
            }
        }
    }

}