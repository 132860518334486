import INetteExtension from './INetteExtension';

export default class NetteExtensionRegistrar {
    private static extensions: INetteExtension[] = [];

    static addExtension(extension: INetteExtension): void {
        if (!this.extensions.includes(extension)) {
            this.extensions.push(extension);
        }
    }

    static register(): void {
        // @ts-ignore
        if (typeof $.nette === 'undefined') {
            throw Error('$.nette is missing, unable to register extensions.');
        }

        if (process.env.NODE_ENV === 'development') {
            console.log('Registered extensions', this.extensions);
        }

        this.extensions.forEach((extension, i) => {
            const name = extension.name?.() || extension.constructor.name;
            const ext = {
                init: function(...args) { extension.init?.call(extension, ...args)},
                load: function(...args) { extension.load?.call(extension, ...args)},
                before: function(...args) { extension.before?.call(extension, ...args)},
                start: function(...args) { extension.start?.call(extension, ...args)},
                success: function(...args) { extension.success?.call(extension, ...args)},
                complete: function(...args) { extension.complete?.call(extension, ...args)},
            };

            // @ts-ignore
            $.nette.ext(name, ext);
        });

        // clear registered extensions
        this.extensions = [];
    }
}

