import {Czech} from "flatpickr/dist/l10n/cs";
import {Slovak} from "flatpickr/dist/l10n/sk";
import {Hungarian} from "flatpickr/dist/l10n/hu";

export default function getFlatpickrLocale() {
    let locale;
    switch ($('html').attr('lang')) {
        case 'cs': locale = Czech; break;
        case 'sk': locale = Slovak; break;
        case 'hu': locale = Hungarian; break;
    }
    return locale || 'en';
}