import m, {Vnode} from "mithril";
import Filter from "../Filter";
import {TFilterGroup} from "../../model/filterTypes";
import IFilterComponent from "../IFilterComponent";
import FilterItem from "./FilterItem";
import ISearchable from "./ISearcheable";
import FilterItemLabel from "./FilterItemLabel";

export default class FilterGroup extends FilterItem implements IFilterComponent, ISearchable {

    public readonly subtreeItems: IFilterComponent[] = [];
    private readonly label: FilterItemLabel;

    private isOpen = false;

    constructor(
        public readonly definition: TFilterGroup,
        public readonly filter: Filter,
        public readonly parent: IFilterComponent|null = null,
    ) {
        super();
        filter.search?.register(this);

        this.label = new FilterItemLabel(this.definition.label);
    }

    public addItem(item: IFilterComponent): void {
        this.subtreeItems.push(item);
    }

    public onFilterLoadState() {
        this.subtreeItems.forEach(si => si.onFilterLoadState());
        this.isOpen = this.isActive();
    }

    public isActive(): boolean {
        return this.subtreeItems.some(i => i.isActive());
    }

    public reset(): void {
        this.subtreeItems.forEach(fi => fi.reset());
    }

    public onSearch(query: string) {
        this.isOpen = Boolean(query);
    }

    public open(): void {
        this.isOpen = true;
    }

    viewModalPanel(): Vnode|null {
        return null;
    }

    viewModalSidebar(): Vnode|null {
        return null;
    }

    viewSidebar(): Vnode|null {
        const cssClass = '.FilterItem.FilterItem-Group' + (this.isOpen ? '.opened' : '.closed');
        return m(cssClass, {}, [
            m('.item', {
                onclick: () => this.isOpen = !this.isOpen,
            }, [
                m('div.item-label', {}, [
                    m(this.label),
                ]),
                m('.control', {}, [
                    m('i.icon.ico-dropdown'),
                ]),
            ]),
            m('.subtree', {}, this.subtreeItems.map(i => i.viewSidebar())),
        ]);
    }

}