import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import TDataScatterChart from "./TDataScatterChart";
import Highcharts from 'highcharts';
import scatterChartDefinition from "./scatterChartDefinition";
import _ from 'lodash';
import BaseHighchartsComponent from "../../BaseHighchartsComponent";

class ScatterChart extends BaseHighchartsComponent {

    public onUpdate(data: TDataScatterChart): void {

        this.chart?.destroy();

        const series = data.datasets.map(d => {
            return {
                name: d.label,
                color: d.color,
                data: d.data.filter(i => !i.some(a => a === null)), // remove null pairs
            };
        });

        const chartOptions = _.merge(scatterChartDefinition(), {series: series}, data.chart);

        this.addAveragePlotLines(chartOptions);
        this.chart = Highcharts.chart(this._$element.find('[data-chart]')[0], chartOptions);

    }

    private addAveragePlotLines(chartOptions): void {

        _.set(chartOptions, 'chart.events.render', function () {
            this.xAxis[0].removePlotBand('mean-x');
            this.yAxis[0].removePlotBand('mean-y');

            const data = this.series.filter(s => s.visible && s.data[0] !== undefined)
                .map(s => [s.data[0].x, s.data[0].y]);
            const x = _.mean(data.map(d => d[0]));
            const y = _.mean(data.map(d => d[1]));

            this.xAxis[0].addPlotLine( {id: 'mean-x',color: '#FF0000', width: 1, value: x, dashStyle: 'dash'} );
            this.yAxis[0].addPlotLine( {id: 'mean-y',color: '#FF0000', width: 1, value: y, dashStyle: 'dash'} );
        });
    }

}

ComponentManager.registerComponentClass('ScatterChart', ScatterChart);
