import INetteExtension from "../../INetteExtension";
import ClassicExtension from "../../ClassicExtension";

export default class FixUblabooGridScroll extends ClassicExtension implements INetteExtension {

    public attach($root: JQuery): void {

        if ($root.find('.card-body.table-responsive table>tfoot .row-grid-bottom').length) {
            $('body').css('overflow-x', 'hidden');
        }

        // Attach update on scroll event
        $root.find('.card-body.table-responsive').on('scroll', e => this.updateRelativePosition(e.currentTarget));

        // But, update position after any snippet redraw
        $('.card-body.table-responsive').each((i, container) => this.updateRelativePosition(container));
    }

    private updateRelativePosition(container: HTMLElement): void {
        const left = container.scrollLeft;
        $(container).find('table>tfoot .row-grid-bottom>*').each((i, child) => {
            child.style.left = left + 'px';
        });
    }

}
