import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import TranslatableElement from "../../../appComponents/TranslatableElement";
import Table from "../../shared/Table";

type TAnswersTableRow = {
    id: string,
    value: string,
    link?: string,
    translatableLink?: string,
};

type TAnswersTableData = {
    count: number,
    countPerPage: number,
    rows: TAnswersTableRow[],
    targetLanguage?: string,
}

export default class AnswersTable extends DashboardComponent
{
    private currentPage = 0;
    private table: Table;

    init()
    {
        super.init();
        const $table = $(this._$element).find('[data-table]');

        this.table = new Table($table, [
            {
                id: 'value',
                label: 'value',
                formatter: (value, row) => {
                    return row.translatableLink && row.value !== row.link ? `<div class="TranslatableElement" data-component-translatable-element data-url="${row.translatableLink}">${row.value}</div>` : String(row.value);
                }
            }, {
                id: 'link',
                label: 'link',
                hider: rows => !Boolean(rows[0]?.link),
                styler: () => 'width:0;'
            }
        ]);
        this.table.setHasThead(false);

    }


    onUpdate(data: TAnswersTableData)
    {
        if ($('[data-use-stored-data="1"]').length) {
            // do not update from component stored data - prevent translation rewriting by stored data during print in printilo
            return;
        }

        this.table.load(data.rows);

        this._$element.find('.showNext').prop('disabled', data.count < data.countPerPage)
        this._$element.find('.showPrevious').prop('disabled', this.currentPage === 0);

        this.initTranslations();
    }

    getOwnFilterData(): FormData {
        
        const filterData = super.getOwnFilterData();
        if (filterData) {
            filterData.append("currentPage", String(this.currentPage));
        }
        return filterData;
    }

    registerOwnFilterChange()
    {
        const $form = this._$ownFilter.find('form');
        
        $form.on('change submit', e => {
            e.preventDefault();
            this.currentPage = 0;
            this._componentManager.applyComponentFilter(this, new FormData($form.get(0)));
        });

        $form.find('input[type="radio"]').on('change', () => {
            // checked option needs to be saved to DOM - because of Printilo
            $form.find('input[type="radio"]:not(:checked)').removeAttr('checked');
            $form.find('input[type="radio"]:checked').attr('checked', 'checked');
        });

        this._$element.find('.showNext').on('click', e => {
            e.preventDefault();
            this.currentPage++;
            this._componentManager.applyComponentFilter(this, new FormData($form.get(0)));
            this.scrollOnThis();
        });

        this._$element.find('.showPrevious').on('click', e => {
            e.preventDefault();
            if (this.currentPage > 0) {
                this.currentPage--;
                this._componentManager.applyComponentFilter(this, new FormData($form.get(0)));
                this.scrollOnThis();
            }
        });
    }

    scrollOnThis() {
        window.scroll(
              window.scrollX,
            this._$element.closest('.card')[0].getBoundingClientRect().top + document.documentElement.scrollTop - 120
        );
    }

    initTranslations() {
        $.each(this._$element.find('[data-component-translatable-element]'), (i, el) => {
            const $el = $(el);
            new TranslatableElement($el, this.getName()+'_t'+i, {});
            $el.removeAttr('data-component-translatable-element');
        });
    }
}

ComponentManager.registerComponentClass('AnswersTable', AnswersTable);
