import copy from 'copy-to-clipboard';

export default class CopyAnchor {
    public static last_copied_value;

    constructor(
        private readonly $element: JQuery,
        private readonly name: string,
        private readonly options: any
    ) {
        this.init();
    }

    private init() {
        const $icon = this.$element;
        const id = $icon.data('id');
        const copyValue = location.href.replace(location.hash,"").replace('#', '') + (id ? '#'+id : '');

        $icon.on('click', () => {
            CopyAnchor.last_copied_value = copyValue;
            copy(copyValue, {format: 'text/plain'});
            $icon.tooltip('show');
            setTimeout(() => $icon.tooltip('hide'), 1000);
        });

        $icon.tooltip({
            title: () => $icon.data(CopyAnchor.last_copied_value === copyValue ? 'copied' : 'title'),
        })
    }
}
