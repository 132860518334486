import IPrintChange from "./IPrintChange";

export default class BodyPrintSetup implements IPrintChange{

    prepare(): void {
        const $body = $('body');
        $body.addClass('printing');
        document.body.style.width = '1100px';
    }

    revert(): void {
        const $body = $('body');
        $body.removeClass('printing');
        document.body.style.width = '';
    }

}
