import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options, Point} from "highcharts";
import TDataRangeChart from "../types/TDataRangeChart";
import ExtendedPointOptionsObject from "../../../shared/highcharts/ExtendedPointOptionsObject";
import TDataPoint from "../../../shared/highcharts/TDataPoint";
import LowBaseModifier from "../../../shared/highcharts/modifiers/LowBaseModifier";
import {TLowBase} from "../../../../Board/src/model/TBoardOptions";

export default class TooltipOptions implements IOptions {



    private static createDatasetRow(point: Point & ExtendedPointOptionsObject): string|null {
        return `<span style="color:${point.color}">●</span> ${point.series.name}`;
    }

    private static createCurrentPointRow(point: Point & ExtendedPointOptionsObject): string|null {
        const formatter = point.custom.formatter;
        const dataPoint = point.custom.point;
        const type = point.custom.type;
        const value = formatter.format(dataPoint.value);
        if (type === 'min' || type === 'max') {
            return dataPoint.count ? `${value}, ${LowBaseModifier.createCountString(dataPoint.count,point.custom.isLowBase)}, ${dataPoint.label}` : null;
        }
        return dataPoint.count ? `${value}, ${LowBaseModifier.createCountString(dataPoint.count,point.custom.isLowBase)}` : null;
    }

    private static createPreviousRow(current: Point & ExtendedPointOptionsObject, previous?: TDataPoint, lowBase?: TLowBase): string|null {
        if (previous?.count) {
            const formatter = current.custom.formatter;
            const previousValue = previous.value;
            const previousValueRounded = formatter.getRoundedValue(previous.value);
            const currentValueRounded = formatter.getRoundedValue(current.value);
            const valueDiff = previousValue === null ? null : currentValueRounded - previousValueRounded;
            const previousCount = previous.count;

            const isPreviousCountLowBase = previousCount < Number(lowBase?.threshold);

            if (typeof valueDiff === "number" && previousCount) {
                const previousValueFormatted = formatter.format(previousValue);
                const previousValueDiff = (valueDiff > 0 ? '+' : '') + formatter.format(valueDiff);
                const color = valueDiff > 0 ? 'color:green' : (valueDiff<0 ? 'color:red' : '');
                return `<span style="color:transparent">●</span> <span style="color:#909090;font-size:10px">${previousValueFormatted} (<span style="${color}">${previousValueDiff}</span>), ${LowBaseModifier.createCountString(previousCount,isPreviousCountLowBase)}</span>`;
            }
        }
        return null;
    }

    options(data: TDataRangeChart): Partial<Options> {

        const pointsTooltipFormatter = function() {
            const previousPoint = data.previousTermDatasets?.[this.point.series.options.custom.index]?.dataTotal?.[this.point.custom.index];

            return [
                TooltipOptions.createDatasetRow(this.point),
                TooltipOptions.createCurrentPointRow(this.point),
                TooltipOptions.createPreviousRow(this.point, previousPoint, LowBaseModifier.loadLowBase(data)),
            ].filter(row => row).join('<br>');
        };

        return {
            tooltip: {
                shadow: false,
                followPointer: true,
                followTouchMove: true,
                formatter: pointsTooltipFormatter,
            },
        }
    }

}
