import INetteExtension from "../INetteExtension";

/**
 * Control modal elements from backend
 * @see app/services/ModalControl.php
 */
export default class ControlModalExtension implements INetteExtension {
    private static readonly HEADER_KEY_HIDE = 'hideModal';
    private static readonly HEADER_KEY_SHOW = 'showModal';


    success(payload, status, jqXHR: XMLHttpRequest, setting) {
        this.hide(jqXHR);
        this.show(jqXHR);
    }

    private hide(jqXHR: XMLHttpRequest): void {
        const target = jqXHR.getResponseHeader(ControlModalExtension.HEADER_KEY_HIDE);
        if (!target) {
            return;
        }

        if (target === '*') {
            $('.modal.show').modal('hide');
        } else {
            $(target).modal('hide');
        }
    }


    private show(jqXHR: XMLHttpRequest): void {
        const target = jqXHR.getResponseHeader(ControlModalExtension.HEADER_KEY_SHOW);
        if (!target) {
            return;
        }

        $(target).modal('show');
    }


}