export default class FoldingMenu {

    constructor($element, name, options) {
        this._$element = $element;
        this._name = name;
        this._options = options;

        this.attachEvents();
    }

    attachEvents() {
        const $btn = this._$element.find('[data-site-menu-folder]');
        $btn.on('click', () => {

            if (this._$element[0].style.width) {
                this._$element.css('width', '');
                $btn.removeClass('opened');
            } else {
                this._$element.css('width', this.unfoldedWidth+'px');
                $btn.addClass('opened');
            }
        })
    }

    get unfoldedWidth() {
        const originalWidth = this._$element.outerWidth();
        const $hidden = this._$element.clone();
        $hidden.css('position', 'absolute');
        $hidden.css('left', '200%');
        $hidden.css('width', 'auto');
        $hidden.css('min-width', 'unset');
        $hidden.find('.site-menu-title').css('text-overflow', 'none').css('padding-right', '40px');
        $hidden.find('.scrollable-content').css('width', '');
        $('body').append($hidden);
        const unfoldedWidth = 11 + Math.max($hidden.outerWidth(), originalWidth); // 11px works just fine
        $hidden.remove();

        return unfoldedWidth;
    }
}