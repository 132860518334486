export default class Jezisek {

    private $dust: JQuery;
    private $body: JQuery;
    private symbol: string;

    private static readonly DUST_COLORS = ['#ffd967', '#89f36a', '#ec5c5c'];

    constructor(
        private readonly $component: JQuery
    ) {
        this.$dust = $component.find('.jezisek-dust');
        this.$body = $component.find('.jezisek-body');
        this.symbol = $component.data('symbol') ?? '.';

        const interval = setInterval(() => {
            this.createParticle();
            if (Math.random() < 0.3) {
                this.createParticle();
            }
        }, Math.round(200+Math.random()*200));
        setTimeout(() => clearInterval(interval), 240_000);
    }

    private createParticle(): void {
        const $particle= $(`<div class="jezisek-particle">${this.symbol}</div>`);
        $particle.css('top', (this.$body.offset().top + 10 + Math.random()*30)+'px');
        $particle.css('left', (20+this.$body.offset().left)+'px');
        $particle.css('scale', this.pickScale());
        $particle.css('color', this.pickColor());
        $particle.css('animation-duration', this.pickAnimationDuration());
        this.$dust.append($particle);

        setTimeout(() => $particle.remove(), 8000);
    }

    private pickColor(): string {
        const index = Math.round(Math.random() * Jezisek.DUST_COLORS.length);
        return Jezisek.DUST_COLORS[index];
    }

    private pickScale(): string {
        return ''+(2*Math.random());
    }

    private pickAnimationDuration(): string {
        return (4 + 4*Math.random())+'s';
    }

}
