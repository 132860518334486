import m, {Vnode} from 'mithril';
import Api from "./model/Api";
import Filter from "./components/Filter";
import TBoardOptions from "./model/TBoardOptions";

export default class Board {

    public static instance?: Board;

    public readonly api: Api;
    private _filter: Filter;

    private readonly listeners = {
        beforeFilter: [],
        afterFilter: [],
    }

    constructor(
        public readonly $element: JQuery,
        public readonly name: string,
        public readonly options: TBoardOptions,
    ) {
        Board.instance = this;

        this.options.settings.lowBase ??= {mode: 'disabled', threshold: -1};

        if (this.options && this.options.endpoints) {
            // model
            this.api = new Api(this, this.options.endpoints);

            // components
            this.reloadFilter();

            // RUN!
            m.mount($element.get(0), this);
        } else {
            console.error("Unable to initialize Board component, options missing.")
        }
    }

    public reloadFilter(
        onLoadStructure?: (filter: Filter)=>any,
        onLoadState?: (filter: Filter)=>any,
    ): void {
        this._filter = new Filter(this, onLoadStructure, onLoadState);
    }

    public get filter(): Filter {
        return this._filter;
    }

    public addEventListener(event: "beforeFilter"|"afterFilter"|string, listener: (board: Board) => void) {
        return this.listeners[event]?.push(listener);
    }

    public removeEventListener(event, listener: (board: Board) => void) {
        this.listeners[event] = this.listeners[event]?.filter(l => l !== listener);
    }

    public onBeforeFilter() {
        this.dispatchEvent('beforeFilter');
    }

    public onAfterFilter() {
        this.dispatchEvent('afterFilter');
    }

    private dispatchEvent(event: string) {
        this.listeners[event]?.forEach((l) => {
            if (typeof l === 'function') {
                l(this);
            }
        })
    }

    // return just filter... prozatím
    public view(): Vnode {
        return m(this.filter)
    }

}
