export default class ChartColors
{

    // https://www.figma.com/file/rkEtOfusTw9ruvBWA2jGdK/01---ASAP-'25?node-id=4%3A32740
    public static readonly COLORS = [
		'#009FE3', '#92C329', '#D8DE31', '#D1A022', '#2DC3A8', '#CF7736',
		'#D92F80', '#9D2FD0', '#6633BB', '#2B4088', '#176CA1', '#397C40',
		'#A62320', '#CA4B40', '#24CBD6', '#239208', '#27B16F', '#C939A9',
		'#DB121E', '#B7CA40',
	];

	public static readonly COLOR_TOTAL = '#3A3A3A';

	public static getColorByIndex(index): string {
		return ChartColors.COLORS[index % ChartColors.COLORS.length];
	}

	/**
	 * Pick color safely
	 */
	public static pickColor(color: string | string[], index: number, defaultColor: string = null): string {
		if (typeof color === 'string') {
			return color;
		}
		if (defaultColor) {
			return defaultColor;
		}
		return color[index % color.length]
	}
}
