import ChartConstants from "../../shared/highcharts/ChartConstants";

export default function sankeyDiagramDefinition() {
    return {
        "chart": {
            "styledMode": false,
            "backgroundColor": ChartConstants.PLOT_BACKGROUND,
            "style": {
                "fontFamily": ChartConstants.FONT_FAMILY,
            },
        },
        "title": null,
        "subtitle": null,
        "tooltip": {
            "shadow": false,
        },
        "responsive": {
            "rules": [],
        },
        "exporting": {
            "enabled": false,
        },
        "credits": false,
    };
}
