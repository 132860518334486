import IOptions from "../../../shared/highcharts/options/IOptions";
import TDataPieChart from "../types/TDataPieChart";
import {Options} from "highcharts";
import ExtendedPointOptionsObject from "../../../shared/highcharts/ExtendedPointOptionsObject";
import {formatPointCount, formatPointValue} from "../../../shared/highcharts/formatters";


export default class TooltipPieOptions implements IOptions {
    options(data: TDataPieChart): Partial<Options> {

        const pointsTooltipFormatter = function() {
            let label, value, count, countColor;
            const point: ExtendedPointOptionsObject = this.point;

            // @ts-ignore
            if (point.series.index === 0) { // datasets
                if (point.custom.count === 0) { // rest to 100%
                    return false;
                }
                label = this.point.name;
                label = label ? `<span>${label}</span><br>` : '';
                value = formatPointValue(this.point);
                value = value ? value+', ' : '';
                count = formatPointCount(this.point);
                countColor = point.custom.isLowBase ? 'color:red' : '';
            } else { // targets
                label = `<span>${this.point.series.name}</span><br>`;
                value = formatPointValue(this.point);
                countColor = '';
                count = '';
            }
            return `<span style="color:${this.point.color}">●</span> ${label}<span>${value}</span><span style="${countColor}">${count}</span>`;
        };

        return {
            "tooltip": {
                "shadow": false,
                "useHTML": false,
                "formatter": pointsTooltipFormatter,
            },
        }
    }
}