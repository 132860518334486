import FilterItem from "./FilterItem";
import m, {Vnode} from "mithril";
import {TFilterItemRange} from "../../model/filterTypes";
import Filter from "../Filter";
import IFilterComponent from "../IFilterComponent";
import noUiSlider from "nouislider";
import ISearchable from "./ISearcheable";
import StringMatcher from "../../utilities/StringMatcher";

export default class FilterItemRange extends FilterItem implements IFilterComponent, ISearchable {
    private isOpen = false;

    private min: number = Number.MAX_SAFE_INTEGER;
    private max: number = Number.MIN_SAFE_INTEGER;

    private begin: number = Number.MIN_SAFE_INTEGER;
    private end: number = Number.MAX_SAFE_INTEGER;

    public self;
    public slider;

    private isSearching = false;
    private searchMatch = false;
    private readonly searchMatcher: StringMatcher;

    constructor(
        private readonly definition: TFilterItemRange,
        public readonly filter: Filter,
        public parent?: IFilterComponent
    ) {
        super();
        this.self = this;

        this.filter.search?.register(this);
        this.searchMatcher = new StringMatcher(this.definition.label);

        definition?.values.forEach((rangeValue) => {
            const value = parseFloat(rangeValue.label);
            if (!isNaN(value)) {
                this.min = Math.min(this.min, value);
                this.max = Math.max(this.max, value);
            }
        });
    }

    isActive(): boolean {
        return this.definition?.values.some(v => !this.filter.list().getOptionState(v.id));
    }


    onFilterLoadState(): void {
        this.begin = this.max;
        this.end = this.min;
        let found = false;
        this.definition?.values.forEach((rangeValue) => {
            if (this.filter.list().getOptionState(rangeValue.id)) {
                const value = parseFloat(rangeValue.label);
                if (!isNaN(value)) {
                    found = true;
                    this.begin = Math.min(this.begin, value);
                    this.end = Math.max(this.end, value);
                }
            }
        });
        if (!found) {
            this.begin = this.min;
            this.end = this.max;
        }

        this.self.slider?.set([this.begin, this.end]);

        this.isOpen = this.isActive();
    }

    public getStates(): string[] {
        const states = [];
        this.definition?.values.forEach((rangeValue) => {
            if (this.filter.list().getOptionState(rangeValue.id)) {
                states.push(rangeValue.id);
            }
        });
        return states;
    }

    onSearch(query: string): void {
        this.isSearching = Boolean(query);
        this.searchMatch = this.searchMatcher.match(query);
    }

    viewModalPanel(): Vnode | null {
        return null;
    }

    viewModalSidebar(): Vnode | null {
        return null;
    }

    viewSidebar(): Vnode | null {
        if (this.isSearching && !this.searchMatch) {
            return null;
        }

        const open = this.isSearching || this.isOpen;
        const cssClass = '.FilterItem.FilterItem-Range' + (open ? '.opened' : '.closed');
        return m(cssClass, {}, [
            m('.item', {
                onclick: (e: MouseEvent) => {
                    e.preventDefault();
                    this.isOpen = !this.isOpen
                },
            }, [
                m('div.item-label', {}, [
                    m('label', {}, this.definition.label),
                ]),
                m('.control', {}, [
                    this.isActive() ? m('i.icon.ico-filter-remove', {
                        onclick: (e: MouseEvent) => {
                            e.stopPropagation();
                            this.reset();
                            this.filter.triggerChange();
                        }
                    }) : null,
                    m('i.icon.ico-dropdown'),
                ]),
            ]),
            m('.subtree', {}, [
                m('.slider', {
                    oncreate: (vnode) => {

                        this.self.slider = noUiSlider.create(vnode.dom, {
                            start: [this.begin, this.end],
                            connect: true,
                            range: {min: this.min, max: this.max},
                        });

                        this.self.slider.on('change', (values) => {
                            [this.begin, this.end] = values;
                            this.updateState();
                            this.filter.triggerChange();
                            m.redraw();
                        });

                        this.self.slider.on('update', (values) => {
                            [this.begin, this.end] = values;
                            m.redraw();
                        });
                    }
                }),
                m('.slider-values', {}, [
                    m('input.form-control', {
                        type: 'number',
                        value: Math.round(this.begin),
                        onchange: (e: InputEvent) => {
                            this.begin = Math.min(Math.max(parseFloat( (<HTMLInputElement>e.currentTarget).value ), this.min), this.max) || this.begin;
                            this.self.slider?.set([this.begin, this.end]);
                            this.updateState();
                            this.filter.triggerChange();
                        },
                    }),
                    m('input.form-control', {
                        type: 'number',
                        value: Math.round(this.end),
                        onchange: (e: InputEvent) => {
                            this.end = Math.min(Math.max(parseFloat( (<HTMLInputElement>e.currentTarget).value ), this.min), this.max) || this.end;
                            this.self.slider?.set([this.begin, this.end]);
                            this.updateState();
                            this.filter.triggerChange();
                        },
                    }),
                ])
            ])
        ]);
    }

    public reset(): void {
        this.begin = this.min;
        this.end = this.max;
        this.self.slider?.set([this.begin, this.end]);
        this.updateState();
        this.isOpen = false;
    }

    private updateState(): void {
        const list = this.filter.list();
        list.clearFavouriteState();

        const allItems = this.begin <= this.min && this.end >= this.max;
        this.definition?.values.forEach((rangeValue) => {
            const value = parseFloat(rangeValue.label);
            const state = allItems || (!isNaN(value) && value >= this.begin && value <= this.end);
            list.setOptionState(rangeValue.id, state);
        });
    }



}
