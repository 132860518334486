import ChartConstants from "../../shared/highcharts/ChartConstants";

export default function lineChartDefinition() {
    return {
        "chart": {
            "style": {
                "fontFamily": ChartConstants.FONT_FAMILY,
            },
            "type": "line",
            "scrollablePlotArea": {
                "minWidth": 250,
                "scrollPositionX": 0.5,
            },
            "zoomType": "xy",
            "panKey": "shift",
            "panning": {
                "enabled": false, // cannot be combined with endOnTick
                "type": "xy"
            },
            "backgroundColor": ChartConstants.PLOT_BACKGROUND,
        },
        "title": null,
        "subtitle": null,
        "xAxis": {
            "startOnTick": true,
            "endOnTick": true,
            "showLastLabel": true,
            "labels": {
                "style": {
                    "fontSize": "12px"
                }
            },
            "crosshair": {
                "color": ChartConstants.CROSS_HAIR_COLOR,
            },
        },
        "yAxis": [{
            "title": {
                "enabled": true,
                "text": ""
            },
            "endOnTick": true, // round to closest higher tick
            "startOnTick": true, // round to closest lower tick
            "maxPadding": 0, // do not next line if point hits last tick (chart point 100% not draw chart line 125%)
            "minPadding": 0, // -||-
            "minRange": 1,
            "labels": {
                "style": {
                    "fontSize": "12px"
                }
            }
        }],
        "legend": {
            "layout": "horizontal",
            "align": "center",
            "verticalAlign": "bottom",
            "floating": false,
            "useHTML": true,
            "itemStyle": {
                "fontWeight": "normal"
            }
        },
        "tooltip": {
            "shadow": false,
        },
        "plotOptions": {
            "line": {
                "marker": {
                    "enabled": true,
                    "symbol": "circle",
                        "radius": 5,
                        "states": {
                        "hover": {
                            "enabled": true,
                            "lineColor": "rgb(100,100,100)"
                        }
                    }
                },
                "states": {
                    "hover": {
                        "marker": {
                            "enabled": false
                        }
                    }
                },
                "tooltip": {
                    "headerFormat": "<b>{series.name}</b><br>",
                }
            },
            "series": {
                "connectNulls": true,
                "dataLabels": {
                    "enabled": false,
                    "allowOverlap": true,
                    "overflow": "justify",
                    "format": null,
                    "style": {
                        "fontWeight": "normal",
                        "fontSize": "11px",
                        "color": "black",
                    }
                }
            }
        },
        "responsive": {
            "rules": [],
        },
        "exporting": {
            "enabled": false,
        },
        "credits": false,


        "annotations": [{
            "zIndex": 1,
            "draggable": false,
        }]
    };
}
