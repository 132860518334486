import IOptions from "../../../shared/highcharts/options/IOptions";
import TDataPieChart from "../types/TDataPieChart";
import ChartColors from "../../../../../../js/utility/ChartColors";
import {createValueFormatterFromDataset} from "../../../shared/highcharts/formatters";
import {Options, SeriesPieOptions as SeriesBarOptions_type} from "highcharts";



export default class SeriesPieOptions implements IOptions {

    private static areDataEmpty(data: TDataPieChart): boolean {
        if (!data.datasets?.length) {
            return false
        }
        return !data.datasets.some(dataset => dataset.data?.some(p => p.count));
    }

    options(data: TDataPieChart): Partial<Options> {
        if (SeriesPieOptions.areDataEmpty(data)) {
            return {
                series: [],
            }
        }

        const serie: SeriesBarOptions_type = {
            name: data.categories?.[0] ?? '',
            type: 'pie',
            data: data.datasets.map((d, i) => {
                return {
                    y: d.data[0].value,
                    name: d.label,
                    custom: {
                        count: d.data[0].count,
                        formatter: createValueFormatterFromDataset(d),
                        link: d.data[0].link,
                        value: d.data[0].value,
                        index: i,
                    },
                }
            }),
            colors: data.datasets
                .map((d, i) => d.color ?? ChartColors.getColorByIndex(d.index ?? i)),
        }

        return {
            series: [serie],
        }
    }
}
