import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options, SeriesBarOptions as SeriesBarOptions_type} from "highcharts";
import TDataBarChart from "../types/TDataBarChart";
import ChartColors from "../../../../../../js/utility/ChartColors";
import transformBarChartDataset from "../transforms/transformBarChartDataset";
import transformBarChartTotalPointToPlotLine from "../transforms/transformBarChartTotalPointToPlotLine";

export default class SeriesBarOptions implements IOptions {

	// Get color for dataset from
	//  1 custom chart series setting
	//  2 given dataset
	//  3 from default color palette
	// Colorize only 1 dataset column by column (= point by point)
	private getColor(data: TDataBarChart, dataset, i: number): string | string[] {
		const index = typeof dataset.index === 'number' ? dataset.index - 1 : i;
		const customChartSeriesColor = data.chart?.series?.[index]?.['color'];

		if (data.datasets.length > 1) {
			return customChartSeriesColor ?? dataset.color ?? ChartColors.getColorByIndex(index);
		} else {
			return customChartSeriesColor ?? dataset.color ?? ChartColors.COLORS;
		}
	}

	/** Create multiline categories labels, which includes total datasets data */
    private getCategoriesLabels(data: TDataBarChart, totalDatasets): string[] {

        return data.categories.map((category, i) => {
            totalDatasets?.forEach((d) => {
                const point = d.data[i];
                if (point) {
                    const totalValue = (point.y !== null) ? point.custom.formatter.format(point.y) : '';
                    const countColor = point.custom.isLowBase ? 'red' : '#909090';
                    category += `<br><span style="font-size:9px">${totalValue}</span>, <span style="font-size:9px;color:${countColor}">n=${point.custom.count}</span>`;
                }
            });

            return category;
        });
    }

    options(data: TDataBarChart): Partial<Options> {

		const totalSeries = data.totalDatasets
			?.map((dataset, i) => {
				return transformBarChartDataset(
					dataset,
					i,
					dataset.color ?? ChartColors.COLOR_TOTAL,
					data.categories
				);
			});


		const categoriesLabels = this.getCategoriesLabels(data, totalSeries);

		const totalPlotLines = [];
		data.datasets.forEach((dataset, datasetIndex) => {
			if (dataset.displayDataTotalAs === "line") {

				const color = this.getColor(data, dataset, datasetIndex);
				dataset.dataTotal.forEach((point, i) => {
					const plotLine = transformBarChartTotalPointToPlotLine({
						name: categoriesLabels[i] ?? '',
						color: dataset.colorTotal || ChartColors.pickColor(color, i, ChartColors.COLOR_TOTAL),
						point: point,
						previousPoint: data.previousTermDatasets?.[datasetIndex]?.data?.[i],
						dataset: dataset,
						backendDataIndex: datasetIndex,
						data: data,
					});
					totalPlotLines.push(plotLine);
				});
			}
		});

		const series = data.datasets
			.map((dataset, i) => {
				const color = this.getColor(data, dataset, i);
				return transformBarChartDataset(
					dataset,
					i,
					color,
					categoriesLabels,
				);
			})
			.filter(serie => {
				// filter out empty series
				return (<SeriesBarOptions_type>serie).data.some(v => v !== null);
			});

		return {
			series: series,
			yAxis: [{
				plotLines: totalPlotLines
			}]
		};

    }

}
