import IOptions from "../../../shared/highcharts/options/IOptions";
import {Options} from "highcharts";
import barChartAutoHeight from "../barChartAutoHeight";
import TDataBarChart from "../types/TDataBarChart";
import ChartConstants from "../../../shared/highcharts/ChartConstants";

export default class BarChartOptions implements IOptions {

    options(data: TDataBarChart): Partial<Options> {

        return {
            chart: {
                events: {
                    load: function() {
                        barChartAutoHeight(this);
                    }
                },
                plotBorderWidth: 0,
                type: "column",
                zoomType: "y",
                panKey: "shift",
                panning: {
                    "enabled": false, // cannot be combined with endOnTick
                    "type": "y"
                },
                backgroundColor: ChartConstants.PLOT_BACKGROUND,
                style: {
                    fontFamily: ChartConstants.FONT_FAMILY,
                }
            },
        }
    }

}