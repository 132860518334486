import OptionsComposer from "../../shared/highcharts/options/OptionsComposer";
import PieChartOptions from "./options/PieChartOptions";
import CreditsOptions from "../../shared/highcharts/options/CreditsOptions";
import ExportingOptions from "../../shared/highcharts/options/ExportingOptions";
import TitlePieOptions from "./options/TitlePieOptions";
import LegendPieOptions from "./options/LegendPieOptions";
import PlotOptionsPieOptions from "./options/PlotOptionsPieOptions";
import SeriesPieOptions from "./options/SeriesPieOptions";
import TDataPieChart from "./types/TDataPieChart";
import {Options} from "highcharts";
import LowBaseModifier from "../../shared/highcharts/modifiers/LowBaseModifier";
import ChartConstants from "../../shared/highcharts/ChartConstants";
import CustomOptions from "../../shared/highcharts/options/CustomOptions";
import PreviousDatasetPieModifier from "./modifiers/PreviousDatasetPieModifier";
import TargetsPieModifier from "./modifiers/TargetsPieModifier";
import ResponsivePieOptions from "./options/ResponsivePieOptions";
import TooltipPieOptions from "./options/TooltipPieOptions";
import DataSortPieModifier from "./modifiers/DataSortPieModifier";

export default class PieChartTransformer {
    private readonly composer: OptionsComposer;

    constructor() {
        this.composer = new OptionsComposer([
            new PieChartOptions(),

            new CreditsOptions(),
            new ExportingOptions(),
            new TitlePieOptions(),

            new LegendPieOptions(),
            new TitlePieOptions(),
            new SeriesPieOptions(),

            new PlotOptionsPieOptions(),
            new TooltipPieOptions(),
            new ResponsivePieOptions(),

            new CustomOptions(),
        ])
    }

    transform(data: TDataPieChart): Options {
        const lowBase = LowBaseModifier.loadLowBase(data, {base: 'dataset'});

        const options = this.composer.options(data);

        const lowBasePointCallback = ['filter','hide'].includes(lowBase.mode) ? LowBaseModifier.invisibleCallback : p => p.opacity = ChartConstants.LOW_BASE_OPACITY_PIE;
        (new LowBaseModifier(lowBase, {pointCallback: lowBasePointCallback})).modify(options);

        (new TargetsPieModifier(data)).modify(options);
        (new PreviousDatasetPieModifier(data, lowBase)).modify(options);
        (new DataSortPieModifier()).modify(options);


        return options;
    }
}