import ExportToImage from "./ExportToImage";
import translator from "../translator";

export default class ExportDashboardToImage extends ExportToImage
{
    private static BATCH_SIZE() {
        // @ts-ignore
        const isInternetExplorer = window.document.documentMode;
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        return isFirefox || isInternetExplorer ? 4 : 8;
    }

    private static PAGE_SIZE_LIMIT() {
        // @ts-ignore
        return window.document.documentMode ? 5000 : 8000;
    }

    private scroll = [0, 0];
    private scrollBehavior = 'smooth';

    protected init() {
        this.$element.on('click', () => {

            $('#loader').show();
            this.IEBeforeRender();
            this.scroll = [window.scrollX, window.scrollY];
            this.scrollBehavior = document.documentElement.style.scrollBehavior;

            document.documentElement.style.scrollBehavior = 'auto';
            document.documentElement.scrollTop = 0;
            document.documentElement.scrollLeft = 0;

            const cleanUp = () => {
                this.IEAfterRender();
                $('#loader').hide();
                document.documentElement.scrollLeft = this.scroll[0];
                document.documentElement.scrollTop = this.scroll[1];
                document.documentElement.style.scrollBehavior = this.scrollBehavior;
            }

            if (this.$target.height() < ExportDashboardToImage.PAGE_SIZE_LIMIT()) {
                // Render in one piece
                this.render(this.$target[0])
                    .then(canvas => this.saveAs(canvas, this.filename))
                    .finally(() => cleanUp());
            } else {
                // Cut in pieces
                const filesCount = Math.ceil(this.$target.children('.row').length / ExportDashboardToImage.BATCH_SIZE());
                if (filesCount > 1) {
                    // It gonna take soooo loooong, are you sure boy?
                    const confirmMessage = translator.translate('downloadMultipleFiles', {filesCount: String(filesCount)});
                    if (!confirm(confirmMessage)) {
                        cleanUp();
                        return;
                    }
                }

                const groups = [];

                this.$target.children('.row').each((i, row) => {
                    const gi = Math.floor(i / ExportDashboardToImage.BATCH_SIZE());
                    const group = groups[gi] || [];
                    group.push(row);
                    groups[gi] = group;
                });

                const $groups = groups.map(g => {
                    const $group = $('<div class="container-fluid"></div>');
                    g.forEach(row => $(row).detach().appendTo($group));
                    return $group;
                })

                $groups.forEach($group => this.$target.parent().append($group));

                // Render one group after another and clean up groups and document
                (() => {
                    let $group, i = -1;
                    const loop = () => {
                        $group = $groups.shift();
                        i++;
                        if ($group) {
                            this.render($group[0]).then((canvas) => {
                                this.saveAs(canvas, this.filename + '_' + (i + 1));
                                canvas.remove();
                                loop();
                            });
                        } else {
                            // Rollback
                            groups.forEach(group => {
                                group.forEach(row => {
                                    $(row).detach().appendTo(this.$target);
                                });
                            });
                            $groups.forEach($group => $group.remove());

                            cleanUp();
                        }
                    };
                    loop();
                })();

            }

        });
    }



}