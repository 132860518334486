import {RgbaColor} from "./colorToString";

export function colorCrossFade(k: number, a: RgbaColor, b: RgbaColor): RgbaColor {
    if (k > 1) {
        return a;
    }
    if (k < 0) {
        return b;
    }

    const l = 1-k;
    return <RgbaColor>a.map((c, i) => k*a[i] + l*b[i]);
}