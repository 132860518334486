import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import arrayToCsvDownload from "../../../../../js/utility/arrayToCsvDownload";
import TCompareTableData from "../types/TCompareTableData";
import _ from "lodash";
import SelectpickerExtension from "../../../extensions/Selectpicker";



export default class CompareTable extends DashboardComponent
{
    private static PROTECTED_ATTRIBUTES = ['colspan', 'rowspan', 'data-table-row', 'data-table-column'];

    private filters;

    /**
     * Fields in structure fields.row_name.column_name[ i ]
     * (There can be multiple fields with same row_name and column_name)
     */
    private fields: {[row_name:string]: {[column_name:string]: HTMLTableCellElement[]}} = {};

    /**
     * Fields in structure of 2-dimensional array fieldsTable[row_index][column_index]
     */
    private fieldsTable: HTMLTableCellElement[][] = [];

    init() {
        super.init();

        this._$element.find('[data-export-to-csv]').on('click', e => {
            e.preventDefault();
            const filename = $(e.currentTarget).data('export-to-csv');
            const data = this.exportTable();
            arrayToCsvDownload(data, filename);
        });

        this._$element.find('table thead th select').on('change', (e) => {
            this.showHideResetFiltersIcon(<HTMLSelectElement>e.currentTarget);
        }).each((i,select) => this.showHideResetFiltersIcon(<HTMLSelectElement>select));

        this._$element.find('[data-reset-filter]').on('click', (e) => {
            const $select = $(e.currentTarget).closest('th').find('select');
            $select.val('');
            $select.trigger('change');
        });

        this.loadFields(); // heavy operation
    }

    onUpdate(data: TCompareTableData) {
        this.updateTableRows(data);
        this.updateTableFilters(data);
        this.updateFreezeColumns(data);
    }

    private loadFields(): void {
        const $tbody = this.getTableElement().children('tbody');
        const rows = Array.from($tbody[0].children);
        rows.forEach((row, y) => {
            const cells = Array.from(row.children);
            cells.forEach((cell: HTMLTableCellElement,x) => {
                const rowName = cell.dataset['tableRow'];
                if (!rowName) {
                    return;
                }
                const columnName = cell.dataset['tableColumn'];
                if (!columnName) {
                    return;
                }
                this.fields[rowName] ??= {};
                this.fields[rowName][columnName] ??= [];
                this.fields[rowName][columnName].push(cell);

                this.fieldsTable[y] ??= [];
                this.fieldsTable[y][x] = cell;
            });
        })
    }

    private updateFreezeColumns(data: TCompareTableData): void {
        const freezeColumn = data.freezeColumn;

        if (freezeColumn === false || freezeColumn === null) {
            return;
        }

        if (freezeColumn === true) {
            this.fieldsTable.forEach(row => row?.[0]?.classList.add('freezeColumn'));
        }

        if (typeof freezeColumn === 'number') {
            this.fieldsTable.forEach(row => row?.[freezeColumn]?.classList.add('freezeColumn'));
        }

        if (freezeColumn === 'string') {
            Object.values(this.fields).forEach(row => row?.[freezeColumn]?.forEach(cell => cell?.classList.add('freezeColumn')));
        }
    }

    private showHideResetFiltersIcon(select: HTMLSelectElement): void {
        const $resetFilter = $(select).closest('th').find('[data-reset-filter]')
        $resetFilter.css('visibility', select.value ? 'visible' : 'hidden');
    }

    private getTableElement(): JQuery {
        return $(this._$element).find('[data-table]');
    }

    private updateTableRows(data: TCompareTableData): void {

        for (let variable in data.rows) {
            const row = data.rows[variable];
            for (let column in row) {

                const fields = this.fields[variable]?.[column];
                if (!fields) {
                    continue;
                }

                fields.forEach(field => this.resetField(field));
                const fieldData = row[column] || null;

                if (fieldData) {
                    const innerText = typeof fieldData === 'object' ? fieldData['value'] ?? '' : fieldData;
                    fields.forEach(field => field.innerText = ''+innerText);

                    if (fieldData['attributes'] !== undefined) {
                        for (let attr in fieldData['attributes']) {
                            if (!CompareTable.PROTECTED_ATTRIBUTES.includes(attr)) {
                                fields.forEach(field => field.setAttribute(attr, fieldData['attributes'][attr]));
                            } else {
                                console.warn(`CompareTable: Trying to override protected attribute "${attr}" with value "${fieldData['attributes'][attr]}". Not allowed.`, this)
                            }
                        }
                    }
                } else {
                    fields.forEach(field => field.innerText = '');
                }
            }
        }
    }

    private updateTableFilters(data: TCompareTableData): void {
        if (!this.filters || _.isEqual(this.filters, data.filters)) {
            this.filters = data.filters;
            return;
        }
        this.filters = data.filters;

        const addOptions = ($el: JQuery, option) => {
            $el.append($(`<option value="${option.id}">${option.label}</option>`));
        }
        const addGroups = ($el: JQuery, group) => {
            const $group = $(`<optgroup label="${group.label}" />`);
            $el.append($group);
            group.groups?.forEach(group => addGroups($group, group));
            group.options?.forEach(option => addOptions($group, option));
        }
        const fillSelect = ($select: JQuery, filter) => {
            filter.groups?.forEach(group => addGroups($select, group));
            filter.options?.forEach(option => addOptions($select, option));
        }

        const selectPickerExtension = new SelectpickerExtension();
        const $table = this.getTableElement();
        $table.find('select').selectpicker('destroy');

        data.filters.forEach(filter => {
            const $th = $table.find(`th[data-filter="${filter.id}"]`);
            const $select = $th.find(`select`).first();
            const value = $select.val();
            $select.empty();
            $select.addClass('selectpicker');
            fillSelect($select, filter);
            $select.val(value);
            this.showHideResetFiltersIcon($select.get(0));
            selectPickerExtension.attach($th);

        });



    }

    registerOwnFilterChange() {
        const self = this;
        const form = self._$ownFilter.find('form');

        form.on('change submit', function(e) {
            e.preventDefault();
            self._componentManager.applyComponentFilter(self, new FormData(this));
        });

    }

    private exportTable(): string[][] {

        const data = [];
        const $table = this._$element.find('table');
        const $thead = $table.find('thead');

        // Header
        const headerRow = [];
        const comparisonObjects = [];
        $thead.find('select:not([multiple]) option[selected]').each((i,option) => {
            comparisonObjects.push($(option).text());
        });
        $thead.find('tr:last-child th').each((i,cell) => {
            const $cell = $(cell);
            const $options = $cell.find('select[multiple] option[selected]').toArray();
            const $label = $cell.find('label');
            const text = $options.map(o => $(o).text()).filter(t => t).join(', ') || $label.text() || $cell.text() || '';
            headerRow.push(text.trim());
        });
        headerRow[0] = comparisonObjects.join(' x ');
        data.push(headerRow);

        // Table data
        const $tbody = $table.find('tbody');
        $tbody.find('tr').each((i,row) => {
            const exportRow = [];
            const $row = $(row);
            $row.find('td, th').each( (j,cell) => {
                const $cell = $(cell);
                const colspan = $cell.attr('colspan') || 1;
                let text;
                if ($cell.is('td.ico-arrow-down-bold')) {
                    text = '⬇'
                } else if ($cell.is('td.ico-arrow-up-bold')) {
                    text = '⬆'
                } else {
                    text = $cell.text();
                }
                text = (text || '').trim();

                for (let k=0; k<colspan; k++) {
                    exportRow.push(text);
                }
            });

            if (exportRow.some(v => v)) {
                data.push(exportRow);
            }
        });

        return data;
    }

    private resetField(field: Element): void {
        if (field.hasAttributes()) {
            Array.from(field.attributes).forEach(attr => {
                const name = attr.name;
                if (!CompareTable.PROTECTED_ATTRIBUTES.includes(name)) {
                    field.removeAttribute(name);
                }
            });
        }
    }
}

ComponentManager.registerComponentClass('CompareTable', CompareTable);