import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import Highcharts from 'highcharts';
import BaseHighchartsComponent from "../../BaseHighchartsComponent";
import TDataBarChart from "./types/TDataBarChart";
import BarOptionsTransformer from "./BarOptionsTransformer";
import BarChartConstants from "./BarChartConstants";

export default class BarChart extends BaseHighchartsComponent {

    private readonly optionsTransformer: BarOptionsTransformer

    constructor(
        componentManager: ComponentManager,
        $element: JQuery<HTMLElement>,
        name: string,
        options: {[name: string]: any}
    ) {
        super(componentManager, $element, name, options);
        this.optionsTransformer = new BarOptionsTransformer();
    }

    init() {
        super.init();
    }

    protected toggleDataLabels() {
        super.toggleDataLabels();
        if (this.chart?.options.chart.type === 'column') {
            const checked = this.$toggleDataLabels.is(':checked');
            this.chart.update({
                yAxis: [{
                    maxPadding: checked ? BarChartConstants.COLUMN_Y_AXIS_MAX_PADDING_WITH_LABELS : this._data?.chart?.yAxis?.[0]?.maxPadding ?? BarChartConstants.COLUMN_Y_AXIS_MAX_PADDING,
                    max: checked ? null : this._data?.chart?.yAxis?.[0]?.max ?? null,
                    endOnTick: checked ? false : this._data?.chart?.yAxis?.[0]?.endOnTick ?? false,
                }]
            }, undefined, undefined, false);
        }
    }

    private resolveCommonHeight(data: TDataBarChart): void {
        if (data.chart?.chart?.type === 'bar') {
            this._$element.removeClass('commonHeight');
        } else {
            this._$element.addClass('commonHeight');
        }
    }

    public onUpdate(data: TDataBarChart): void {
        this.resolveCommonHeight(data);

        this.chart?.destroy();

        const chartOptions = this.optionsTransformer.transform(data);
        this.validate(chartOptions);

        this.chart = Highcharts.chart(this._$element.find('[data-chart]')[0], chartOptions);
    }
}

ComponentManager.registerComponentClass('BarChart', BarChart);
