export default class Resizer {
    private readonly $el: JQuery;
    private readonly $control: JQuery;
    private readonly $background: JQuery;

    private startY: number|null = null;
    private startHeight: number;
    private minHeight: number;

    private bottomEdgeTimeout?: number;

    constructor(
        private readonly $container: JQuery,
        private readonly $target: JQuery,
        private readonly onResize?: (e) => any,
    ) {
        this.$el = $('<div class="resizer d-print-none" data-html2canvas-ignore></div>');
        this.$control = $('<div class="resizer-control"></div>');
        this.$background = $('<div class="resizer-background"></div>');

        this.$el.append(this.$control);
        this.$el.append(this.$background);
        this.$container.append(this.$el);
        this.startHeight = this.$target.height();
        this.minHeight = this.startHeight;

        this.attachEvents();
    }

    private attachEvents(): void {
        this.$control.on('pointerdown', e => this.onStart(e));

        this.$el.on('pointermove', e => this.onMove(e))
            .on('pointerup', (e) => this.onEnd(e))
            .on('pointerleave', (e) => this.onEnd(e));
    }

    private onStart(e): void {
        this.$el.addClass('active');
        this.startY = e.pageY;
        this.startHeight = this.$target.height()
        if (!this.minHeight) {
            this.minHeight = this.startHeight;
        }
    }

    private onMove(e): void {
        if (this.startY === null) {
            return;
        }
        if (e.clientY > window.innerHeight - 10) {
            this.onBottomEdge(Math.round((10+e.clientY-window.innerHeight)));
        } else {
            if (this.bottomEdgeTimeout) {
                window.clearTimeout(this.bottomEdgeTimeout);
            }
            const newHeight = this.startHeight + e.pageY - this.startY;
            this.setHeight(newHeight);
        }
        this?.onResize(e);

    }

    private onBottomEdge(speed: number): void {

        if (this.bottomEdgeTimeout) {
            window.clearTimeout(this.bottomEdgeTimeout);
        }
        window.scrollTo(window.scrollX, window.scrollY+speed);
        this.setHeight(this.$target.height()+speed);
        this.bottomEdgeTimeout = window.setTimeout(() => this.onBottomEdge(speed), 16.6);
    }

    private onEnd(e): void {
        if (this.bottomEdgeTimeout) {
            window.clearTimeout(this.bottomEdgeTimeout);
        }
        if (this.startY === null) {
            return;
        }
        this.$el.removeClass('active');
        this.startY = null;
        $(window).trigger('resize');
        this?.onResize(e);
    }

    private setHeight(height): void {
        const maxHeight = Math.max(this.minHeight*2, $(window).height());
        this.$target.height(Math.min(Math.max(height, this.minHeight), maxHeight));
    }

}
