import IOptions from "./IOptions";
import {Options} from "highcharts";
import TDataBarChart from "../../../BarChart/js/types/TDataBarChart";

export default class LegendOptions implements IOptions {
    options(data: any): Partial<Options> {

        return {
            legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
                floating: false,
                useHTML: true,
                itemStyle: {
                    fontWeight: "normal"
                },
            },
        }

    }

}
