export default class ChartConstants {
    public static readonly PRINT_WIDTH = 958;
    public static readonly PRINT_HEIGHT = 410; // it could be same in PieChart.scss
    public static readonly LOW_BASE_OPACITY_BAR = 0.15;
    public static readonly LOW_BASE_OPACITY_LINE = 0.1;
    public static readonly LOW_BASE_OPACITY_PIE = 0.3;
    public static readonly LOW_BASE_BORDER_WIDTH = 1;
    public static readonly PLOT_BACKGROUND = '#FDFDFD';
    public static readonly CROSS_HAIR_COLOR = 'rgba(0,0,15,0.06)';
    public static readonly FONT_FAMILY = 'Roboto, sans-serif';
}