import BaseDashboardComponent from "../../BaseDashboardComponent";
import ComponentManager from "nms-dashboard-js/src/ComponentManager";
import Filter from "../../../Board/src/components/Filter";
import AddFavouriteFilterModal from "./AddFavouriteFilterModal";
import translator from "../../../translator";

type TClassification = {
    type: 'checkbox'|'range'|string,
    name: string,
    selected: TOption[], // selected option names
    notSelected: TOption[], // not selected option names
};

type TOption = {
    id: string,
    label: string,
};

type TTerm = {
    from: string,
    to: string,
};

type TActiveFilterData = {
    term: TTerm,
    classifications: TClassification[],
    description: string,
};


export default class ActiveFilters extends BaseDashboardComponent {

    private $favouriteButton: JQuery<HTMLElement>;
    private $expandButton: JQuery<HTMLElement>;
    private isFavouriteEnabled = false;
    private keepPresetFilterName;

    constructor(componentManager: ComponentManager, $element: JQuery<HTMLElement>, name: string, options: { [p: string]: any }) {
        super(componentManager, $element, name, options);

        this.$favouriteButton = $element.find('[data-favourite-filter]');
        this.$expandButton = $element.find('[data-expand-card-body]');
    }

    public init(): void {
        this.$favouriteButton.on('click', () => {
            if (this.isFavouriteEnabled) {
                this.openAddFavouriteModal();
            }
        });

        this.$expandButton.on('click', () => {
            this._$element.toggleClass('expanded');
        });

        const $reset = this._$element.find('[data-reset-filter]');
        $reset.on('click', () => this.resetAll());
    }

    update(data: TActiveFilterData) {
        super.update(data);

        this._$element.find('.active-filter-description').text(data.description);

        const $content = this._$element.find('.active-filters-content');
        $content.html('');

        if (data.classifications?.length) {
            data.classifications.forEach(c => $content.append(this.createClassification(c)));
            this.enableFavourite();
        } else {
            $content.append(`<p class="text-muted mb-0">${translator.translate('no_active_filters')}</p>`);
            this.disableFavourite();
        }

        const $presetFilterName = this._$element.find('[data-preset-filter-name]');

        if (this.keepPresetFilterName !== $presetFilterName.text()) {
            this.keepPresetFilterName = $presetFilterName.text();
        } else {
            $presetFilterName.html('');
            this.keepPresetFilterName = false;
        }
    }


    // View / Create elements
    private createReset(): JQuery<HTMLElement> {
        return $(`<div class="action-reset" title="${translator.translate('reset_filter')}" data-html2canvas-ignore><i class="icon ico-close-round"></i></span>`);
    }

    private createClassification(classification: TClassification): JQuery<HTMLElement> {
        const $reset = this.createReset();
        $reset.on('click', () => this.resetFilter(classification) );

        const $pill = $(`<div class="active-filter"></div>`);
        $pill.append(this.createLabel(classification));
        $pill.append($reset);

        return $pill;
    }

    private createLabel(classification: TClassification): JQuery<HTMLElement> {
        let label, count;
        switch (classification.type) {
            case 'range': {
                label = classification.name;
                count = '';
                break;
            }
            default: {
                label = classification.name+': ';
                if (classification.selected.length + classification.notSelected.length > 6 && classification.notSelected.length <= 2) {
                    label = translator.translate('all_without') + ' ' + classification.notSelected.map(o => o.label).join(', ');
                } else {
                    label += classification.selected.map(o => o.label).join(', ');
                }
                count = `(${classification.selected.length})`;
            }
        }

        const $label = $(`<div class="active-filter-inner" title="${label}"><span class="active-filter-label">${label}</span><span class="active-filter-count" data-html2canvas-ignore>${count}</span></span>`);
        $label.tooltip();

        return $label;
    }


    // Controller
    private resetFilter(classification: TClassification): void {
        this.getFilterComponent().resetOptions(
            [...classification.notSelected, ...classification.selected].map( option => option.id )
        );
    }

    private resetAll(): void {
        this.getFilterComponent().resetPeriod();
        this.getFilterComponent().reset();
    }

    private enableFavourite(): void {
        this.isFavouriteEnabled = true;
        this.$favouriteButton.removeClass('disabled');
        this.$favouriteButton.tooltip('dispose');
        this.$favouriteButton.attr('title', translator.translate('save_current_filter'))
        this.$favouriteButton.tooltip();
    }

    private disableFavourite(): void {
        this.isFavouriteEnabled = false;
        this.$favouriteButton.addClass('disabled');
        this.$favouriteButton.tooltip('dispose');
        this.$favouriteButton.attr('title', translator.translate('save_current_filter_set_filter_first'))
        this.$favouriteButton.tooltip();
    }

    // Helpers
    private getFilterComponent(): Filter|never {
        // @ts-ignore
        if ($.components?.board?.filter) {
            // @ts-ignore
            return $.components?.board?.filter;
        } else {
            // @ts-ignore
            console.error('Filter component not found (in Active filters component).', $.components?.board || "(there should be Board component, but it was not found)")
        }
    }


    // Modals
    private openAddFavouriteModal() {
        const modal = new AddFavouriteFilterModal();
        modal.render();
    }
}

ComponentManager.registerComponentClass('ActiveFilters', ActiveFilters);
