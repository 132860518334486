import FilterModal from "../FilterModal";
import m, {Vnode, VnodeDOM} from "mithril";
import flatpickr from "flatpickr";
import translator from "../../../../../translator";
import getFlatpickrLocale from "../../../../../extensions/Flatpickr/js/getFlatpickrLocale";

export default class CalendarModal extends FilterModal {

    private period = '';

    public readonly parts = {
        header: true,
        body: true,
        footer: true,
    };

    protected extendModalClass(): string {
        return ".modal-calendar";
    }

    protected viewBody(): Vnode|null {
        return m('.modal-calendar-body', {}, [
            m('.', {
                oncreate: (vnode: VnodeDOM) => this.createCalendar(vnode),
            }),
        ]);
    }

    private createCalendar(vnode: VnodeDOM): void {

        flatpickr(vnode.dom, {
            mode: "range",
            inline: true,
            positionElement: vnode.dom,
            onChange: (selectedDates) => {
                if (selectedDates.length !== 2) {
                    return;
                }
                const [begin, end] = selectedDates;
                end.setSeconds( end.getSeconds() + 86399 );
                this.period = this.dateFormat(begin)+'_'+this.dateFormat(end);
            },
            locale: getFlatpickrLocale(),
        });
    }

    protected viewFooter(): Vnode[] {
        return [
            m('button.btn.btn-secondary', {
                type: "button",
                onclick: (e) => {
                    this.onBackgroundClick(e);
                },
            }, translator.translate('cancel')),
            m('button.btn.btn-primary', {
                type: "button",
                onclick: (e) => {
                    this.save();
                    this.onBackgroundClick(e);
                },
            }, translator.translate('apply')),
        ];
    }

    protected viewHeader(): Vnode[] {
        return [
            m('h4', translator.translate('calendar')),
            m('button.close', {
                onclick: e => this.onBackgroundClick(e),
            }, [
                m('span', {}, m.trust('&times;'))
            ]),
        ]
    }


    private save() {
        if (this.period) {
            this.filter.list().clearPeriods();
            this.filter.list().setShortenedPeriodState(this.period);
            this.filter.triggerChange(0);
            this.filter.list().setPeriodState(this.period, true);
        }
    }

    private dateFormat(d): string {
        return '' + d.getFullYear()
            + ("0" + (d.getMonth()+1)).slice(-2)
            + ("0" + d.getDate()).slice(-2)
            + ("0" + d.getHours()).slice(-2)
            + ("0" + d.getMinutes()).slice(-2)
            + ("0" + d.getSeconds()).slice(-2);
    }
}