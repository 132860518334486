export default class Loader
{
    private onShow = [];
    private onHide = [];

    constructor(
        public readonly $element?: JQuery
    ) { }

    addOnShowListener(listener: ()=>any) {
        this.onShow.push(listener);
    }

    addOnHideListener(listener: ()=>any) {
        this.onHide.push(listener);
    }

    show(): void {
        this.$element?.show();
        this.onShow.forEach(listener => listener());
    }

    hide(): void {
        this.$element?.hide();
        this.onHide.forEach(listener => listener());
    }
}
