import IModifier from "../../../shared/highcharts/modifiers/IModifier";
import {Options, PlotSeriesOptions} from "highcharts";
import ExtendedPointOptionsObject from "../../../shared/highcharts/ExtendedPointOptionsObject";

function getValueFromPoint(point: ExtendedPointOptionsObject, path: string): any {
    const steps = path.split('.');
    if (steps.length === 1) {
        return point[path];
    } else if (steps.length > 1) {
        return point[steps[0]]?.[steps[1]];
    }
    return undefined;
}

export default class DataSortPieModifier implements IModifier {
    modify(chartOptions: Options) {
        chartOptions.series?.forEach((serie: any) => {
            if ( String(serie.name).startsWith('__') ) { // generated series
                return;
            }

            const enabled = serie.dataSorting?.enabled
                ?? (<PlotSeriesOptions>chartOptions.plotOptions?.pie)?.dataSorting?.enabled
                ?? chartOptions.plotOptions?.series?.dataSorting?.enabled
                ?? false;

            if (!enabled) {
                return;
            }

            const sortKey = serie.dataSorting?.sortKey
                ?? (<PlotSeriesOptions>chartOptions.plotOptions?.pie)?.dataSorting?.sortKey
                ?? chartOptions.plotOptions?.series?.dataSorting?.sortKey
                ?? 'y';

            serie.data?.sort?.((a: ExtendedPointOptionsObject, b: ExtendedPointOptionsObject) => {
                 const valueA = getValueFromPoint(a, sortKey);
                 const valueB = getValueFromPoint(b, sortKey);
                 if (typeof valueA === "number" && typeof valueB === "number") {
                     return valueB - valueA;
                 }
                 return String(valueB).localeCompare(String(valueA));
            });

        })
    }
}