import Modal from "../../shared/Modal";
import Api from "../../../Board/src/model/Api";
import Filter from "../../../Board/src/components/Filter";
import translator from "../../../translator";

export default class AddFavouriteFilterModal {

    private readonly modal: Modal;

    private static RANDOM_EMOTICON(): string {
        const emoticons = ['🍉', '🥕', '👏', '👍', '🦠', '🍅', '🍓', '🧐', '👌', '👾', '👀', '🐱', '🐭', '🦊', '🪲', '🦟', '🐬', '🐘', '🍍', '🥑', '🍔', '🍺', '⚽', '🏄','🎧', '🚗', '🔨', '📕', '📘'];
        return emoticons[Math.round(Math.random()*emoticons.length)];
    }

    constructor() {
        const defaultName = translator.translate('preset_filter')+' '+AddFavouriteFilterModal.RANDOM_EMOTICON();
        const $content = $(`<div>
            <div class="highlight highlight-info mb-3">
                ${translator.translate('save_current_filter_hint')}
            </div>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="${defaultName}">
            </div>    
        </div>`);

        this.modal = new Modal(
            translator.translate('save_current_filter'),
            $content,
            translator.translate('cancel'),
            translator.translate('save'),
        );

        this.modal.setOnAfterRender($modal => $modal.find('input[type="text"]')[0].focus());

        this.modal.setOnOk( () => {
            const name = $content.find('input').val() || defaultName;
            // @ts-ignore
            const api: Api = $.components?.board?.api;
            // @ts-ignore
            const filter: Filter = $.components?.board?.filter;

            if (!api || !filter) {
                throw new Error('Internal error: Cannot save filters. Api nor Filter not found in Board component.');
            }

            const $loader = $('#loader');
            $loader.show();
            api.saveFavouriteFilter(String(name))
                .then( storedFilter => filter.board.reloadFilter (
                    undefined,
                    f => f.list().setFavouriteState(storedFilter.uuid)
                ) )
                .finally( () => $loader.hide() );
        });


    }

    public render(): void {
        this.modal.render();
    }

}
