import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';

export default class Dummy extends DashboardComponent {
    private static instanceInitializedCounter = 1;

    init() {
        super.init();
        setTimeout(
            () => this._$element.parent().addClass('animate'),
            Dummy.instanceInitializedCounter * 220
        );
        Dummy.instanceInitializedCounter++;
    }
}

ComponentManager.registerComponentClass('Dummy', Dummy);