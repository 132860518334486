import {Chart} from "highcharts";
import fullscreenConditionAndChartOptions from "../responsive/fullscreenConditionAndChartOptions";


function setupFullscreenChart(chart: Chart): void {
    const responsiveRules = chart.options.responsive?.rules || [];
    responsiveRules.unshift(fullscreenConditionAndChartOptions());
    chart.update({
        responsive: {
            rules: responsiveRules,
        }
    });
};

function unsetupFullscreenChart(chart: Chart): void {
    const responsiveRules = chart.options.responsive?.rules || [];
    responsiveRules.pop();
    chart.update({
        responsive: {
            rules: responsiveRules,
        }
    });
};

/**
 * Toggle fullscreen of chart and add control to exit fullscreen
 * @param chart
 */
export default function actionToggleFullscreen(chart: Chart): void {

    const $container = $(chart.container).closest('.chart');

    // remove controls on exit fullscreen
    const onFullscreenChange = () => {
        if (!document.fullscreenElement) {
            unsetupFullscreenChart(chart);
            $container.find('.fullscreen-controls').remove();
            document.removeEventListener('fullscreenchange', onFullscreenChange);
        }
    }

    // create controls on enter to fullscreen
    const createFullscreenControls = () => {
        const $controls = $('<div class="fullscreen-controls"></div>');
        const $close = $('<button class="btn btn-default"><i class="icon ico-close"></i></button>');
        $close.on('click', () => {
            // remove listenter
            document.removeEventListener('fullscreenchange', onFullscreenChange);
            // handle fullscreen - toggle chart fullscreen and remove controls

            unsetupFullscreenChart(chart);
            // @ts-ignore
            chart.fullscreen.toggle();
            $controls.remove();
        });

        $controls.append($close);
        $container.append($controls);
    }

    setupFullscreenChart(chart);
    // @ts-ignore
    chart.fullscreen.toggle();

    createFullscreenControls();
    document.addEventListener('fullscreenchange', onFullscreenChange);
}
