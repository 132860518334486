// Dashboard components and manager
import './dashboardsComponents/AnswersTable';
import './dashboardsComponents/Blank';
import './dashboardsComponents/Dummy';
import './dashboardsComponents/EncodedOpenQuestionFrequencyTable';
import './dashboardsComponents/ResponseRate';
import './dashboardsComponents/AlertSummary';
import './dashboardsComponents/SectionHeader';
import './dashboardsComponents/VarValue';
import './dashboardsComponents/RankTable';
import './dashboardsComponents/CompareTable';
import './dashboardsComponents/KbTable';
import './dashboardsComponents/Map';
import './dashboardsComponents/ActiveFilters';
import './dashboardsComponents/ResponseRateTable';
import './dashboardsComponents/ClientSpecificCsobPositiveFeedbackEmail';

// Highcharts based charts
import './dashboardsComponents/ScatterChart';
import './dashboardsComponents/WordCloud';
import './dashboardsComponents/SankeyDiagram';
import './dashboardsComponents/RangeChart';
import './dashboardsComponents/LineChart';
import './dashboardsComponents/PieChart';
import './dashboardsComponents/BarChart';

import componentManager from 'nms-dashboard-js';
import Filter from "../src/Board/src/components/Filter";
import Loader from "./Loader";
import ScrollKeeper from "../../js/utility/ScrollKeeper";


$(function() {
    const scrollKeeper = new ScrollKeeper('.content-wrap .container-fluid.real-content > .row');
    const loader = new Loader();
    loader.addOnShowListener(() => scrollKeeper.save());
    loader.addOnHideListener(() => scrollKeeper.load());
    $(window).on('unload', () => scrollKeeper.save());

    if ($.components['board']) {

        const board = $.components['board'];
        const $boardElement = board.$element;

        // rewrite get filter data function of componentManager
        // return format must implement entries() method
        componentManager.getFilterData = () => {
            const form_data = new FormData();
            if (!board.filter) {
                return form_data;
            }

            const data = board.filter.getData();

            for (const period of data.periods) {
                form_data.append('period', period);
            }

            for (const option of data.options) {
                form_data.append('options', option);
            }

            return form_data;
        };

        componentManager.setAjaxTransport($.nette.ajax.bind($.nette));
        componentManager.setAttributeNamespace('db');
        componentManager.setFilter($boardElement);
        componentManager.setDataEndpoint($boardElement.data('endpoint'));
        componentManager.setLoadingText($boardElement.data('loading-text'));
        componentManager.activateDataStorage();
        componentManager.setSendInitialRequest(componentManager.isDataStorageUsed());
        componentManager.run(document.body);

        $boardElement.on(Filter.CHANGE_EVENT, (e, data) => {

            board.onBeforeFilter();
            loader.show();

            componentManager.applyFilter().done(e => {
                $('.dummy-content').remove();
                $('.real-content').css('opacity', '');
                const url = $boardElement.data('refresh-filter-link');
                loader.hide();
                if (url !== undefined) {
                    $boardElement.netteAjax(e, {
                        url: url,
                        complete: () => {
                            loader.hide();
                            board.onAfterFilter();
                        }
                    });
                }
            });
        });
    }
});
