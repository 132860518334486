import "./shared/highcharts/style/ChartDataContainer.scss";

import BaseDashboardComponent from "./BaseDashboardComponent";
import {Chart} from "highcharts";
import Highcharts from 'highcharts';
import actionToggleFullscreen from "./shared/highcharts/actions/actionToggleFullscreen";
import actionToggleDataLabels from "./shared/highcharts/actions/actionToggleDataLabels";
import CsvExporter from "./shared/highcharts/actions/CsvExporter";
import TableExporter from "./shared/highcharts/actions/TableExporter";
import ComponentManager from "nms-dashboard-js/src/ComponentManager";
import scrollToElementOnDashboard from "../../../js/utility/scrollToElementOnDashboard";
import Resizer from "./shared/Resizer";
import Board from "../Board";
import IValidator from "./shared/validators/IValidator";
import LowBaseModifier from "./shared/highcharts/modifiers/LowBaseModifier";

const HighchartsAccessibility = require('highcharts/modules/accessibility');
HighchartsAccessibility(Highcharts);
const HighchartsExporting = require('highcharts/modules/exporting');
HighchartsExporting(Highcharts);
const HighchartsExportData = require('highcharts/modules/export-data');
HighchartsExportData(Highcharts);
const HighchartsAnnotation = require('highcharts/modules/annotations');
HighchartsAnnotation(Highcharts);
const HighchartsRegression = require('highcharts-regression');
HighchartsRegression(Highcharts);
const HighchartsWordCloud = require('highcharts/modules/wordcloud');
HighchartsWordCloud(Highcharts);
const HighchartsSankey = require('highcharts/modules/sankey');
HighchartsSankey(Highcharts);
const HighchartsMore = require('highcharts/highcharts-more');
HighchartsMore(Highcharts);
import "./shared/highcharts/serieType/rangeSerieType";


export default abstract class BaseHighchartsComponent extends BaseDashboardComponent {

    protected $toggleDataLabels: JQuery<HTMLInputElement>;
    protected csvExporter: CsvExporter;
    protected tableExporter: TableExporter;

    protected chart: Chart;
    protected validators: IValidator[] = [];

    constructor(
        componentManager: ComponentManager,
        $element: JQuery<HTMLElement>,
        name: string,
        options: {[name: string]: any}
    ) {
        super(componentManager, $element, name, options);
        this.tableExporter = new TableExporter(this._$element.find('[data-export-table-target]').first(), Board.instance?.options.settings.lowBase);
        this.csvExporter = new CsvExporter();
        this.$toggleDataLabels = <JQuery<HTMLInputElement>>this._$element.find('.toggle-datalabels');
    }

    public init(): void {
        super.init();

        $(window).on('beforeprint', () => {
            this.chart?.setSize(null, null, false);
            this.chart?.reflow();
        }).on('afterprint', () => {
            setTimeout(() => {
                this.chart?.setSize(null, null, false);
                this.chart?.reflow();
            }, 1);
        });

        // Buttons
        this.$toggleDataLabels.on('change', () => {
            this.toggleDataLabels();
        }).trigger('change');
        this._$element.find('[data-chart-toggle-fullscreen]').on('click', (e) => {
            e.preventDefault();
            this.toggleFullscreen();
        });
        this._$element.find('[data-chart-export-csv]').on('click', (e) => {
            e.preventDefault();
            this.exportCsv(e.currentTarget.dataset.chartExportCsv);
        });
        this._$element.find('[data-chart-toggle-table]').on('click', (e) => {
            e.preventDefault();
            this.toggleTable();
        });
        this.addResizer(this._$element.find('[data-chart]'));
    }


    protected toggleDataLabels(): void {
        actionToggleDataLabels(this.chart, this.$toggleDataLabels);
    }

    protected toggleFullscreen(): void {
        actionToggleFullscreen(this.chart);
    }

    protected toggleTable(): void {
        if (this.tableExporter.toggle(this.chart)) {
            scrollToElementOnDashboard(this._$element);
        }
    }

    protected exportCsv(filename?: string): void {
        this.csvExporter.export(this.chart, filename);
    }

    protected addResizer($chart: JQuery): void {
        new Resizer(this._$element, $chart, () => this.chart.reflow());
    }

    protected validate(chartOptions: any): void {
        this.validators.map(v => v.validate(chartOptions))
            .forEach(judgment => judgment && this.throwError(...judgment));
    }

    update(data) {
        super.update(data);
        this.toggleDataLabels();
        this.tableExporter.setLowBase(LowBaseModifier.loadLowBase(data));
        this.tableExporter.update(this.chart);
        setTimeout(() => this.chart?.reflow(), 1); // fit chart to its container
    }

    public registerOwnFilterChange(): void {
        this._$ownFilter.find('form').on('change submit', (e) => {
            e.preventDefault();
            this._componentManager.applyComponentFilter(this, new FormData(e.currentTarget));
        });

        // because of printilo
        this._$ownFilter.find('form').find('input[type="radio"]').on('change', (e) => {
            const $form = $(e.currentTarget).closest('form');
            $form.find('input[type="radio"]:not(:checked)').removeAttr('checked');
            $form.find('input[type="radio"]:checked').attr('checked', 'checked');
        });
    }

}
