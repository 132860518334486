import SidebarItemTile from "./SidebarItemTile";
import {TFilterValueTileMatrix} from "../../../model/filterTypes";
import m, {Vnode} from "mithril";
import FilterItemTilesMatrix from "../FilterItemTilesMatrix";

export default class SidebarItemTileMatrix extends SidebarItemTile {

    constructor(
        filterItem: FilterItemTilesMatrix,
        definition: TFilterValueTileMatrix,
    ) {
        super(filterItem, definition)
    }

    view(): Vnode|null {
        return m(`.option[data-id="${this.definition.id}"]`, {
            tabindex: "1",
            onclick: () => this.toggleState(),
        }, [
            m('label', {
                style: this.definition.style || '',
            }, m.trust(this.definition.label))
        ])
    }
}