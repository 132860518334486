import IPrintChange from "./IPrintChange";

export default class TableAllRowsVisible implements IPrintChange {
    prepare(): void {
        const $body = $('body');
        $body.addClass('print-tables-visible-all-rows');
    }

    revert(): void {
        const $body = $('body');
        $body.removeClass('print-tables-visible-all-rows');
    }

}