import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';

export default class SectionHeader extends DashboardComponent
{
    init()
    {
        super.init();
    }
}

ComponentManager.registerComponentClass('SectionHeader', SectionHeader);