import m, {Vnode, VnodeDOM} from "mithril";
import {TFilterItemTilesRow} from "../../model/filterTypes";
import Filter from "../Filter";
import IFilterComponent from "../IFilterComponent";
import ISearchable from "./ISearcheable";
import FilterItemTiles from "./FilterItemTiles";
import SidebarItemTileRow from "./tile/SidebarItemTileRow";

export default class FilterItemTilesRow extends FilterItemTiles implements IFilterComponent, ISearchable {

    constructor(
        definition: TFilterItemTilesRow,
        filter: Filter,
        parent?: IFilterComponent
    ) {
        super(definition, filter, parent);

        definition.values?.forEach(valueDefinition => this.subTiles.push(
            new SidebarItemTileRow(this, valueDefinition)
        ));
    }

    viewModalPanel(): Vnode | null {
        return null;
    }

    viewModalSidebar(): Vnode | null {
        return null;
    }

    viewSidebar(): Vnode | null {
        return m('.FilterItem.FilterItem-TilesRow', {}, [
            m('.item', {}, [
                m('.item-label', {}, [
                    m('label', {}, [
                        m.trust(this.definition.label)
                    ])
                ]),
                m('.control', {}, [
                    m('.tiles', {
                        oncreate: (vnode: VnodeDOM) => {
                            const $el = $(<HTMLElement>vnode.dom);
                            $el.tooltip({
                                html: true,
                                trigger: "hover",
                                placement: "top",
                                title: () => this.getTilesTitle(),
                            });
                        }
                    }, this.subTiles.map(st => m(st)))
                ])
            ])
        ]);
    }

    private getTilesTitle(): string {
        const optionData = this.definition.values?.map(vd => {
            const n = this.filter.list().getOptionData(vd.id)?.count;
            return `<div><span>n=${n ?? 0}</span><span>${vd.label}</span></div>`
        });
        return `<strong>${this.definition.label}</strong><div class="filter-count-table">${optionData.join('')}</div>`;
    }

}
