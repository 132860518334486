import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';

export default class ResponseRate extends DashboardComponent
{
    init() {
        this.$total = this._$element.find('.response-rate-total');
        this.$usable = this._$element.find('.response-rate-usable');
        this.totalTitleTemplate = this.$total.data('title') || '';
        this.$published = this._$element.find('.response-rate-published');
        this.publishedTitleTemplate = this.$published.data('title') || '';
        this.$finished = this._$element.find('.response-rate-finished');
        this.finishedTitleTemplate = this.$finished.data('title') || '';
        this.$excluded = this._$element.find('.response-rate-excluded');
        this.excludedTitleTemplate = this.$finished.data('title') || '';

        this.$excluded.hide();
    }

    onUpdate(data) {
        if (data.finished === data.published) {
            this.$published.hide();
        } else {
            this.$published.show();
        }

        if (!data.excluded) {
            this.$excluded.hide();
        } else {
            this.$excluded.show();
        }

        this._setData(data);
    }

    _setData(data) {
        const {total, error, published, finished, excluded} = data;
        const usable = total - error;

        this.$total.find('strong').first().text(Number(total).toLocaleString());

        this.$usable.find('strong span:nth-child(1)').text(Number(usable).toLocaleString());
        this.$usable.find('span.ratio').text(this._ratio(total, usable));

        this.$published.find('strong span').first().text(Number(published).toLocaleString());
        this.$published.find('span.ratio').text(this._ratio(usable, published));

        this.$finished.find('strong').first().text(Number(finished).toLocaleString());
        this.$finished.find('span.ratio').text(this._ratio(usable, finished));

        this.$excluded.find('strong').first().text(Number(excluded).toLocaleString());
        this.$excluded.find('span.ratio').text(this._ratio(usable, excluded));
    }

    _ratio(total, part) {
        return total ? Math.round(part / total * 100) : 0;
    }
}

ComponentManager.registerComponentClass('ResponseRate', ResponseRate);